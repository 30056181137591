import { Box, Text } from 'theme-ui'
import SidebarButton from './sidebar-button'

const AboutUs = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* <Text>About PBC</Text> */}
      <Box pt={6} />
      {/* <SidebarButton
        text={'How It Works?'}
        icon={'/assets/svg/how-it-works.svg'}
        link={'/how-it-works'}
      /> */}
      {/* <SidebarButton
        text={'Whitepaper'}
        icon={'/assets/svg/how-it-works.svg'}
        link={'/how-it-works'}
      /> */}
      <Box pt={4} />
      {/* <SidebarButton text={'About Public Coin'} icon={'/assets/svg/about.svg'} link={'/about-us'} /> */}
      {/* <Box pt={4} />
      <SidebarButton text={'Help Centre'} icon={'/assets/svg/help-centre.svg'} link={'/help'} /> */}
      <Box pt={4} />
      {/* <SidebarButton
        text={'Terms & Condition'}
        icon={'/assets/svg/terms-condition.svg'}
        link={'/terms-condition'}
        newTab={true}
      /> */}
    </Box>
  )
}
export default AboutUs
