/** @jsxImportSource theme-ui */
import { Box, Button, Card, Flex, Text } from 'theme-ui'
import { FC } from 'react'
import _ from 'lodash'

import { Pool } from 'network/services/pool'
import Link from 'components/link'
import TokenIcon from 'components/icons/token'

const PoolTokenCard: FC<{ pool: Pool }> = ({ pool }) => {
  return (
    <>
      <Card variant="secondary" sx={{ p: 8 }}>
        <Flex variant="layout.flexCenterSpaceBetween" sx={{ flexDirection: ['column', 'row'] }}>
          <Flex variant="layout.flexCenterStart" sx={{ flexDirection: ['column', 'row'] }}>
            <Box sx={{ width: 50, display: 'inline-flex', svg: { width: '100%', height: '100%' } }}>
              <TokenIcon type={pool.token_type} />
            </Box>
            <Box ml={[0, 8]} mt={[4, 0]}>
              <Flex variant="layout.vStack">
                <Text>{_.startCase(pool.type)} Pool</Text>
                <Box>
                  <Flex variant="layout.flexCenterCenter" sx={{ flexDirection: ['column', 'row'] }}>
                    <Box>
                      <Text variant="extraLarge">{pool.token_type}</Text>
                    </Box>
                    <Box sx={{ ml: [0, 3] }}>
                      <Text variant="mediumSmall" color="textMuted">
                        {pool.token_name}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Link variant="unstyled" to={`/stake/submit/${pool.id}`} mt={[2, 0]}>
            <Button sx={{ minWidth: [85, 95, 150] }}>Stake</Button>
          </Link>
        </Flex>
      </Card>
    </>
  )
}

export default PoolTokenCard
