import { proxy } from 'valtio'

export interface BinanceSocketData {
  e: string // Event type
  E: number // Event time
  s: string // Symbol
  c: string // Last price
  p: string // Price change
  P: string // Price change percent
  v: string // Volume
}
export interface BinanceSocketStream {
  stream: string
  data: BinanceSocketData
}

export const currencyStore = proxy<{
  btc: BinanceSocketData | null
  eth: BinanceSocketData | null
  bnb: BinanceSocketData | null
}>({
  btc: null,
  eth: null,
  bnb: null
})

export const findUsd = (amount: number, type: string) => {
  if (type.toLowerCase() === 'usdt') {
    return amount
  }

  if (type.toLowerCase() === 'btc') {
    return amount * parseFloat(currencyStore.btc?.c ?? '0')
  }

  if (type.toLowerCase() === 'eth') {
    return amount * parseFloat(currencyStore.eth?.c ?? '0')
  }

  if (type.toLowerCase() === 'bnb') {
    return amount * parseFloat(currencyStore.bnb?.c ?? '0')
  }

  // TODO: get vxt token value
  if (type.toLowerCase() === 'vxt') {
    return amount * 0.1
  }

  return amount
}
