/** @jsxImportSource theme-ui */
import { FC, useMemo, useState } from 'react'
import { Box, Card, Flex, Grid, Text } from 'theme-ui'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import _ from 'lodash'

import PoolService, { Pool, PoolProfit } from 'network/services/pool'
import { useResolution } from 'hooks/useResolution'
import { LineChart } from 'components/LineChart'
import Table from 'components/Table'
import TitleWithBack from 'components/TitleWithBack'
import HorizontalScroller from 'layout/horizontal-scroller'
import MobileReturnHeader from 'components/MobileReturnHeader'
import PoolOverviewCard from 'components/Stake/overview-card'
import PoolTokenCard from 'components/Stake/token-card'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'
import { serialize } from 'network/request'
import toUTCTime from 'modules/to-utc-time'

const StakeOverview = () => {
  const { isMobile } = useResolution()

  const { id } = useParams()
  const { data: poolData, error: poolError } = useSWR<{ data: Pool }>(
    id != null ? PoolService.get(id) : null
  )

  return (
    <Box variant="layout.pageContainer">
      <Box>
        {isMobile && (
          <MobileReturnHeader
            title={
              poolData
                ? `${poolData?.data.token_type} - ${_.startCase(poolData?.data.type)} Pool Info`
                : ''
            }
            delta={-1}
          />
        )}
        <TitleWithBack
          delta={-1}
          title={
            poolData
              ? `${poolData?.data.token_type} - ${_.startCase(poolData?.data.type)} Pool Info`
              : ''
          }
        />
      </Box>
      <PageView poolData={poolData} poolError={poolError} />
    </Box>
  )
}

const PageView: FC<{ poolData: any; poolError: any }> = ({ poolData, poolError }) => {
  if (poolError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve the overview of the stake" />
      </Flex>
    )
  }

  if (!poolData) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Box>
      <Grid sx={{ width: '100%' }} gap={8}>
        <PoolTokenCard pool={poolData.data} />

        <Grid sx={{ width: '100%' }} columns={[1, '1fr 2fr']} gap={8}>
          <PoolOverviewCard pool={poolData.data} />
          <ChartView />
        </Grid>

        <TableView />
      </Grid>
    </Box>
  )
}

const ChartView: FC = () => {
  const { id } = useParams()
  const { data, error } = useSWR<{ data: PoolProfit[] }>(
    id != null ? PoolService.getProfits(id) : null
  )
  const chartData = useMemo(
    () =>
      data
        ? PoolService.profitToRow(data).map((item) => {
            const date = new Date(item.distributed_at)
            const mth = date.toLocaleString('en-us', { month: 'short' })
            return {
              x: `${mth} ${date.getDate()}`,
              y: item.profit_rate
            }
          })
        : undefined,
    [data]
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to display pool profit chart" />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  return (
    <Card sx={{ background: 'darkBG' }}>
      <HorizontalScroller breakpointEnabled={false}>
        <Box sx={{ height: 400, px: 6, py: 8, width: [800, '100%'] }}>
          <Box>
            <Text variant="large">Pool Profit</Text>
          </Box>
          <LineChart data={chartData} />
        </Box>
      </HorizontalScroller>
    </Card>
  )
}

const TableView: FC = () => {
  const { id } = useParams()
  const { isMobile } = useResolution()
  const [page, setPage] = useState(1)
  const limit = 10
  const { data, error } = useSWR<{ data: PoolProfit[] }>(
    id != null
      ? serialize(PoolService.getProfits(id), {
          page: page,
          limit: limit,
          sort: 'created_at:desc'
        })
      : null
  )
  const total = PoolService.profitToPaginate(data).total

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve pool profits" />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  const tableData = PoolService.profitToRow(data).map((profit) => {
    return {
      last_distribution_date: toUTCTime(profit.distributed_at),
      pool_size: `${profit.total_vxt ?? 0} ${profit.token_type}`,
      profit: (
        <Text>
          {`${profit.profit_value ?? 0} ${profit.token_type} `}
          <Text color={profit.profit_rate > 0 ? 'success' : 'danger'}>({profit.profit_rate}%)</Text>
        </Text>
      )
    }
  })

  return (
    <Table
      headers={['Last Distribution Date', 'Pool Size', 'Profit']}
      data={tableData}
      width={isMobile ? 800 : '100%'}
      page={page}
      limit={limit}
      total={total}
      onPageChange={setPage}
    />
  )
}

export default StakeOverview
