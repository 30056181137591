import { createContext, useContext, useState } from 'react'

const sidebarDisplayContext = createContext<{
  sidebarDisplay: boolean
  setSideBarDisplay: (value: boolean) => any
}>({
  sidebarDisplay: true,
  setSideBarDisplay: (value) => {}
})

export const useSideBarDisplay = () => useContext(sidebarDisplayContext)

export const SideBarDisplayContextProvider = ({ children }: { children: JSX.Element }) => {
  const [sidebarDisplay, setSideBarDisplay] = useState<boolean>(false)

  return (
    <sidebarDisplayContext.Provider value={{ sidebarDisplay, setSideBarDisplay }}>
      {children}
    </sidebarDisplayContext.Provider>
  )
}
