/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Box, Button, Card, Flex, Grid, Heading, Input, Switch, Text, useThemeUI } from 'theme-ui'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { BsArrowRight } from 'react-icons/bs'
import { AnimatePresence, motion } from 'framer-motion'
import { proxy, useSnapshot } from 'valtio'
import useSWR, { mutate } from 'swr'

import { fadeAnim } from 'lib/animation'
import { useResolution } from 'hooks/useResolution'
import UserSecurityService, {
  SecurityCodeVerification,
  UserSecurityRequest
} from 'network/services/user_security'
import MobileReturnHeader from 'components/MobileReturnHeader'
import TitleWithBack from 'components/TitleWithBack'
import TwoFactorAuthenticationStep1 from './two-factor-step-1'
import TwoFactorAuthenticationStep2 from './two-factor-step-2'
// import TwoFactorAuthenticationStep3 from './two-factor-step-3'
import TwoFactorAuthenticationStep4 from './two-factor-step-4'
import { UserSecurity } from 'network/services/user_security'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import StatusModal from 'components/status-modal'
import CustomModal from 'components/modal'
import FormInput from 'components/form-input'

interface ModalMessage {
  isOpen: boolean
  message?: string
  success?: boolean
}

let AnimatedBox = motion(Box)

const twoFactorAuthStore = proxy({
  currentStep: 0
})

const TwoFactorAuthenticationPage = () => {
  const { isMobile } = useResolution()
  const [message, setMessage] = useState<ModalMessage>({
    isOpen: false,
    success: true,
    message: ''
  })

  useEffect(() => {
    twoFactorAuthStore.currentStep = 0
  }, [])

  return (
    <Box variant="layout.pageContainer">
      {isMobile ? (
        <>
          <MobileReturnHeader title="Security" delta={-1} />
          <Box>
            <Heading variant="styles.h5" color="secondaryText">
              Two-Factor Authentication
            </Heading>
            <Box p={2} />
            <Box>
              <Text variant="large">User for withdrawals and changes to security settings.</Text>
            </Box>
          </Box>
        </>
      ) : (
        <TitleWithBack
          title="Two-Factor Authentication"
          backRef="/security"
          subtitle="User for withdrawals and changes to security settings."
        />
      )}

      <Box p={2} />

      <TwoFactorAuthenticationView message={message} setMessage={setMessage} />

      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        success={message.success}
        onRequestClose={() => setMessage({ isOpen: false })}
      />
    </Box>
  )
}

const TwoFactorAuthenticationView: FC<{ message: ModalMessage; setMessage: any }> = (props) => {
  const { data, error, mutate } = useSWR<{ data: UserSecurity | null }>(UserSecurityService.get)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve security information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const userSecurity = data.data

  if (userSecurity == null) {
    return <IncompletedTwoFactorAuthentication {...props} />
  }

  return <CompletedTwoFactorAuthentication userSecurity={userSecurity} />
}

const StepIndicator: FC<{ index: number }> = ({ index: currentStep }) => {
  const { isMobile } = useResolution()
  const { theme } = useThemeUI()
  const topics = ['Download App', 'Scan QR Code', 'Enable Google Authenticator']
  // const topics = ['Download App', 'Scan QR Code', 'Backup Key', 'Enable Google Authenticator']

  const horrizontalLine = (
    <Box sx={{ mx: 5 }}>
      <svg width={120} height={6}>
        <line
          x1={0}
          y1={0}
          x2={120}
          y2={0}
          stroke={theme.colors?.light?.toString()}
          strokeWidth={4}
        />
      </svg>
    </Box>
  )

  const verticalLine = (
    <Box sx={{ my: 3 }}>
      <svg width={6} height={60}>
        <line
          x1={0}
          y1={0}
          x2={0}
          y2={60}
          stroke={theme.colors?.light?.toString()}
          strokeWidth={4}
        />
      </svg>
    </Box>
  )

  return isMobile ? (
    <Box>
      {topics.map((topic, index) => (
        <Box key={index}>
          <Flex
            variant="layout.flexCenterCenter"
            sx={{ maxWidth: 'fit-content', flexDirection: 'column' }}
          >
            {index !== 0 && verticalLine}
            <Flex
              variant="layout.roundContainer"
              sx={{ background: currentStep === index ? 'buttonBG' : 'sidebarButtonBG' }}
            >
              {index + 1}
            </Flex>
          </Flex>
          <Box p={2} />
          <Box>{topic}</Box>
        </Box>
      ))}
    </Box>
  ) : (
    <Flex variant="layout.flexStartStart">
      {topics.map((topic, index) => (
        <Box key={index}>
          <Flex variant="layout.flexStartStart">
            <Flex
              variant="layout.roundContainer"
              sx={{ background: currentStep === index ? 'buttonBG' : 'sidebarButtonBG' }}
            >
              {index + 1}
            </Flex>
            {index < topics.length - 1 && horrizontalLine}
          </Flex>
          <Box p={2} />
          <Box>{topic}</Box>
        </Box>
      ))}
    </Flex>
  )
}

const IncompletedTwoFactorAuthentication: FC<{ setMessage: any }> = ({ setMessage }) => {
  const { currentStep } = useSnapshot(twoFactorAuthStore)
  const { isMobile } = useResolution()

  const method = useForm<SecurityCodeVerification>({
    shouldUseNativeValidation: false,
    defaultValues: {
      two_fa_method: 'google_auth'
    }
  })

  const onSubmit = method.handleSubmit(async (data) => {
    try {
      const { data: result } = await UserSecurityService.verifySecurityCode(data)

      if (result.success) {
        method.clearErrors()
        setMessage({
          success: true,
          isOpen: true,
          message: 'Enable Two Factor Authentication Successfully'
        })
        mutate(UserSecurityService.get)
        // twoFactorAuthStore.currentStep = 0
      }
    } catch (e: any) {
      setMessage({
        success: false,
        isOpen: true,
        message: e.message ?? ''
      })
    }
  })

  return (
    <Box>
      <FormProvider {...method}>
        <Card sx={{ p: 0 }}>
          <Box as="form" onSubmit={onSubmit}>
            <Box
              sx={{ p: 8, borderWidth: '0 0 2px 0', borderColor: 'light', borderStyle: 'solid' }}
            >
              <Box>
                <Text variant="large" color="secondaryText">
                  Enable Google Authenticator
                </Text>
              </Box>

              <Box p={1} />

              <Box>
                <Text variant="mediumSmall">
                  Protect your account with an additional layer of authentication.
                </Text>
              </Box>
              <Box sx={{ padding: [5, 2] }} />
              <Box>
                <StepIndicator index={currentStep} />
              </Box>

              <Box p={5} />

              <TwoFactorAuthenticationForm />
            </Box>

            <Flex
              variant={isMobile ? 'layout.flexCenterCenter' : 'layout.flexCenterEnd'}
              sx={{ p: 8 }}
            >
              {currentStep > 0 && (
                <Button
                  type="button"
                  variant="sidebarButton"
                  onClick={() => {
                    twoFactorAuthStore.currentStep -= 1
                  }}
                  sx={{ mr: 3, flex: [1, 0] }}
                >
                  Back
                </Button>
              )}
              {currentStep === 3 ? (
                <Button
                  variant="primaryFlexCenter"
                  type="submit"
                  sx={{ flex: [1, 0] }}
                  onClick={() => method.clearErrors()}
                >
                  <Box sx={{ flex: 1 }} />
                  <Text>Submit</Text>
                  <BsArrowRight sx={{ flex: 1 }} />
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="primaryFlexCenter"
                  onClick={() => {
                    twoFactorAuthStore.currentStep += 1
                  }}
                  sx={{ flex: [1, 0] }}
                >
                  <Box sx={{ flex: 1 }} />
                  <Text>Next</Text>
                  <BsArrowRight sx={{ flex: 1 }} />
                </Button>
              )}
            </Flex>
          </Box>
        </Card>
      </FormProvider>
    </Box>
  )
}

const TwoFactorAuthenticationForm = () => {
  const { currentStep } = useSnapshot(twoFactorAuthStore)
  const { setValue } = useFormContext<SecurityCodeVerification>()

  // const { data, error, mutate } = useSWR<{ data: SecurityCodeGenerator }>(
  //   UserSecurityService.generateSecurityCode
  // )

  // if (error) {
  //   return (
  //     <Flex variant="layout.flexCenterCenter">
  //       <ErrorCard message="Unable to retrieve security code" refresh={() => mutate()} />
  //     </Flex>
  //   )
  // }

  // if (!data) {
  //   return (
  //     <Flex variant="layout.flexCenterCenter">
  //       <LoadingCard />
  //     </Flex>
  //   )
  // }

  // const securityCode = data.data

  const [userSecurity, setUserSecurity] = useState<UserSecurity | null>(null)

  useEffect(() => {
    if (userSecurity == null && currentStep === 1) {
      // generate 2fa
      UserSecurityService.create()
        .then((value) => {
          setUserSecurity(value.data.data)
          setValue('user_security_id', value.data.data.id)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  return (
    <AnimatePresence exitBeforeEnter>
      {
        {
          0: (
            <AnimatedBox
              key="step_one"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnim}
            >
              <TwoFactorAuthenticationStep1 />
            </AnimatedBox>
          ),
          1: (
            <AnimatedBox
              key="step_two"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnim}
            >
              {userSecurity && (
                <TwoFactorAuthenticationStep2
                  otpUrl={userSecurity.otpauth_url!}
                  base32={userSecurity.two_fa_code!}
                />
              )}
            </AnimatedBox>
          ),
          2: (
            <AnimatedBox
              key="step_four"
              initial="hide"
              animate="show"
              exit="hide"
              variants={fadeAnim}
            >
              {userSecurity && <TwoFactorAuthenticationStep4 />}
            </AnimatedBox>
          )
          // 2: (
          //   <AnimatedBox
          //     key="step_three"
          //     initial="hide"
          //     animate="show"
          //     exit="hide"
          //     variants={fadeAnim}
          //   >
          //     <TwoFactorAuthenticationStep3 backupKey={backupKey} />
          //   </AnimatedBox>
          // ),
          // 3: (
          //   <AnimatedBox
          //     key="step_four"
          //     initial="hide"
          //     animate="show"
          //     exit="hide"
          //     variants={fadeAnim}
          //   >
          //     <TwoFactorAuthenticationStep4 />
          //   </AnimatedBox>
          // )
        }[currentStep]
      }
    </AnimatePresence>
  )
}

const CompletedTwoFactorAuthentication: FC<{ userSecurity: UserSecurity }> = ({ userSecurity }) => {
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const method = useForm<UserSecurityRequest>({
    shouldUseNativeValidation: false
  })
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const onCloseModal = () => {
    setModalOpen(false)
    onSubmit()
  }

  const onSubmit = method.handleSubmit(async (data) => {
    try {
      const { data: result } = await UserSecurityService.update(data, userSecurity.id)

      if (result.success) {
        mutate(UserSecurityService.get)
        setMessage({
          success: true,
          isOpen: true,
          message: 'Update Two Factor Authentiation Successfully'
        })
      }
    } catch (error: any) {
      setMessage({
        success: false,
        isOpen: true,
        message: error.message ?? ''
      })
    }
  })

  return (
    <Box>
      <FormProvider {...method}>
        <Card sx={{ p: 8 }}>
          <Flex variant="layout.flexCenterSpaceBetween">
            <Box>
              <Box>
                <Text variant="large" color="secondaryText">
                  Enable Google Authenticator
                </Text>
              </Box>
              <Box>
                <Text variant="mediumSmall">
                  Protect your account with an additional layer of authentication.
                </Text>
              </Box>
              <Box pt={4} />
              <Box>
                <Text variant="large">
                  Your two factor authentication is
                  <Text color="primary">
                    {` ${userSecurity?.two_fa_enabled ? 'enabled' : 'disabled'}`}
                  </Text>
                  .
                </Text>
              </Box>
            </Box>

            <Box>
              <Switch
                checked={userSecurity?.two_fa_enabled ?? false}
                onClick={() => {
                  setModalOpen(true)
                  method.setValue('two_fa_enabled', false)
                }}
                sx={{ background: userSecurity.two_fa_enabled ? 'default' : 'input' }}
              />
            </Box>
          </Flex>
        </Card>
        <StatusModal
          isOpen={message.isOpen}
          children={message.message}
          success={message.success}
          onRequestClose={() => setMessage({ isOpen: false })}
        />
        <VerificationModal
          onRequestClose={() => setModalOpen(false)}
          isOpen={modalOpen}
          onSubmit={onCloseModal}
        />
      </FormProvider>
    </Box>
  )
}

const VerificationModal: FC<{
  onRequestClose: () => any
  isOpen: boolean
  onSubmit: () => any
}> = ({ isOpen, onRequestClose, onSubmit }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<UserSecurityRequest>()

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box
        variant="layout.flexCenterCenter"
        sx={{
          minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
          py: [5, 16],
          position: 'relative'
        }}
      >
        <Card px={10} py={12}>
          <Box
            variant="layout.flexStartCenter"
            sx={{ minHeight: 'inherit', flexDirection: 'column' }}
          >
            <Heading sx={{ textAlign: 'left' }}>Security Verification</Heading>
            <Box pt={5} />
            <Text>To secure your account, please complete the following verification.</Text>
            <Box pt={5} />
            <Box>
              <Text>Google </Text>
              <Text>Authentication Code</Text>
            </Box>
            <Box pt={2} />
            <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
              <FormInput
                sx={{ my: 3, background: 'sidebarButtonBG' }}
                name="two_fa_code"
                register={register}
                rules={{ required: 'You must enter your authentication code.' }}
                errors={errors}
              />
            </Flex>
            <Box pt={5} />
            <Grid columns={2} gap={[1, 3]} sx={{ width: '100%' }}>
              <Button type="button" onClick={onRequestClose} sx={{ width: '100%' }}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => {
                  onSubmit()
                }}
                sx={{ width: '100%' }}
              >
                Next
              </Button>
            </Grid>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

export default TwoFactorAuthenticationPage
