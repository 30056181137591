import CustomModal from 'components/modal'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { FC } from 'react'
import { NetworkState } from 'network/services/network'

export interface SelectNetworkModalProps {
  networks?: NetworkState[]
  networkSelected?: NetworkState | null
  setNetwork: (network: NetworkState) => void
  setIsOpen: (isOpen: boolean) => void
  onRequestClose: () => void
  isOpen: boolean
}

const SelectNetworkModal: FC<SelectNetworkModalProps> = ({
  networks = [],
  networkSelected,
  setNetwork,
  setIsOpen,
  isOpen = false,
  onRequestClose = () => {}
}) => {
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box>
        <Card>
          <Flex variant="layout.flexCenterSpaceBetween">
            <Text>Select network</Text>
            <Button onClick={onRequestClose}>Close</Button>
          </Flex>
          <Box p={2} />
          <Text variant="small" sx={{ color: 'textMuted' }}>
            Ensure the network you choose to deposit matches the withdrawal network, or assets may
            be lost.
          </Text>
          <Box p={2} />
          {networks?.map((item, index) => (
            <Box
              key={index}
              sx={{
                mx: -4,
                py: 3,
                px: 4,
                borderWidth: '0 0 1px 0',
                borderColor: 'muted',
                borderStyle: 'solid',
                cursor: 'pointer',
                ':hover': { background: 'light' },
                background: item.id === networkSelected?.id ? 'light' : 'initial',
                transition: 'background 0.2s'
              }}
              onClick={() => {
                setNetwork(item)
                setIsOpen(false)
              }}
            >
              <Text>{item.name}</Text>
            </Box>
          ))}
        </Card>
      </Box>
    </CustomModal>
  )
}

export default SelectNetworkModal
