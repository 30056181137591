import { NFT } from 'network/services/nft'
import { FC } from 'react'
import { AspectRatio, Box, Card, Grid, Image, Text } from 'theme-ui'

interface NFTPreviewCardProps {
  isSelected?: boolean
  NFT: NFT
  handleSelect?: (id: number | string) => any
  isBlind?: boolean
  showTitle?: boolean
}

const NFTPreviewCard: FC<NFTPreviewCardProps> = ({
  isSelected = false,
  NFT,
  handleSelect,
  isBlind = true,
  showTitle = false
}) => {
  return (
    <Card
      sx={{
        border: isSelected ? 'solid 2px' : 'none',
        borderColor: 'primary',
        cursor: 'pointer',
        borderRadius: 8,
        overflow: 'hidden',
        padding: !isBlind ? undefined : 0
      }}
      onClick={() => {
        handleSelect && handleSelect(NFT.id)
      }}
    >
      <Grid gap={3} sx={{ position: 'relative' }}>
        <Box sx={{ borderRadius: 10 }}>
          <AspectRatio ratio={1 / 1}>
            <Image
              src={!isBlind ? NFT.image_url : '/blind_box.jpg'}
              sx={{
                width: '100%',
                objectFit: 'cover'
              }}
              draggable={false}
            />
          </AspectRatio>
        </Box>
        {!isBlind && showTitle && (
          <Box>
            <Text variant="large">{NFT.name}</Text>
          </Box>
        )}
        {isBlind && (
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              background:
                'transparent linear-gradient(2deg, #000000 0%, #181D481A 100%) 0% 0% no-repeat padding-box'
            }}
          />
        )}
        {isBlind && (
          <Box sx={{ position: 'absolute', bottom: 5, left: 5, right: 5 }}>
            <Box>
              <Text variant="large">Poker Card Blind</Text>
            </Box>
            <Box>
              <Text variant="large">Box #{NFT.id}</Text>
            </Box>
          </Box>
        )}
      </Grid>
    </Card>
  )
}

export default NFTPreviewCard
