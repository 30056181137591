import client, { IDataResponse, IPostResponse } from '../request'
import { User } from './auth'
import { Wallet } from './wallet'

export type Stake = {
  id: string | number
  token_name: string
  token_type: string
  type: string
  status: string
  payout_day: number
  // nft: NFT | null
  tenure: number
  completed: boolean
  earn_vxt: boolean
  link_nft: boolean
  stake_rate: number
  // profit_rate: number
  incentive_rate: number
  nft_rate: number
  vxt_rate: number
  burn_rate: number
  reserve_rate: number
  team_rate: number
  principal_amount: number
  subscription_fee: number
  termination_fee: number
  // total_vxt_profit: number
  // total_profit: number
  deposited_at: string //date
  started_at: string // date
  next_distribute_at: string //date
  next_nft_distribute_at: string //date
  matured_at: string // date
  terminated_at: string
  first_payout_at: string
  last_payout_at: string
  pool_id: number
  user_id: number
  wallet_id: number
  vxt_wallet_id: number
  created_at: string // date
  updated_at: string //date
  txn_hash: string
  nft_id: number
  link_nft_at: string //date
  total_interests: number
  total_vxt_profit: number
  total_profit: number
  remaining: number
}

export type CreateStake = {
  tenure: 12 | 24
  principal_amount: number
  earn_vxt: boolean
  link_nft: boolean
  pool_id: number | string
  agreed: boolean
  nft_id?: number | string | null
}

export type CreateEstimate = {
  tenure: 12 | 24
  principal_amount: number
  earn_vxt: boolean
  link_nft: boolean
  pool_id: number | string
  nft_id: number | string | null
}

export type Estimation = {
  type: string
  pool_id: number | string
  token_name: string
  token_type: string
  status: string
  earn_vxt: boolean
  link_nft: boolean
  principal_amount: number
  payout_day: number
  tenure: number
  deposited_at: string
  stake_rate: number
  nft_rate: number
  incentive_rate: number
  team_rate: number
  burn_rate: number
  reserve_rate: number
  vxt_rate: number
  started_at: string
  next_distribute_at: string
  matured_at: string
  first_payout_at: string
  last_payout_at: string
  wallet_id: number | string
  user_id: number | string
  total_vxt_profit: number
  total_profit: number
  remaining: number
}

export type TransactionByStake = {
  id: number | string
  title?: string
  description?: string
  txn_type: string
  token_type: string
  network_type?: string
  status: string
  confirmed: boolean
  amount: number
  amount_in: number
  amount_out: number
  amount_str: string
  asset_id?: number | string
  wallet_id: number | string
  user_id: number | string
  txn_hash: string
  created_at: string
  updated_at: string
  stake_id: number | string
  reward_point: number
  user: User
  wallet: Wallet
}

export type LinkNFT = {
  stake_id: number | string
  nft_id: number | string | null
}

// useSWR, return strings
const getAll = '/me/stakes'

// useSWR, return strings
const get = (id: string | number) => {
  return `/me/stakes/${id}`
}

// useSWR, return strings
const getTransactions = (id: string | number) => {
  return `/me/stakes/${id}/transactions`
}

// axios
const create = (data: CreateStake) => {
  return client.post<IPostResponse<Stake>>('/me/stakes', data)
}

// axios
const estimate = (data: CreateEstimate) => {
  return client.post<IPostResponse<Estimation>>('/me/stakes/estimate', data)
}

const linkNFT = (data: LinkNFT) => {
  return client.post<IPostResponse<LinkNFT>>('/me/stakes/link', data)
}

const unlinkNFT = (data: LinkNFT) => {
  return client.post<IPostResponse<LinkNFT>>('/me/stakes/unlink', data)
}

// processing
const toRow = (data: IDataResponse<Stake>): Stake[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const transactionToRow = (data: IDataResponse<TransactionByStake>): TransactionByStake[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<Stake>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// processing
const transactionToPaginate = (data?: IDataResponse<TransactionByStake>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id: string | number) => {
  return client.delete(`/me/stakes/${id}`)
}

const StakeService = {
  getAll,
  get,
  getTransactions,
  create,
  estimate,
  linkNFT,
  unlinkNFT,
  toPaginate,
  transactionToPaginate,
  toRow,
  transactionToRow,
  remove
}

export default StakeService
