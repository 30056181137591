import CustomModal from 'components/modal'
import TokenIcon from 'components/icons/token'
import { Box, Text, Card, Flex, Button, Input } from 'theme-ui'
import { AiOutlineSearch } from 'react-icons/ai'
import { FC, useMemo, useState } from 'react'
import { Asset } from 'network/services/asset'

export interface SelectTokenModalProps {
  tokens?: Asset[]
  tokenSelected?: Asset | null
  setToken: (token: Asset) => void
  setIsOpen: (isOpen: boolean) => void
  onRequestClose: () => void
  isOpen: boolean
}

const SelectTokenModal: FC<SelectTokenModalProps> = ({
  tokens = [],
  tokenSelected,
  setToken,
  setIsOpen,
  isOpen = false,
  onRequestClose = () => {}
}) => {
  const [search, setSearch] = useState<string>()
  const filteredData = useMemo(() => {
    if (search && search.length > 0) {
      return tokens.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    }

    return tokens
  }, [search, tokens])

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box>
        <Card px={0}>
          <Box px={4}>
            <Flex variant="layout.flexCenterSpaceBetween">
              <Text>Select token to deposit</Text>
              <Button onClick={onRequestClose}>Close</Button>
            </Flex>
            <Box p={2} />
            <Flex>
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'input',
                  p: 4,
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  cursor: 'pointer'
                }}
              >
                <AiOutlineSearch color="grey" />
              </Box>
              <Input
                placeholder="Search token name"
                onChange={(e) => setSearch(e.currentTarget.value.toString())}
                sx={{
                  flex: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              />
            </Flex>
          </Box>
          <Box pt={4} />
          <Box>
            {filteredData?.map((item, index) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    px: 5,
                    py: 4,
                    cursor: 'pointer',
                    ':hover': { background: 'light' },
                    background: item.id === tokenSelected?.id ? 'light' : 'initial',
                    transition: 'background 0.2s'
                  }}
                  onClick={() => {
                    setToken(item)
                    setIsOpen(false)
                  }}
                >
                  <Flex variant="layout.hStack" sx={{ alignItems: 'flex-start' }}>
                    <TokenIcon type={item.symbol} />
                    <Flex variant="layout.vStack" sx={{ ml: 2 }}>
                      <Text as="p" variant="large">
                        {item.symbol}
                      </Text>
                      <Text
                        variant="mediumSmall"
                        color="textMuted"
                        sx={{ flex: 2, whiteSpace: 'nowrap' }}
                      >
                        {item.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              )
            })}
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

export default SelectTokenModal
