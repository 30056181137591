/** @jsxImportSource theme-ui */
import { Datum, ResponsiveLine } from '@nivo/line'
import LoadingCard from 'components/loading'
import { FC } from 'react'
import { Card, Flex, Text, useThemeUI } from 'theme-ui'

export interface LineChartProps {
  data?: Datum[]
}

export const LineChart: FC<LineChartProps> = ({ data }) => {
  const { theme, colorMode } = useThemeUI()
  const CustomPointLayer = (props: any) => {
    const { currentPoint, borderWidth } = props
    // it will show the current point
    if (currentPoint) {
      return (
        <g>
          <circle
            fill={theme.colors?.danger?.toString()}
            r={10}
            strokeWidth={borderWidth}
            cx={currentPoint.x}
            cy={currentPoint.y}
          />
        </g>
      )
    }
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <ResponsiveLine
      margin={{
        left: 70,
        top: 20,
        right: 30,
        bottom: 40
      }}
      data={[{ id: 'profit', color: theme.colors?.primary, data: data }]}
      xScale={{ type: 'point' }}
      yFormat={(value) => `${value}%`}
      yScale={{
        type: 'linear',
        min: 0,
        max: 100,
        stacked: true,
        reverse: false
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 20,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle',
        tickValues: [0, 25, 50, 75, 100],
        format: (value) => `${value}%`
      }}
      axisBottom={{
        tickPadding: 10
      }}
      tooltip={(point) => {
        return (
          <Card variant="toolTipCard">
            <Text>{point.point.data.yFormatted}</Text>
          </Card>
        )
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        'legends',
        CustomPointLayer
      ]}
      onClick={(point, event) => console.log(point)}
      pointSize={data?.length > 1 ? 0 : 20}
      colors={theme.colors?.primary?.toString()}
      pointColor={theme.colors?.danger?.toString()}
      curve="cardinal"
      lineWidth={4}
      useMesh={true}
      gridYValues={[0, 25, 50, 75, 100]}
      enableArea={true}
      theme={{
        fontSize: 14,
        crosshair: {
          line: {
            stroke: theme.colors?.danger?.toString(),
            strokeWidth: 3
          }
        },
        textColor:
          colorMode === 'light'
            ? theme.colors?.modes?.light?.text?.toString()
            : theme.colors?.text?.toString(),
        grid: {
          line: {
            strokeWidth: 0.3,
            color: theme.colors?.secondaryText?.toString()
          }
        },
        axis: {
          ticks: {
            line: {
              strokeWidth: 0
            },
            text: {
              fontSize: 16
            }
          }
        },
        tooltip: {
          container: {
            color: 'black'
          }
        }
      }}
      crosshairType="x"
      enableGridX={false}
    />
  )
}
