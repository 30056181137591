/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Image } from 'theme-ui'

const TokenIcon: FC<{
  type: string
  background?: string
  fill?: string
}> = ({ type, background, fill }) => {
  if (type == null) {
    return <></>
  }

  if (type.toLowerCase() === 'eth')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.416"
        height="19.416"
        viewBox="0 0 19.416 19.416"
      >
        <g data-name="Icon - Ethereum" transform="translate(-2.292 -2.292)">
          <path
            d="M19.415,9.54A9.708,9.708,0,1,1,9.538,0,9.707,9.707,0,0,1,19.415,9.54Z"
            transform="translate(2.292 2.292)"
            fill={background ?? '#4e80ff'}
          />
          <path
            d="M3.775,5.23,0,0,3.775,2.22Z"
            transform="translate(8.25 12.765)"
            fill={fill ?? '#fff'}
          />
          <path
            d="M3.725,0,0,5.28V2.245Z"
            transform="translate(12.025 12.765)"
            fill={fill ?? '#d2ecff'}
          />
          <path d="M0,3V0L2.25,1.3Z" transform="translate(12 11.206)" fill={fill ?? '#9dd4ff'} />
          <path
            d="M3.75,4.441V0L0,6.168Z"
            transform="translate(8.25 5.956)"
            fill={fill ?? '#fff'}
          />
          <path
            d="M0,4.441,3.75,6.118,0,0Z"
            transform="translate(12 5.956)"
            fill={fill ?? '#d2ecff'}
          />
          <path
            d="M2.25,3,0,1.338,2.235,0Z"
            transform="translate(9.75 11.206)"
            fill={fill ?? '#9dd4ff'}
          />
        </g>
      </svg>
    )

  if (type.toLowerCase() === 'usdt')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.417"
        height="19.416"
        viewBox="0 0 19.417 19.416"
      >
        <path
          id="Bg"
          d="M19.415,9.54A9.708,9.708,0,1,1,9.538,0,9.707,9.707,0,0,1,19.415,9.54Z"
          fill={background ?? '#50af95'}
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M69.7,44.828h0c-.053,0-.325.02-.934.02-.484,0-.827-.015-.948-.02h0c-1.869-.082-3.264-.408-3.264-.8s1.4-.715,3.264-.8V44.5c.122.009.472.029.956.029.58,0,.871-.024.923-.029V43.234c1.865.083,3.257.409,3.257.8s-1.392.714-3.257.8h0Zm0-1.726V41.965h2.6V40.23H65.214v1.735h2.6V43.1c-2.115.1-3.706.516-3.706,1.018s1.591.921,3.706,1.018v3.645H69.7V45.137c2.111-.1,3.7-.516,3.7-1.018S71.81,43.2,69.7,43.1h0Zm0,0Z"
          transform="translate(-59.044 -34.304)"
          fill={fill ?? '#fff'}
          fillRule="evenodd"
        />
      </svg>
    )

  if (type.toLowerCase() === 'bnb')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g transform="translate(0.491 0.491)">
          <circle
            cx="10"
            cy="10"
            r="10"
            transform="translate(-0.491 -0.491)"
            fill={background ?? '#f3ba2f'}
          />
          <g transform="translate(-610.592 -130.591)">
            <g transform="translate(615.1 135.1)">
              <path
                d="M618.158,139.3l1.942-1.942,1.943,1.943,1.13-1.13L620.1,135.1l-3.072,3.072,1.13,1.13m-3.058.8,1.13-1.13,1.13,1.13-1.13,1.13Zm3.058.8,1.942,1.942,1.943-1.943,1.131,1.129h0L620.1,145.1l-3.072-3.072,0,0,1.132-1.128m4.682-.8,1.13-1.13,1.13,1.13-1.13,1.13Z"
                transform="translate(-615.1 -135.1)"
                fill={fill ?? '#fff'}
              />
              <path
                d="M1580.593,1099.447h0l-1.146-1.147-.848.847h0l-.1.1-.2.2,0,0,0,0,1.146,1.146,1.147-1.147h0"
                transform="translate(-1574.447 -1094.447)"
                fill={fill ?? '#fff'}
              />
            </g>
          </g>
        </g>
      </svg>
    )

  if (type.toLowerCase() === 'btc')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.416"
        height="19.416"
        viewBox="0 0 19.416 19.416"
      >
        <g data-name="Icon - Bitcoin" transform="translate(0 0)">
          <path
            d="M19.415,9.54A9.708,9.708,0,1,1,9.538,0,9.707,9.707,0,0,1,19.415,9.54Z"
            fill={background ?? '#ff8933'}
          />
          <path
            d="M7.113,3.05c-.148-1.3-.814-1.715-2.233-1.814L4.848,0,3.75.019l.03,1.188c-.288.005-.583.016-.876.027L2.872.035l-1.1.019.032,1.235L0,1.331.021,2.5a3.467,3.467,0,0,1,.391-.014.567.567,0,0,1,.64.47L1.138,7.9a.387.387,0,0,1-.406.37c.014.012-.392.013-.392.013L.145,9.6l1.807-.032L1.984,10.7l1.1-.019L3.049,9.569c.3,0,.593,0,.877-.007l.031,1.1,1.1-.019L5.024,9.518c1.844-.138,2.664-.626,2.793-2.361A1.87,1.87,0,0,0,6.2,4.914,1.7,1.7,0,0,0,7.112,3.05Zm-1,3.928C6.132,8.341,3.8,8.227,3.051,8.241L3.009,5.823C3.753,5.811,6.083,5.556,6.108,6.978Zm-.569-3.4c.021,1.241-1.928,1.13-2.548,1.141L2.952,2.524C3.572,2.514,5.517,2.282,5.539,3.576Z"
            transform="translate(5.794 4.359)"
            fill={fill ?? '#fff'}
          />
        </g>
      </svg>
    )

  if (type.toLowerCase() === 'busd')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g transform="translate(0.491 0.491)">
          <circle
            cx="10"
            cy="10"
            r="10"
            transform="translate(-0.491 -0.491)"
            fill={background ?? '#f3ba2f'}
          />
          <g transform="translate(-610.592 -130.591)">
            <g transform="translate(615.1 135.1)">
              <path
                d="M618.158,139.3l1.942-1.942,1.943,1.943,1.13-1.13L620.1,135.1l-3.072,3.072,1.13,1.13m-3.058.8,1.13-1.13,1.13,1.13-1.13,1.13Zm3.058.8,1.942,1.942,1.943-1.943,1.131,1.129h0L620.1,145.1l-3.072-3.072,0,0,1.132-1.128m4.682-.8,1.13-1.13,1.13,1.13-1.13,1.13Z"
                transform="translate(-615.1 -135.1)"
                fill={fill ?? '#fff'}
              />
              <path
                d="M1580.593,1099.447h0l-1.146-1.147-.848.847h0l-.1.1-.2.2,0,0,0,0,1.146,1.146,1.147-1.147h0"
                transform="translate(-1574.447 -1094.447)"
                fill={fill ?? '#fff'}
              />
            </g>
          </g>
        </g>
      </svg>
    )

  if (type.toLowerCase() === 'usdc')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 2000 2000">
        <path
          d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z"
          fill="#2775ca"
        />
        <path
          d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z"
          fill="#fff"
        />
        <path
          d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z"
          fill="#fff"
        />
      </svg>
    )

  if (type.toLowerCase() === 'pbc')
    return <Image sx={{ width: 20, height: 20 }} src="/assets/images/pbc.png" alt="pbc" />

  return <></>
}

export default TokenIcon
