import { useFormContext } from 'react-hook-form'
import { Box, Flex, Grid, Input, Text } from 'theme-ui'

import { SecurityCodeVerification } from 'network/services/user_security'
import FormInput from 'components/form-input'

const TwoFactorAuthenticationStep4 = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<SecurityCodeVerification>()

  return (
    <Box>
      <Box>
        <Text variant="large">Step 3</Text>
      </Box>
      <Box p={1} />
      <Box>
        <Text color="secondaryText" variant="mediumSmall">
          Enable Google Authenticator
        </Text>
      </Box>
      <Box p={5} />
      <Grid sx={{ width: '100%' }} gap={6} columns={[1, 2]}>
        <Input type="hidden" {...(register && register('two_fa_method'))} />
        <Flex variant="layout.vStack">
          <Box>
            <Text>Google Verification Code</Text>
          </Box>
          <Box>
            <FormInput
              sx={{ my: 3, background: 'sidebarButtonBG' }}
              name="two_fa_code"
              register={register}
              rules={{ required: 'You must enter your verification code.' }}
              errors={errors}
            />
          </Box>
          <Box>
            <Text color="textMuted">Enter the 6-digit code from Google Authenticator</Text>
          </Box>
        </Flex>
      </Grid>

      <Box sx={{ display: 'none' }}>
        <FormInput name="user_security_id" register={register} errors={errors} />
      </Box>
    </Box>
  )
}

export default TwoFactorAuthenticationStep4
