import { FC, useEffect } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Flex, Text } from 'theme-ui'

import { useNavBarDisplay } from 'context/navBarDisplayContext'
import { useSideBarDisplay } from 'context/sideBarDisplay'

const MobileReturnHeader: FC<{
  backRef?: string
  delta?: number
  title?: string
  subtitle?: string
  toSidebar?: boolean
}> = ({ backRef = '/', title = '', delta, toSidebar = false, subtitle }) => {
  const navigate = useNavigate()
  const { setNavBarDisplay } = useNavBarDisplay()
  const { setSideBarDisplay } = useSideBarDisplay()

  useEffect(() => {
    setNavBarDisplay(false)

    return () => setNavBarDisplay(true)
  })

  const handleBack = () => {
    if (toSidebar) {
      setSideBarDisplay(true)
      navigate(-1)
      return
    }
    delta != null ? navigate(delta) : navigate(backRef)
  }

  return (
    <Flex
      sx={{
        background: 'sidebarButtonBG',
        px: 3,
        py: 4,
        height: 'auto',
        position: 'fixed',
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 3
      }}
    >
      <Box sx={{ width: 0, zIndex: 4 }}>
        <Button variant="round" onClick={handleBack}>
          <BsArrowLeft />
        </Button>
      </Box>
      <Flex variant="layout.flexCenterCenter" sx={{ flexDirection: 'column' }}>
        <Text color="secondaryText" variant="extraLarge">
          {title}
        </Text>
        {subtitle && (
          <Text color="textMuted" sx={{ mt: 1 }}>
            {subtitle}
          </Text>
        )}
      </Flex>
      <Box />
    </Flex>
  )
}

export default MobileReturnHeader
