import client from '../request'

export type CreateWithdraw = {
  amount: number
  token_type: string
  network_type: string
  recipient_addr: string
  two_fa_code: string
}

export type WithdrawResponse = {
  amount_requested?: number
  amount_requested_str?: string | number
  amount_withdrawn?: number
  amount_withdrawn_str?: string | number
  asset_id?: string | number
  audited_at: string | number
  auditor_id: string | number
  created_at: string
  id: string | number
  network_fee: number
  network_type: string
  reason?: string
  recipient_addr: string
  status: string
  token_type: string
  transaction_id: number
  txn_hash: string
  updated_at: string
  user_id: string | number
  wallet_id: string | number
  txn_number: string
}

// axios
const create = (data: CreateWithdraw) => {
  return client.post('/me/withdraws', data)
}

const WithdrawService = {
  create
}

export default WithdrawService
