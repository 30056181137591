import { FC } from 'react'
import { Card, Flex, Text, Box } from 'theme-ui'
import Countdown, { zeroPad } from 'react-countdown'
import { DateTime } from 'luxon'

const CountdownCard: FC = () => {
  return (
    <Card variant="secondary" padding={0}>
      <Flex
        variant="layout.flexCenterSpaceBetween"
        sx={{
          flexDirection: ['column', null, 'row'],
          width: '100%',
          py: 8,
          px: 8,
          alignItems: 'center'
        }}
      >
        <Box>
          <Text as="p" color="white" variant="largeTitle2">
            NFT REVEAL IN
          </Text>
        </Box>
        <Box mt={[4, 4, 0, 0]}>
          <Countdown
            date={DateTime.fromISO('2023-06-26T00:00:00', {
              zone: 'Asia/Kuala_Lumpur'
            }).toJSDate()}
            zeroPadTime={2}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                // Render a completed state
                return <Text variant="largeTitle">NOW</Text>
              } else {
                console.log(seconds)
                // Render a countdown
                return (
                  <Box sx={{ flex: 1 }}>
                    <Flex>
                      <Card
                        bg="#1f2152"
                        mr={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 3,
                          width: 70
                        }}
                      >
                        <Text variant="largeTitle">{zeroPad(days)}</Text>
                        <Text variant="small">Days</Text>
                      </Card>
                      <Card
                        bg="#1f2152"
                        mr={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 3,
                          width: 70
                        }}
                      >
                        <Text variant="largeTitle">{zeroPad(hours)}</Text>
                        <Text variant="small">Hours</Text>
                      </Card>
                      <Card
                        bg="#1f2152"
                        mr={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 3,
                          width: 70
                        }}
                      >
                        <Text variant="largeTitle">{zeroPad(minutes)}</Text>
                        <Text variant="small">Minutes</Text>
                      </Card>
                      <Card
                        bg="#1f2152"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 3,
                          width: 70
                        }}
                      >
                        <Text variant="largeTitle">{zeroPad(seconds)}</Text>
                        <Text variant="small">Seconds</Text>
                      </Card>
                    </Flex>
                  </Box>
                )
              }
            }}
          />
        </Box>
      </Flex>
    </Card>
  )
}

export default CountdownCard
