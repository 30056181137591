/** @jsxImportSource theme-ui */
import { FC, useState } from 'react'
import { Box, Flex, Text, IconButton, Button } from 'theme-ui'
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { BsArrowRight } from 'react-icons/bs'
import useSWR from 'swr'

import PoolService, { Pool } from 'network/services/pool'
import { IDataResponse, serialize } from 'network/request'
import HorizontalScroller from 'layout/horizontal-scroller'
import Link from 'components/link'
import TokenIcon from 'components/icons/token'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'
import commaNumber from 'modules/comma-number'
import PageControl from 'components/page-control'

interface PoolHeader {
  name: string
  key: keyof Pool
}

interface SortStatus {
  key: string
  ascending: boolean
}

const StakePoolAmplifyPage = () => {
  const [page, setPage] = useState(1)
  const [sortStatus, setSortStatus] = useState<SortStatus>({ key: 'token_type', ascending: true })
  const limit = 10
  const { data, error, mutate } = useSWR<IDataResponse<Pool>>(
    serialize(PoolService.getAll, {
      type: 'amplify',
      page: page,
      limit: limit,
      sort: `${sortStatus.key}:${sortStatus.ascending ? 'asc' : 'desc'}`
    })
  )

  const [headers] = useState<PoolHeader[]>([
    {
      name: 'Token',
      key: 'token_type'
    },
    {
      name: 'Current Pool Size',
      key: 'pool_size'
    },
    {
      name: 'Avg. Pool APY',
      key: 'apy'
    },
    {
      name: 'Total Ps.',
      key: 'max_pool_size'
    }
  ])

  const handleSort = (key: keyof Pool) => {
    setSortStatus({ key: key, ascending: !sortStatus.ascending })
  }

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve amplify pools" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const total = PoolService.toPaginate(data).total

  return (
    <Box>
      <HorizontalScroller>
        <table sx={{ width: [900, '100%'] }} cellSpacing={0}>
          <thead>
            <tr
              sx={{
                margin: 0
              }}
            >
              {headers?.map((item, index) => (
                <th
                  key={index}
                  sx={{
                    borderWidth: '0 0 2px 0',
                    pl: 10,
                    borderStyle: 'solid',
                    borderColor: 'darkBG'
                  }}
                >
                  <Flex sx={{ py: 5, flexDirection: 'column' }}>
                    <Flex variant="layout.flexCenterStart" sx={{ flex: 1, fontWeight: 'normal' }}>
                      <Text sx={{ display: 'block' }}>{item.name}</Text>
                      <IconButton
                        sx={{ color: 'text', fontSize: 10 }}
                        onClick={() => handleSort(item.key as keyof Pool)}
                      >
                        {sortStatus?.key === item.key ? (
                          sortStatus.ascending ? (
                            <TiArrowSortedDown size="0.1em" />
                          ) : (
                            <TiArrowSortedUp size="0.1em" />
                          )
                        ) : (
                          <TiArrowUnsorted size="0.1em" />
                        )}
                      </IconButton>
                    </Flex>
                    <Box>
                      {index === 2 && (
                        <Flex variant="layout.flexCenterStart">
                          <Text variant="mediumSmall" color="textMuted">
                            (Last 3 mths)
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  </Flex>
                </th>
              ))}
              <th
                sx={{
                  borderWidth: '0 0 2px 0',
                  pl: 10,
                  borderStyle: 'solid',
                  borderColor: 'darkBG'
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <TableBody pools={data?.data} />
          </tbody>
        </table>
      </HorizontalScroller>

      <Box p={2} />

      {data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Box>
  )
}

const TableBody: FC<{ pools?: Pool[] }> = ({ pools }) => {
  return (
    <>
      {pools?.map((item, index) => (
        <tr key={item.id ?? index}>
          <td sx={{ py: 8, pl: 10 }}>
            <Flex variant="layout.hStack" sx={{ alignItems: 'flex-start' }}>
              <TokenIcon type={item.token_type} />
              <Box>
                <Flex variant="layout.vStack">
                  <Box>
                    <Text as="p" variant="large">
                      {item.token_type}
                    </Text>
                  </Box>
                  <Box>
                    <Text as="p" color="textMuted">
                      {item.token_name}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </td>
          <td sx={{ py: 8, pl: 10 }}>
            <Text>{`${commaNumber(item.pool_size)} ${item.token_type}`}</Text>
          </td>
          <td sx={{ py: 8, pl: 10 }}>
            <Text color={item.apy > 0 ? 'success' : 'danger'}>{item.apy ?? '-'}%</Text>
          </td>
          <td sx={{ py: 8, pl: 10 }}>
            <Text>{item.total_participants_count}</Text>
          </td>
          <td sx={{ py: 8, pl: 10 }}>
            <Flex variant="layout.flexCenterCenter">
              <Link to={`/stake/submit/${item.id}`} variant="unstyled">
                <Button
                  variant="primaryFlexCenter"
                  sx={{ justifyContent: 'space-between', minWidth: [85, 85, 150] }}
                >
                  <Box />
                  <Text>Stake</Text>
                  <BsArrowRight size="1em" />
                </Button>
              </Link>
              <Link to={`/stake/overview/${item.id}`} sx={{ textDecoration: 'underline', ml: 5 }}>
                More info
              </Link>
            </Flex>
          </td>
        </tr>
      ))}
    </>
  )
}

export default StakePoolAmplifyPage
