import client, { IPostResponse } from '../request'

// useSWR, return strings
const get = (filename: string) => {
  return `/uploads/${filename}`
}

// axios
const create = (file: File) => {
  const data = new FormData()
  data.append('file', file)
  return client.post<IPostResponse<string>>('/uploads', data)
}

const FileService = {
  get,
  create
}

export default FileService
