import CustomModal from './modal'
import { FC, ReactNode } from 'react'
import { Box, Card, Heading, Image, Flex, Button } from 'theme-ui'

const StatusModal: FC<{
  isOpen: boolean
  onRequestClose?: () => void
  children?: ReactNode
  success?: Boolean
}> = ({ isOpen, onRequestClose, children, success = true }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
      contentStyle={{
        height: '50%'
      }}
    >
      <Box sx={{ pt: 15 }}>
        <Card sx={{ width: 300, mx: 'auto' }}>
          <Box sx={{ height: 80, position: 'relative' }}>
            <Flex
              variant="layout.flexCenterCenter"
              sx={{
                position: 'absolute',
                top: -12
              }}
            >
              {success ? (
                <Image src="/assets/images/successful.png" />
              ) : (
                <Image src="/assets/images/failed.png" />
              )}
            </Flex>
          </Box>
          <Box p={2} />
          <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
            {success ? <Heading>Successful</Heading> : <Heading>Failed</Heading>}
            <Box sx={{ width: '100%' }}>{children}</Box>
            <Box p={2} />
            <Button onClick={onRequestClose}>Close</Button>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

export default StatusModal
