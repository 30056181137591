import { FC, ReactNode } from 'react'
import { Box } from 'theme-ui'

const ResponsivePoolCardContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box sx={{ overflowY: ['scroll', 'initial'], width: ['100%', 'auto'], px: [5, 0] }}>
      <Box sx={{ width: [800, 'auto'] }}>{children}</Box>
    </Box>
  )
}

export default ResponsivePoolCardContainer
