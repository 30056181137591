import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'
import { localeStore } from './store/locale'

export type LanguageSnapshot = { state: { language: 'en' | 'zh_CN' } }

export type DynamicStringTranslation = {
  en: string
  zh_CN: string
}

const I18n = () => {
  const { i18n } = useTranslation()
  const {
    state: { language }
  } = useSnapshot(localeStore)

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [language, i18n])

  return <></>
}

export default I18n
