import { Box, Text } from 'theme-ui'
import SidebarButton from './sidebar-button'

import { getProfile } from 'store/auth'

const QuickAccess = () => {
  const profile = getProfile()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Text>Quick Access</Text>
      <Box pt={6} />
      <SidebarButton
        text={'Identity Verification'}
        icon={'/assets/svg/identity.svg'}
        // link="/identity-verification"
        link="#"
        subText={profile?.verified ? 'Verified' : 'Not Verified'}
      />
      <Box pt={4} />
      <SidebarButton text={'Security'} icon={'/assets/svg/surface1.svg'} link="/security" />
      {/* <Box pt={4} />
      <SidebarButton text={'App Setting'} icon={'/assets/svg/setting.svg'} link={'app-setting'} /> */}
    </Box>
  )
}

export default QuickAccess
