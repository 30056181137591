import toUTCTime from 'modules/to-utc-time'
import { IDataResponse } from '../request'

export type LoginActivity = {
  id: string | number
  type: number
  location: string
  ip_address: string
  device: string
  provider: string
  user_id: string | number
  created_at: string
  updated_at: string
}

// useSWR, return strings
const getAll = '/me/user-activities?sort=created_at:desc'

// useSWR, return strings
const get = (id: string | number) => {
  return `/me/user-activities/${id}`
}

// processing
const toRow = (data: IDataResponse<LoginActivity>): LoginActivity[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<LoginActivity>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const LoginActivityService = {
  getAll,
  get,
  toPaginate,
  toRow
}

export default LoginActivityService
