import React from 'react'
import { FC } from 'react'
import { Box, Card, Image, Text, Flex, AspectRatio } from 'theme-ui'

interface NFTSlideshowProps {
  image: string
  name: string
  highestBid: number
  email: string
  isLarge?: boolean
}

const NFTSlideshowCard: FC<{
  data: NFTSlideshowProps[]
  dataIndex: number
  isCenterSlide: boolean
  slideIndex: number
}> = React.memo((props) => {
  const { image, name, highestBid, email, isLarge = true } = props.data[props.dataIndex]

  return (
    <Box sx={{ width: '100%' }}>
      <AspectRatio ratio={1 / 1}>
        <Card
          variant={isLarge ? 'NFTSlideShowCardLarge' : 'NFTSlideShowCardSmall'}
          sx={{
            backgroundImage: `url(${image})`
          }}
        >
          <Flex variant="layout.vStack" px={6} mb={6}>
            <Text variant={isLarge ? 'default' : 'small'} sx={{ fontWeight: 'bold' }}>
              {name}
            </Text>
            <Text variant={isLarge ? 'mediumSmall' : 'small'}>Highest bid {highestBid} VXT</Text>
            <Box />
            <Box>
              <Flex variant="layout.hStack">
                <Image src={'/assets/svg/user-logo.svg'} sx={{ height: 30 }} />
                <Box>
                  <Text variant={isLarge ? 'mediumSmall' : 'small'} color="textMuted">
                    {email}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Card>
      </AspectRatio>
    </Box>
  )
})

export default NFTSlideshowCard
