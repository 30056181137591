/** @jsxImportSource theme-ui */
import { Box, Button, Card, Flex, Grid, IconButton, Text, Image, Input } from 'theme-ui'
import { BsArrowRight, BsEmojiSmile } from 'react-icons/bs'
import { FC, FormEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useResolution } from 'hooks/useResolution'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'

import useSWR from 'swr'
import TicketService, { CreateMessage } from 'network/services/tickets'
import { serialize } from 'network/request'

import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'

import FormInput from 'components/form-input'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import StatusModal from 'components/status-modal'
import _ from 'lodash'

import FileService from 'network/services/file'

const HelpCentreChat = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Help Centre" delta={-1} />}
      <TitleWithBack backRef="/help" title="Help Centre" />
      <PageView />
    </Box>
  )
}

const PageView = () => {
  const { id } = useParams()
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const { data: ticket, error: ticketError } = useSWR(
    id != null ? serialize(TicketService.getTicket(id)) : null
  )

  const {
    data: ticketMessage,
    error: messageError,
    mutate
  } = useSWR(
    id != null ? serialize(TicketService.getTicketMessage(id), { sort: 'created_at:asc' }) : null
  )

  const method = useForm<CreateMessage>({
    shouldUseNativeValidation: false
  })

  useEffect(() => {
    id && method.setValue('ticket_id', id)
  }, [id, method])

  if (ticketError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Ticket details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!ticket) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  if (messageError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Message details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!ticketMessage) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const handleSubmit = method.handleSubmit(async (data) => {
    method.clearErrors()
    try {
      const { data: result } = await TicketService.createMessage(data)
      if (result.success) {
        mutate()
        method.setValue('message', '')
      }
    } catch (e: any) {
      setMessage({
        success: false,
        isOpen: true,
        message: e.message ?? ''
      })
    }
  })

  const messages = TicketService.toRowMessage(ticketMessage)
  return (
    <Box>
      <Box variant="pageContainer">
        <Box p={2} />
        <Box
          sx={{
            height: '100%',
            overflowY: 'scroll',
            scrollbarWidth: ['auto', 'none'], //for firefox
            '::-webkit-scrollbar': {
              display: ['auto', 'none']
            }
          }}
        >
          <Grid>
            {messages.map((item) => (
              <ChatDialogue
                text={item.message}
                isAdmin={!(ticket.data.user_id === item.sender_id)}
              />
            ))}
          </Grid>
        </Box>
        <Box p={5} />
      </Box>
      <Box as="form" onSubmit={handleSubmit}>
        <FormProvider {...method}>
          <ChatInput />
        </FormProvider>
      </Box>
      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        success={message.success}
        onRequestClose={() => setMessage({ isOpen: false })}
      />
    </Box>
  )
}

const ChatDialogue = ({
  isAdmin = false,
  text,
  time = '11:05 PM'
}: {
  isAdmin?: boolean
  text: string
  time?: string
}) => {
  return (
    <Flex
      variant={isAdmin ? 'layout.flexStartStart' : 'layout.flexStartEnd'}
      sx={{ width: 'auto' }}
    >
      {isAdmin && (
        <Box variant="layout.iconContianer" sx={{ width: 50 }}>
          <Image src="/assets/svg/user-logo.svg" />
        </Box>
      )}
      <Box
        sx={{
          mx: 3,
          flex: 1,
          textAlign: isAdmin ? 'left' : 'right'
        }}
      >
        <Card
          variant={isAdmin ? 'chatAdmin' : 'chatUser'}
          sx={{
            width: 'auto',
            maxWidth: ['100%', '60%'],
            display: 'inline-block',
            textAlign: 'left',
            overflowWrap: 'break-word'
          }}
        >
          {text.includes('[image]') ? <ImageDisplay text={text} /> : <Text>{text}</Text>}
        </Card>
        <Box p={1} />
        <Box sx={{ width: '100%', textAlign: isAdmin ? 'left' : 'right' }}>
          <Text variant="mediumSmall">{time}</Text>
        </Box>
      </Box>
      {!isAdmin && (
        <Box variant="layout.iconContianer" sx={{ width: 50 }}>
          <Image src="/assets/svg/user-logo.svg" />
        </Box>
      )}
    </Flex>
  )
}

const ChatInput = () => {
  const {
    setValue,
    register,
    formState: { errors }
  } = useFormContext<CreateMessage>()
  const { isMobile } = useResolution()

  const uploadRef = useRef<HTMLInputElement | null>(null)

  const upload = async (e: FormEvent<HTMLInputElement>) => {
    // make sure files is not empty
    if (e.currentTarget?.files == null || _.isEmpty(e.currentTarget?.files)) {
      return
    }
    const files = e.currentTarget?.files
    try {
      const { data } = await FileService.create(files[0])

      if (data.success) {
        setValue('message', ('[image]' + data.data) as any)
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  return (
    <Card variant="chatInputFooter" px={[2, 0]}>
      <Box variant="layout.mainContainer" my={0}>
        <Flex variant="layout.flexCenterStart">
          <Card variant="chatInputCard" sx={{ py: [1, 2], px: [2, 4] }}>
            <Flex variant="layout.hStack">
              {/* <IconButton sx={{ color: 'white' }}>
                <BsEmojiSmile />
              </IconButton>
              <Text variant="extraLarge" color="primary" sx={{ mx: 1 }}>
                |
              </Text> */}
              {/* to trigger upload dialog */}
              <Input type="file" ref={uploadRef} sx={{ display: 'none' }} onChange={upload} />
              <IconButton
                sx={{ color: 'white' }}
                type="button"
                onClick={() => {
                  uploadRef?.current?.click()
                }}
              >
                <Image src="/assets/svg/paper-clip.svg" />
              </IconButton>
            </Flex>
            <FormInput<CreateMessage>
              register={register}
              errors={errors}
              name="message"
              placeholder="Send a message..."
              rules={{
                required: 'You must enter a Message'
              }}
              containerSX={{ bg: 'sidebarButtonBG', flex: 7, py: 0, textAlign: 'left' }}
            />
          </Card>

          <Button variant={isMobile ? 'round' : 'primaryFlexCenter'} type="submit" sx={{ ml: 3 }}>
            <Flex variant="layout.hStack">
              {!isMobile && <Text>send</Text>}
              <BsArrowRight />
            </Flex>
          </Button>
        </Flex>
      </Box>
    </Card>
  )
}

const ImageDisplay: FC<{ text: string }> = ({ text }) => {
  const uploadedFile = text.replace('[image]', '')
  const { data: fileLink, error: fileError, mutate } = useSWR(FileService.get(uploadedFile))

  if (fileError) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Image" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!fileLink) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Box>
      <Image src={fileLink.data} />
    </Box>
  )
}

export default HelpCentreChat
