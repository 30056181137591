import { Box, IconButton, Image } from 'theme-ui'
import socialLinkUrl from 'components/socialLinksUrl'
const SocialLink = () => {
  return (
    <Box>
      <a href={socialLinkUrl.facebook} target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/facebook.svg" />
        </IconButton>
      </a>
      <a href={socialLinkUrl.telegram} target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/telegram.svg" />
        </IconButton>
      </a>
      <a href={socialLinkUrl.twitter} target="_blank" rel="noreferrer">
        <IconButton>
          <Image src="/assets/svg/twitter.svg" />
        </IconButton>
      </a>
      <a
        href={socialLinkUrl.tiktok}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'inline-block', width: '25px', height: '32px' }}
      >
        <IconButton>
          <Image src="/assets/svg/tiktok.svg" />
        </IconButton>
      </a>
      <a
        href={socialLinkUrl.instagram}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'inline-block', width: '25px', height: '32px' }}
      >
        <IconButton>
          <Image src="/assets/svg/instagram.svg" />
        </IconButton>
      </a>
      <a
        href={socialLinkUrl.youtube}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'inline-block', width: '25px', height: '32px' }}
      >
        <IconButton>
          <Image src="/assets/svg/youtube.svg" />
        </IconButton>
      </a>

      <a
        href={socialLinkUrl.xiaohongshu}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'inline-block', width: '25px', height: '32px' }}
      >
        <IconButton>
          <Image src="/assets/svg/xiaohongshu.svg" />
        </IconButton>
      </a>
    </Box>
  )
}

export default SocialLink
