import { Box, Flex, Text } from 'theme-ui'
import TitleWithBack from 'components/TitleWithBack'
import { AnnouceCard } from 'components/Announcements'
import { useResolution } from 'hooks/useResolution'
import MobileReturnHeader from 'components/MobileReturnHeader'
import AnnouncementService, { Announcement } from 'network/services/announcement'
import useSWR from 'swr'
import { IDataResponse, serialize } from 'network/request'
import _ from 'lodash'
import { FC, useState } from 'react'
import PageControl from 'components/page-control'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'

const AnnouncementsPage = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Announcements" delta={-1} />}
      <TitleWithBack delta={-1} title="Announcements" />
      <PageView />
    </Box>
  )
}

const PageView = () => {
  const [page, setPage] = useState(1)
  const limit = 10
  const { data, error, mutate } = useSWR<IDataResponse<Announcement>>(
    serialize(AnnouncementService.getAnnouncements, {
      sort: 'published_at:desc',
      page: page,
      limit: limit
    })
  )
  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve announcements" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const announcements = AnnouncementService.toRow(data)
  const total = AnnouncementService.toPaginate(data).total

  if (_.isEmpty(announcements)) {
    return (
      <Box>
        <Text>There are currently no annoucement</Text>
      </Box>
    )
  }

  return (
    <Box>
      {announcements.map((item) => (
        <AnnouceCard announcement={item} />
      ))}

      {data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Box>
  )
}

export default AnnouncementsPage
