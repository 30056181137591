const socialLinkUrl = {
  facebook: 'https://www.facebook.com/coinpubliczone',
  youtube: 'https://www.youtube.com/@coinpubliczone',
  telegram: 'https://t.me/coinpubliczone',
  twitter: 'https://twitter.com/coinpubliczone',
  instagram: 'https://www.instagram.com/coinpubliczone/',
  tiktok: 'https://www.tiktok.com/@coinpubliczone',
  xiaohongshu:
    'https://www.xiaohongshu.com/user/profile/6400638b00000000290164b3?xhsshare=CopyLink&appuid=5f648a850000000001002757&apptime=1678156729'
}

export default socialLinkUrl
