import client, { IDataResponse } from '../request'
import { NFT } from './nft'

export type NFTWithdraw = {
  id: number | string
  token_type: string
  network_type: string
  status: string
  reason: string
  recipient_addr: string
  network_fee: number
  txn_no: number
  txn_hash: number
  nft_id: number | string
  user_id: number | string
  auditor_id: number | string
  transaction_id: number | string
  audited_at: string
  created_at: string
  updated_at: string
  nft: NFT
}

export type CreateNFTWithdraw = {
  token_type: string
  network_type: string
  recipient_addr: string
  nft_id: number | string
  two_fa_code: string
}

const getAll = '/me/nft-withdraws'

const getOne = (id: string | number) => {
  return `/me/nft-withdraws/${id}`
}

const create = (data: CreateNFTWithdraw) => {
  return client.post('/me/nft-withdraws', data)
}

const toRow = (data: IDataResponse<NFTWithdraw>): NFTWithdraw[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }
  return []
}

const toPaginate = (data: IDataResponse<NFTWithdraw>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const NFTWithdrawService = {
  getAll,
  getOne,
  create,
  toRow,
  toPaginate
}

export default NFTWithdrawService
