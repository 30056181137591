import TitleWithBack from 'components/TitleWithBack'
import { Box, Button, Card, Flex, Grid, Heading, Paragraph, Text } from 'theme-ui'
import { FiShare } from 'react-icons/fi'
import { IoIosArrowDown } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import ProposalService, { CreateVote, Proposal, Vote } from 'network/services/proposals'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import _, { first } from 'lodash'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { FC, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import StatusModal from 'components/status-modal'
import PageControl from 'components/page-control'
import { serialize } from 'network/request'
import MobileReturnHeader from 'components/MobileReturnHeader'
import { useResolution } from 'hooks/useResolution'
import toUTCTime from 'modules/to-utc-time'
import CountdownCardProposal from './countdown-card'
import { BsArrowRight } from 'react-icons/bs'
import CustomModal from 'components/modal'
import { DateTime } from 'luxon'

const getVoteAnswer = (value: string) => {
  switch (value) {
    case 'yes':
      return 'Agree'
    case 'no':
      return 'Disagree'
    default:
      return value
  }
}

const ProposalsDetailPage = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Proposal" backRef="/proposals" />}
      <TitleWithBack backRef="/proposals" title="Back to Proposals List" />
      <PageView />
    </Box>
  )
}

const PageView = () => {
  const { id } = useParams()
  const {
    data: proposalData,
    error,
    mutate
  } = useSWR<{ data: Proposal }>(id != null ? ProposalService.getProposal(id) : null)

  const [showVoteTable, setShowVoteTable] = useState(false)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve proposal details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!proposalData) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const proposal = proposalData.data

  return (
    <Box>
      <Grid gap={4} sx={{ width: '100%' }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: ['start', 'center'],
            flexDirection: ['column', 'row']
          }}
        >
          <Heading as="h1" sx={{ maxWidth: ['100%', '60%'] }}>
            {proposal.title}
          </Heading>
          {/* <Box pt={[2, 0]}>
            <Button sx={{ height: 'fit-content' }}>
              <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <FiShare />
                <Box padding={1} />
                <Text>Share</Text>
              </Flex>
            </Button>
          </Box> */}
        </Flex>

        <Text sx={{ whiteSpace: 'break-spaces' }}>{proposal.description}</Text>

        {/* <Box sx={{ background: 'buttonBG', py: 2, px: 8, borderRadius: 30, width: 'fit-content' }}>
          <Text variant="buttonText" color="white">
            {_.startCase(proposal.status)}
          </Text>
        </Box> */}

        <Grid columns={[1, null, '3fr 2fr']} gap={6}>
          <Grid sx={{ gridRow: [2, null, 1] }}>
            {DateTime.fromISO(proposal.expired_at).diffNow('days').days > 0 &&
              proposal.can_vote && (
                // don't show if expired
                <>
                  {proposal.has_voted ? (
                    <Card sx={{ px: 8 }}>
                      <Text variant="mediumTitle" color="secondaryText">
                        Your Vote
                      </Text>
                      <VoteForm proposal={proposal} readOnly={true} />
                    </Card>
                  ) : (
                    proposal.can_vote && (
                      <Card sx={{ px: 8 }}>
                        <Text variant="mediumTitle" color="secondaryText">
                          Cast Your Vote
                        </Text>
                        <VoteForm proposal={proposal} />
                      </Card>
                    )
                  )}
                </>
              )}

            <Card sx={{ px: 8 }}>
              <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Text variant="mediumTitle" color="secondaryText">
                  Votes
                </Text>

                <Box
                  sx={{
                    background: 'buttonBG',
                    py: 2,
                    px: 8,
                    borderRadius: 30,
                    width: 'fit-content'
                  }}
                >
                  <Text variant="buttonText" color="white">
                    {proposal.votes_count} users
                  </Text>
                </Box>
              </Flex>

              {showVoteTable ? (
                <VotesTable proposal={proposal} />
              ) : (
                <>
                  <Box pt={3}>
                    {proposal.votes.map((vote) => {
                      return (
                        <Grid
                          columns={4}
                          py={3}
                          sx={{ borderBottom: '1px solid', borderColor: 'grayDark' }}
                        >
                          <Flex sx={{ alignItems: 'center' }}>
                            <Box
                              sx={{ borderRadius: 999, background: 'info', width: 14, height: 14 }}
                            ></Box>
                            <Box sx={{ px: 1 }} />
                            <Text style={{ wordWrap: 'break-word' }}>{vote.txn_hash}</Text>
                          </Flex>

                          <Box sx={{ textAlign: 'right' }}>
                            <Text>{getVoteAnswer(vote.answer)}</Text>
                          </Box>

                          {/* need to aggregate by username? No need, redundant column */}
                          <Box sx={{ textAlign: 'right' }}>
                            <Text>1 Vote</Text>
                          </Box>
                          <Box sx={{ textAlign: 'right' }}>
                            <Text>{toUTCTime(vote.created_at)}</Text>
                            {/* <Text>{vote.created_at}</Text> */}
                          </Box>
                        </Grid>
                      )
                    })}
                  </Box>

                  <Flex pt={4} sx={{ justifyContent: 'center' }}>
                    <Button
                      variant="secondary"
                      backgroundColor="transparent"
                      onClick={() => {
                        setShowVoteTable(true)
                      }}
                    >
                      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Text>See More</Text>
                        <Box p={1} />
                        <IoIosArrowDown color="blue" />
                      </Flex>
                    </Button>
                  </Flex>
                </>
              )}
            </Card>
          </Grid>

          <Grid sx={{ gridRow: [1, null, 1] }}>
            {proposal.can_vote && (
              <Card className="12">
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CountdownCardProposal title="Expired in:" expired_at={proposal.expired_at} />
                </Box>
              </Card>
            )}

            <Card sx={{ height: 'fit-content' }}>
              <Text variant="mediumTitle" color="secondaryText">
                Current Results
              </Text>

              {(proposal.choices?.length ?? 0) > 0 ? (
                <>
                  {proposal.choices.map((choice) => {
                    const choiceIdx = proposal.choices.indexOf(choice)
                    return (
                      <Box pt={2}>
                        <Flex>
                          <Text>{choice}</Text>
                          <Box sx={{ flex: 1, textAlign: 'right', px: 4 }}>
                            <Text color="secondary">{proposal.choices_count[choiceIdx]} Voted</Text>
                          </Box>
                          <Text>
                            {((proposal.choices_count[choiceIdx] ?? 0) > 0
                              ? ((proposal.choices_count[choiceIdx] ?? 0) / proposal.votes_count) *
                                100
                              : 0
                            ).toFixed(2)}
                            %
                          </Text>
                        </Flex>
                        <Box
                          sx={{
                            height: '16px',
                            width: '100%',
                            background: 'buttonSecondaryBG',
                            borderRadius: 30
                          }}
                        >
                          <Box
                            sx={{
                              height: '16px',
                              width: `${((proposal.choices_count[choiceIdx] ?? 0) > 0
                                ? ((proposal.choices_count[choiceIdx] ?? 0) /
                                    proposal.votes_count) *
                                  100
                                : 0
                              ).toFixed(2)}%`,
                              background: 'buttonBG',
                              borderRadius: 30
                            }}
                          />
                        </Box>
                      </Box>
                    )
                  })}
                </>
              ) : (
                <>
                  <Box pt={2}>
                    <Flex>
                      <Text>Agree</Text>
                      <Box sx={{ flex: 1, textAlign: 'right', px: 4 }}>
                        <Text color="secondary">{proposal.yes_votes} Voted</Text>
                      </Box>
                      <Text>{(proposal.yes_percentage ?? 0).toFixed(2)}%</Text>
                    </Flex>
                    <Box
                      sx={{
                        height: '16px',
                        width: '100%',
                        background: 'buttonSecondaryBG',
                        borderRadius: 30
                      }}
                    >
                      <Box
                        sx={{
                          height: '16px',
                          width: `${(proposal.yes_percentage ?? 0).toFixed(2)}%`,
                          background: 'buttonBG',
                          borderRadius: 30
                        }}
                      />
                    </Box>
                  </Box>

                  <Box py={3}>
                    <Flex>
                      <Text>Disagree</Text>
                      <Box sx={{ flex: 1, textAlign: 'right', px: 4 }}>
                        <Text color="secondary">{proposal.no_votes} Voted</Text>
                      </Box>
                      <Text>{(proposal.no_percentage ?? 0).toFixed(2)}%</Text>
                    </Flex>
                    <Box
                      sx={{
                        height: '16px',
                        width: '100%',
                        background: 'buttonSecondaryBG',
                        borderRadius: 30
                      }}
                    >
                      <Box
                        sx={{
                          height: '16px',
                          width: `${(proposal.no_percentage ?? 0).toFixed(2)}%`,
                          background: 'buttonBG',
                          borderRadius: 30
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const VotesTable: FC<{ proposal: Proposal }> = ({ proposal }) => {
  const [page, setPage] = useState(1)
  const limit = 5

  const {
    data: votesData,
    error,
    mutate
  } = useSWR<{ data: Vote[] }>(
    serialize(ProposalService.getAllVotes(proposal.id), {
      sort: 'created_at:desc',
      page: page,
      limit: limit
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve proposal details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!votesData) {
    return <></>
  }

  const votes = ProposalService.votesToRow(votesData)
  const total = ProposalService.votesToPaginate(votesData).total

  return (
    <Box pt={3}>
      {votes.map((vote) => {
        return (
          <Grid columns={4} py={3} sx={{ borderBottom: '1px solid', borderColor: 'grayDark' }}>
            <Flex sx={{ alignItems: 'center' }}>
              <Box sx={{ borderRadius: 999, background: 'info', width: 14, height: 14 }}></Box>
              <Box sx={{ px: 2 }} />
              <Text style={{ wordWrap: 'break-word' }}>{vote.txn_hash}</Text>
            </Flex>

            <Box sx={{ textAlign: 'center' }}>
              <Text>{getVoteAnswer(vote.answer)}</Text>
            </Box>

            {/* need to aggregate by username? */}
            <Box sx={{ textAlign: 'right' }}>
              <Text>1 Vote</Text>
            </Box>
            <Box sx={{ textAlign: 'right' }}>{vote.created_at}</Box>
          </Grid>
        )
      })}

      <Box pt={2} />

      {votesData && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Box>
  )
}

const VoteForm: FC<{ proposal: Proposal; readOnly?: boolean }> = ({
  proposal,
  readOnly = false
}) => {
  const [requestOpen, setRequestOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })

  const methods = useForm<CreateVote>({
    shouldUseNativeValidation: false,
    defaultValues: {
      answer: first(proposal.me_votes)?.answer
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors }
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    setRequestOpen(false)

    try {
      const { data: result } = await ProposalService.createVote(proposal.id, data)

      if (result.success) {
        mutate(ProposalService.getProposal(proposal.id))
        reset()
        setMessage({
          isOpen: true,
          success: true,
          message: 'Vote cast successfully'
        })
      }
    } catch (error: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: error.message
      })
    }
  })

  return (
    <>
      <Grid pt={4} as="form" id="vote-form" onSubmit={onSubmit}>
        <FormProvider {...methods}>
          <input type="hidden" {...register('answer', { required: 'Please cast your vote' })} />
          {(proposal.choices?.length ?? 0) > 0 ? (
            <>
              {proposal.choices.map((choice) => {
                return (
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      if (!readOnly) {
                        setValue('answer', choice)
                      }
                    }}
                    sx={{
                      border: '1px solid',
                      borderColor: watch('answer') === choice ? 'white' : 'transparent',
                      backgroundColor: watch('answer') === choice ? 'green' : 'transparent'
                    }}
                  >
                    <Text variant="buttonText">{choice}</Text>
                  </Button>
                )
              })}
            </>
          ) : (
            <>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  if (!readOnly) {
                    setValue('answer', 'yes')
                  }
                }}
                sx={{
                  border: '1px solid',
                  borderColor: watch('answer') === 'yes' ? 'white' : 'transparent',
                  backgroundColor: watch('answer') === 'yes' ? 'green' : 'transparent'
                }}
              >
                <Text variant="buttonText">Agree</Text>
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  if (!readOnly) {
                    setValue('answer', 'no')
                  }
                }}
                sx={{
                  border: '1px solid',
                  borderColor: watch('answer') === 'no' ? 'white' : 'transparent',
                  backgroundColor: watch('answer') === 'no' ? 'red' : 'transparent'
                }}
              >
                <Text variant="buttonText">Disagree</Text>
              </Button>
            </>
          )}

          <ErrorMessage
            errors={errors}
            render={({ message }) => <Text color="white">{message}</Text>}
            name="answer"
          />

          {!readOnly && (
            <Button
              type="button"
              onClick={async () => {
                if (await trigger()) {
                  setRequestOpen(true)
                }
              }}
            >
              <Text variant="buttonText">Vote</Text>
            </Button>
          )}

          <CustomModal
            isOpen={requestOpen}
            onRequestClose={() => {
              setRequestOpen(false)
            }}
            overlayStyle={{
              justifyContent: 'center',
              textAlign: 'center'
            }}
            contentStyle={{
              height: '36%'
            }}
          >
            <Box
              sx={{
                textAlign: 'center'
              }}
            >
              <Card>
                <Paragraph>Are you sure you want vote?</Paragraph>
                <Box pt={4} />
                <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
                  <Button
                    onClick={() => {
                      setRequestOpen(false)
                    }}
                    sx={{ background: 'muted' }}
                  >
                    Cancel
                  </Button>
                  <Box>
                    <Button form="vote-form" type="submit">
                      Confirm
                    </Button>
                  </Box>
                </Flex>
              </Card>
            </Box>
          </CustomModal>
        </FormProvider>
      </Grid>

      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        onRequestClose={() => setMessage({ isOpen: false })}
        success={message.success}
      />
    </>
  )
}

export default ProposalsDetailPage
