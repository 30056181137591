import { IDataResponse } from '../request'
import toUTCTime from 'modules/to-utc-time'

export type Announcement = {
  id: number
  title: string
  content: string
  type: number
  published: boolean
  published_at: string
}

// useSWR, return strings
const getAnnouncements = '/announcements'

const getAnnouncement = (id: string) => {
  return `/announcements/${id}`
}

// processing
const toRow = (data?: IDataResponse<Announcement>): Announcement[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id,
        published_at: toUTCTime(element.published_at)
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<Announcement>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const AnnouncementService = {
  getAnnouncements,
  getAnnouncement,
  toRow,
  toPaginate
}

export default AnnouncementService
