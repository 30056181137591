/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { Flex, Button, Heading, Text, Box } from 'theme-ui'

import { useResolution } from 'hooks/useResolution'

const TitleWithBack: FC<{
  backRef?: string
  delta?: number
  title?: string
  subtitle?: string
}> = ({ backRef = '/', title = '', subtitle, delta }) => {
  const navigate = useNavigate()
  const { isMobile } = useResolution()

  return (
    <>
      {!isMobile && (
        <Flex
          variant={subtitle ? 'layout.flexStartStart' : 'layout.flexCenterStart'}
          sx={{ mb: 10 }}
        >
          <Button
            sx={{ mr: 7 }}
            onClick={() => (delta != null ? navigate(delta) : navigate(backRef))}
          >
            <Flex variant="layout.hStack">
              <BsArrowLeft sx={{ flex: 1 }} />
              <Box>
                <Text>Back</Text>
              </Box>
              <Box sx={{ flex: 1 }} />
            </Flex>
          </Button>
          <Box>
            <Heading color="secondaryText">{title}</Heading>
            {subtitle != null && (
              <>
                <Box p={1} />
                <Box>
                  <Text variant="large">{subtitle}</Text>
                </Box>
              </>
            )}
          </Box>
        </Flex>
      )}
    </>
  )
}

export default TitleWithBack
