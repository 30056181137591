/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Box, Card, Heading, Text, Image, Flex } from 'theme-ui'
import { motion } from 'framer-motion'

const LoadingCard: FC<{ message?: string }> = ({ message = 'Loading' }) => {
  return (
    <Box sx={{ pt: 18 }}>
      <Card sx={{ width: 300 }}>
        <Box sx={{ height: 80, position: 'relative' }}>
          <motion.div
            initial={{ y: -90 }}
            animate={{ y: -120 }}
            transition={{ duration: 1, repeat: Infinity, repeatType: 'reverse' }}
          >
            <Flex variant="layout.flexCenterCenter">
              <Image src="/assets/images/loading.png" />
            </Flex>
          </motion.div>
        </Box>
        <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Heading>{message}</Heading>
          <Text>Please wait patiently</Text>
        </Box>
      </Card>
    </Box>
  )
}

export default LoadingCard
