import ProgressBar from 'components/progress-bar'
import { FC } from 'react'
import { Card, Flex, Text, Box, Image } from 'theme-ui'

const EventCard: FC<{
  title: string
  subtitle: string
  imageUrl: string
  disabled?: boolean
  available?: number
  total?: number
}> = ({ title, subtitle, imageUrl, disabled = false, available = 108, total = 108 }) => (
  <Card variant="secondary" padding={0}>
    <Flex
      variant="layout.flexCenterSpaceBetween"
      sx={{ flexDirection: ['column-reverse', null, 'row'], width: '100%' }}
    >
      <Flex
        px={8}
        pt={[0, 0, 4]}
        pb={[8, 0, 4]}
        variant="layout.vStack"
        sx={{ height: '100%', justifyContent: 'space-evenly', width: ['100%', '100%', 'auto'] }}
      >
        <Box>
          <Text as="p" color="#FF147F" variant="mediumTitle">
            Event Started
          </Text>
        </Box>
        <Box>
          <Text as="p" color="white" variant="largeTitle2">
            {title}
          </Text>
        </Box>
        <Box>
          {available > 0 ? (
            <Text as="p" color="white">
              Total Available : <Text color="#FF147F">{available}</Text>
              <Text> / {total}</Text>
            </Text>
          ) : (
            <Text color="#FF147F">{'SOLD OUT'}</Text>
          )}
        </Box>
        <Box pt={2} />
        <ProgressBar value={available / total} />
      </Flex>
      <Image src={imageUrl} style={{ maxWidth: '240px' }} />
    </Flex>
  </Card>
)

export default EventCard
