import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

const initialValue = {
  language: 'en'
}

const storageName = 'locale'
let storedValue = { ...initialValue }
if (window.localStorage) {
  let result = JSON.parse(localStorage.getItem(storageName) ?? '{}')

  if (!_.isEmpty(result)) {
    storedValue = result
  }
}

export const localeStore = proxy({
  state: storedValue,
  reset() {
    localeStore.state = { ...initialValue }
    localStorage.setItem(storageName, JSON.stringify(initialValue))
  }
})

subscribe(localeStore, () => {
  localStorage.setItem(storageName, JSON.stringify(localeStore.state))
})
