import { FC } from 'react'
import { Button, Card, Flex, Text, Box } from 'theme-ui'

const BalanceInfoCard: FC<{
  title: string
  subtitle: string
  icon: JSX.Element
}> = ({ title, subtitle, icon }) => (
  <Card variant="secondary">
    <Flex variant="layout.flexCenterSpaceBetween">
      <Flex variant="layout.vStack">
        <Text as="p" color="textMuted">
          {title}
        </Text>
        <Box>
          <Text as="p" variant="large">
            {subtitle}
          </Text>
        </Box>
      </Flex>
      <Button variant="round" sx={{ width: 45, height: 45 }}>
        {icon}
      </Button>
    </Flex>
  </Card>
)

export default BalanceInfoCard
