import { FC } from 'react'
import { Button, Box, Text, Flex, Image } from 'theme-ui'
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate, useLocation } from 'react-router-dom'

import { useSideBarDisplay } from 'context/sideBarDisplay'

const SidebarButton: FC<{
  //   colors: String
  icon: string
  text: string
  subText?: string
  link?: string
  newTab?: boolean
}> = ({ text, icon, link = '', subText, newTab = false }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setSideBarDisplay } = useSideBarDisplay()

  return (
    <Box>
      {location.pathname.includes(link) ? (
        <Box variant="buttons.primary" sx={{ width: '100%', textAlign: 'left', py: 3, pl: 3 }}>
          <Flex variant="layout.flexCenterSpaceBetween">
            <Flex variant="layout.flexCenterStart">
              <Box variant="layout.iconContainer" sx={{ mr: 4 }}>
                <Image src={icon} />
              </Box>
              <Box>
                <Text>{text}</Text>
                <Box>
                  {subText && (
                    <Text variant="small" sx={{ color: 'textMuted' }}>
                      {subText}
                    </Text>
                  )}
                </Box>
              </Box>
            </Flex>
            <BsArrowRight />
          </Flex>
        </Box>
      ) : (
        <Button
          variant="sidebarButton"
          sx={{ textAlign: 'left', py: 3, pl: 3 }}
          onClick={() => {
            newTab ? window.open(link, '_blank') : navigate(link)
            setSideBarDisplay(false)
          }}
        >
          <Flex variant="layout.flexCenterSpaceBetween">
            <Flex variant="layout.flexCenterStart">
              <Box variant="layout.iconContainer" sx={{ mr: 4 }}>
                <Image src={icon} />
              </Box>
              <Box>
                <Text>{text}</Text>
                <Box>
                  <Text variant="small" sx={{ color: 'textMuted' }}></Text>
                  <Box>
                    {subText && (
                      <Text variant="small" sx={{ color: 'textMuted' }}>
                        {subText}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Flex>
            <BsArrowRight />
          </Flex>
        </Button>
      )}
    </Box>
  )
}

export default SidebarButton
