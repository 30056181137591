/** @jsxImportSource theme-ui */
import { Box, Button, Card, Flex, Image } from 'theme-ui'
import MyNFtsPage from './my-nfts'
import NFTDeposit from './nft-deposit'
import NFTShop from './nft-shop'
import { useState, useEffect } from 'react'
import NFTShowcase from './nfts-showcase'
import { useResolution } from 'hooks/useResolution'
import { useSearchParams } from 'react-router-dom'

const NFTsPage = () => {
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const { isMobile } = useResolution()
  const [currentTab, setCurrentTab] = useState<number>(1)
  const pages = [<MyNFtsPage />, <NFTShop />, <NFTDeposit />, <NFTShowcase />]

  useEffect(() => {
    if (!tab) {
      return
    }

    setCurrentTab(tab === 'shop' ? 1 : 0)
  }, [tab])

  return (
    <>
      <Card
        sx={{
          background: 'sidebarBG',
          borderRadius: 0,
          width: ['100%', null, 300],
          position: ['inherit', null, 'fixed'],
          left: 0,
          top: 100,
          borderTopRightRadius: [0, null, 30],
          borderBottomRightRadius: [0, null, 30],
          height: ['100%', null, 'auto'],
          py: [3, null, 10],
          px: [0, null, 'auto']
        }}
      >
        <Box
          sx={{
            background: ['sidebarButtonBG', null, 'transparent'],
            px: [0, null, 4],
            borderRadius: '30px'
          }}
        >
          <Box
            sx={{
              display: ['flex', null, 'block'],
              overflowY: ['scroll', null, 'auto'],
              '::-webkit-scrollbar': {
                display: ['auto', 'none']
              }
            }}
          >
            <NFTButtonSideButton
              currentTab={currentTab}
              index={1}
              onClick={() => setCurrentTab(1)}
              title="Shop"
              imageSrc="/assets/svg/shop.svg"
            />
            <Box pt={8} />
            <NFTButtonSideButton
              currentTab={currentTab}
              index={0}
              onClick={() => setCurrentTab(0)}
              title="My NFTs"
              imageSrc="/assets/svg/blockchain.svg"
            />
            <Box pt={2} />
            {/* <NFTButtonSideButton
              currentTab={currentTab}
              index={2}
              onClick={() => setCurrentTab(2)}
              title="Deposit"
              imageSrc="/assets/svg/deposit.svg"
            /> */}
            {/* <Box p={1} />
              <NFTButtonSideButton
                currentTab={currentTab}
                index={3}
                onClick={() => setCurrentTab(3)}
                title="NFT Showcase"
                imageSrc="/assets/svg/nft-showcase.svg"
              /> */}
          </Box>
        </Box>
      </Card>

      <Box variant="layout.pageContainer">
        <Box variant="layout.pageSideContainer">
          {!isMobile && (
            <Box mt={[4, null, 0]} ml={[0, null, 300]}>
              {pages[currentTab]}
            </Box>
          )}
          {isMobile && (
            <>
              <Box p={5} />
              <Box>{pages[currentTab]}</Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

const NFTButtonSideButton = ({
  imageSrc,
  title,
  onClick,
  index,
  currentTab
}: {
  imageSrc: string
  title: string
  onClick: () => any
  index: number
  currentTab: number
}) => {
  const { isMobile } = useResolution()
  return (
    <Button
      variant={currentTab === index ? 'primary' : 'sidebarButton'}
      sx={{
        py: [1, 3],
        px: 0,
        width: [200, '100%'],
        minWidth: [200, 'auto'],
        background: isMobile && currentTab !== index ? 'none' : 'auto'
      }}
      onClick={onClick}
    >
      <Flex variant="layout.flexCenterStart" sx={{ position: 'relative' }}>
        {(!isMobile || (isMobile && currentTab === index)) && (
          <Box ml={4} variant="layout.iconContainer">
            <Image src={imageSrc} />
          </Box>
        )}
        <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>{title}</Box>
      </Flex>
    </Button>
  )
}

export default NFTsPage
