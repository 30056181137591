/** @jsxImportSource theme-ui */
import MobileReturnHeader from 'components/MobileReturnHeader'
import { useResolution } from 'hooks/useResolution'
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, Flex, Grid, Heading, Image, Text } from 'theme-ui'

const SecurityPage = () => {
  const { isMobile } = useResolution()
  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Security" toSidebar />}
      {!isMobile && <Heading variant="largeTitle">Security</Heading>}
      <Box p={5} />
      <Grid sx={{ width: '100%', '>*': { height: [300, 350] } }} columns={[1, 3]} gap={5}>
        {/* <LoginInformation /> */}

        {/* <TwoFactorAuthentication /> */}

        <LastLogins />
      </Grid>
    </Box>
  )
}

const LoginInformation = () => {
  const navigate = useNavigate()
  return (
    <Card variant="flexCenterSecondaryCard">
      <Box
        sx={{
          width: '90%',
          height: '100%'
        }}
      >
        <Flex variant="layout.flexCenterCenter" sx={{ p: 1, height: '30%' }}>
          <Image src="/assets/svg/user_login.svg" width={60} height={60} />
        </Flex>
        <Flex variant="layout.flexCenterCenter" sx={{ height: '20%', textAlign: 'center' }}>
          <Text variant="large" color="primary">
            Login Information
          </Text>
        </Flex>
        <Flex variant="layout.flexStartCenter" sx={{ height: '20%', textAlign: 'center' }}>
          <Text color="secondaryText">Change your password, see your account e-mail details.</Text>
        </Flex>
        <Flex variant="layout.flexCenterCenter" sx={{ height: '30%', textAlign: 'center' }}>
          <Button variant="primaryFlexCenter" onClick={() => navigate('login-information')}>
            <Text sx={{ mr: 2 }}>Go</Text>
            <BsArrowRight />
          </Button>
        </Flex>
      </Box>
    </Card>
  )
}

const TwoFactorAuthentication = () => {
  const navigate = useNavigate()
  return (
    <Card variant="flexCenterSecondaryCard">
      <Box
        sx={{
          width: '90%',
          height: '100%'
        }}
      >
        <Flex variant="layout.flexCenterCenter" sx={{ p: 1, height: '30%' }}>
          <Image src="/assets/svg/surface1.svg" width={50} height={50} />
        </Flex>
        <Flex variant="layout.flexCenterCenter" sx={{ height: '20%', textAlign: 'center' }}>
          <Text variant="large" color="primary">
            Two-Factor Authentication
          </Text>
        </Flex>
        <Flex variant="layout.flexStartCenter" sx={{ height: '20%', textAlign: 'center' }}>
          <Text color="secondaryText">Used for withdrawals and changes to security settings.</Text>
        </Flex>
        <Flex variant="layout.flexCenterCenter" sx={{ height: '30%', textAlign: 'center' }}>
          <Button variant="primaryFlexCenter" onClick={() => navigate('2-factor-authentication')}>
            <Text sx={{ mr: 2 }}>Go</Text>
            <BsArrowRight />
          </Button>
        </Flex>
      </Box>
    </Card>
  )
}

const LastLogins = () => {
  const navigate = useNavigate()
  return (
    <Card variant="flexCenterSecondaryCard">
      <Box
        sx={{
          width: '90%',
          height: '100%'
        }}
      >
        <Flex variant="layout.flexCenterCenter" sx={{ p: 1, height: '30%' }}>
          <Image src="/assets/svg/clipboard.svg" width={50} height={50} />
        </Flex>
        <Flex variant="layout.flexCenterCenter" sx={{ height: '20%', textAlign: 'center' }}>
          <Text variant="large" color="primary">
            Last 10 Logins
          </Text>
        </Flex>
        <Flex variant="layout.flexStartCenter" sx={{ height: '20%', textAlign: 'center' }}>
          <Text color="secondaryText">Monitor the logins to your Pbc account.</Text>
        </Flex>
        <Flex variant="layout.flexCenterCenter" sx={{ height: '30%', textAlign: 'center' }}>
          <Button variant="primaryFlexCenter" onClick={() => navigate('last-login')}>
            <Text sx={{ mr: 2 }}>Go</Text>
            <BsArrowRight />
          </Button>
        </Flex>
      </Box>
    </Card>
  )
}

export default SecurityPage
