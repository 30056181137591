/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { BiMenuAltRight } from 'react-icons/bi'
import { HiSpeakerphone } from 'react-icons/hi'
import {
  AspectRatio,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Text
} from 'theme-ui'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { useResponsiveValue } from '@theme-ui/match-media'
import useWebSocket from 'react-use-websocket'

import { useResolution } from 'hooks/useResolution'
import { IDataResponse, serialize } from 'network/request'
import PoolService, { Pool } from 'network/services/pool'
import { Wallet } from 'network/services/wallet'
import {
  AmplifyCard,
  BalanceInfoCard,
  LaunchpoolCard,
  MarketCard,
  NewsCardLarge,
  NewsCardSmall,
  ResponsivePoolCardContainer
} from 'components/Home'
import useSWR from 'swr'
import TokenIcon from 'components/icons/token'
import CommonIcon from 'components/icons/common'
import commaNumber from 'modules/comma-number'
import Link from 'components/link'
import { BinanceSocketStream, currencyStore, findUsd } from 'store/currency'
import { useSnapshot } from 'valtio'
import AuthService from 'network/services/auth'
import AnnouncementService, { Announcement } from 'network/services/announcement'
import NewsService, { News } from 'network/services/news'
import LoadingCard from 'components/loading'
import ErrorCard from 'components/error'
import NFTService, { NFT } from 'network/services/nft'
import NFTPreviewCard from 'components/Stake/nft-preview-card'
import InfoActionCard from 'components/Home/info-action-card'
import EventCard from 'components/Home/event-card'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  return (
    <Grid gap={12} sx={{ width: '100%' }}>
      {/* <ReferAFriend /> */}
      {/* <Markets /> */}
      {/* <LaunchPools /> */}
      <Grid columns={['1fr', '1fr', '3fr 1fr']} gap={8}>
        <Flex variant="layout.vStack">
          <EventView />
          <Box pt={8} />
          <NftPreview />
        </Flex>
        <VideoView />
      </Grid>
      <PortfolioWrapper />
      <Banner />
      <DiscoverNow />
      <ExploreBlindBox />
      {/* <VXT /> */}
      {/* <NewsView /> */}
    </Grid>
  )
}

const Banner = () => {
  const { isMobile } = useResolution()
  return (
    <Box variant="layout.pageContainer">
      {isMobile ? (
        // <Box
        //   sx={{
        //     backgroundImage: 'url(/assets/images/home-page-banner-mobile@2x.png)',
        //     backgroundSize: 'cover',
        //     backgroundRepeat: 'no-repeat',
        //     backgroundPosition: 'center center',
        //     width: '100%',
        //     height: 200,
        //     borderRadius: 20,
        //     p: 8,
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center'
        //   }}
        // >
        //   <Image src="/assets/images/home-page-banner@2x-1.png" />
        // </Box>
        <Image src="/assets/images/home-page-banner@2x-1.png" width="100%" />
      ) : (
        <Image src="/assets/images/home-page-banner@2x-1.png" width="100%" />
      )}
    </Box>
  )
}

const PortfolioWrapper = () => {
  return (
    <Box>
      <Announcements />
      <PortfolioBalance />
    </Box>
  )
}

const Announcements = () => {
  const size = useResponsiveValue(['1em', '1.5em'])
  const navigate = useNavigate()
  const { data, error } = useSWR<IDataResponse<Announcement>>(
    serialize(AnnouncementService.getAnnouncements, {
      sort: 'published_at:desc',
      page: 1,
      limit: 1
    })
  )

  // ToDo : add error and loading message
  if (error) {
    return <></>
  }

  if (!data) {
    return <></>
  }

  const announcements = AnnouncementService.toRow(data)

  if (_.isEmpty(announcements)) {
    return <></>
  }

  return (
    <Box variant="layout.pageContainer">
      <Flex
        sx={{ height: 'auto', alignItems: 'center', mb: 3 }}
        variant="layout.flexCenterSpaceBetween"
      >
        <Flex variant="layout.hStack">
          <Box sx={{ color: 'primary' }}>
            <HiSpeakerphone display="block" color="primary" size={size} />
          </Box>
          <Box>
            <Text variant="mediumSmall">{_.first(announcements)?.title ?? ''}</Text>
          </Box>
        </Flex>

        <Flex sx={{ justifyContent: 'right' }}>
          <IconButton
            onClick={() => {
              navigate('/announcements')
            }}
          >
            <BiMenuAltRight size="2em" />
          </IconButton>
        </Flex>
      </Flex>
    </Box>
  )
}

const PortfolioBalance = () => {
  const { t, i18n } = useTranslation('home')
  const { data, error, mutate } = useSWR<{
    portfolio_balance: {
      token_type: string
      balance: number
    }[]
    wallets: Wallet[]
    interest_balance: {
      token_type: string
      balance: number
    }[]
    linked_nft: string
    owned_nft: string
  }>(serialize(AuthService.findBalance))

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message={t('Unable to retrieve portfolio balance') ?? ''}
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  // const portfolioBalance = data.portfolio_balance?.reduce((prev, next) => {
  //   return prev + findUsd(next.balance, next.token_type)
  // }, 0)

  const availableBalance = data.wallets?.reduce((prev, next) => {
    return prev + findUsd(next.balance, next.token_type)
  }, 0)

  // const interestBalance = data.interest_balance?.reduce((prev, next) => {
  //   return prev + findUsd(next.balance, next.token_type)
  // }, 0)

  return (
    <Box variant="layout.pageContainer">
      <Box>
        <Grid columns={[1, 2, 2]} gap={6}>
          {/* <BalanceInfoCard
            title="Portfolio Balance (USD)"
            subtitle={`$${commaNumber(portfolioBalance.toFixed(2))}`}
            icon={<CommonIcon type="bar" />}
          /> */}
          <BalanceInfoCard
            title={t('Wallet Balance (USD)') ?? ''}
            subtitle={`$${commaNumber(availableBalance.toFixed(2))}`}
            icon={<CommonIcon type="money_bag" />}
          />
          {/* <BalanceInfoCard
            title="Rewards Earned (USD)"
            subtitle={`$${commaNumber(interestBalance.toFixed(2))}`}
            icon={<CommonIcon type="medal" />}
          /> */}
          <BalanceInfoCard
            title={t('Total NFT') ?? ''}
            subtitle={data.owned_nft ?? '0'}
            icon={<CommonIcon type="nft" />}
          />
          {/* <BalanceInfoCard
            title="Owned NFT"
            subtitle={data.owned_nft ?? '0'}
            icon={<CommonIcon type="nft" />}
          /> */}

          {/* {!_.isEmpty(wallets) && (
            <BalanceInfoCard
              title="Portfolio Balance (USD)"
              subtitle={`$${commaNumber(wallets[0].balance)}`}
              icon={<FaBitcoin size={20} sx={{ display: 'block' }} />}
            />
          )} */}
        </Grid>
      </Box>
    </Box>
  )
}

const ReferAFriend = () => {
  const navigate = useNavigate()
  const { isMobile } = useResolution()

  return isMobile ? (
    <Box variant="layout.pageContainer">
      <Box p={1} />
      <Button sx={{ p: 0, py: 0, borderRadius: 10 }}>
        {/* <Button sx={{ p: 0, py: 0, borderRadius: 10 }} onClick={() => navigate('/gift-rewards')}> */}
        <Card
          variant="flexCenterCard"
          sx={{
            background: 'darkBG',
            px: 3,
            pb: 10,
            borderRadius: 10,
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Box sx={{ height: 200, position: 'relative' }}>
              <Flex
                variant="layout.flexCenterCenter"
                sx={{
                  position: 'absolute',
                  top: -10
                }}
              >
                <Image src="/assets/images/invite@2x.png" width={240} />
              </Flex>
            </Box>
            <Box sx={{ color: 'text' }}>
              <Heading variant="styles.h5">Invite friends and earn PBC</Heading>
              <Box sx={{ mt: 3, whiteSpace: 'normal' }}>
                <Text variant="mediumSmall">
                  For every friend who stakes $10,000 or more, we'll reward both of you with $25
                  worth of PBC
                </Text>
              </Box>
            </Box>
          </Box>
        </Card>

        <Flex variant="layout.hStack" sx={{ justifyContent: 'center', py: 4 }}>
          <Text>Go to the Refer a Friend page</Text>
          <BsArrowRight size="1.5em" />
        </Flex>
      </Button>
    </Box>
  ) : (
    // <Button sx={{ p: 0, py: 0 }} onClick={() => navigate('/gift-rewards')}>
    <Button sx={{ p: 0, py: 0 }}>
      <Card
        variant="flexCenterCard"
        sx={{
          background: 'darkBG',
          px: 10,
          py: 0,
          borderRadius: 20,
          overflow: 'hidden',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ textAlign: 'left' }}>
          <Box sx={{ width: '70%', color: 'text' }}>
            <Heading>Invite friends and earn PBC</Heading>
            <Box sx={{ mt: 5 }}>
              <Text>
                For every friend who stakes $10,000 or more, we'll reward both of you with $25 worth
                of PBC
              </Text>
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: 200 }}>
          <motion.img src="/assets/images/invite@2x.png" width={240} initial={{ y: -20 }} />
        </Box>
      </Card>

      <Flex variant="layout.hStack" sx={{ justifyContent: 'center', py: 4 }}>
        <Text>Go to the Refer a Friend page</Text>
        <BsArrowRight size="1.5em" />
      </Flex>
    </Button>
  )
}

const Markets = () => {
  const [socketUrl] = useState(
    'wss://stream.binance.com:9443/stream?streams=btcusdt@ticker/ethusdt@ticker/bnbusdt@ticker'
  )
  const { lastMessage } = useWebSocket(socketUrl)
  const { btc, eth, bnb } = useSnapshot(currencyStore)

  useEffect(() => {
    if (lastMessage !== null) {
      let obj = JSON.parse(lastMessage.data) as BinanceSocketStream

      if (obj.stream === 'btcusdt@ticker') {
        currencyStore.btc = obj.data
      }
      if (obj.stream === 'ethusdt@ticker') {
        currencyStore.eth = obj.data
      }
      if (obj.stream === 'bnbusdt@ticker') {
        currencyStore.bnb = obj.data
      }
    }
  }, [lastMessage])

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: 'Connecting',
  //   [ReadyState.OPEN]: 'Open',
  //   [ReadyState.CLOSING]: 'Closing',
  //   [ReadyState.CLOSED]: 'Closed',
  //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  // }[readyState]

  return (
    <Box>
      <Box variant="layout.pageContainer">
        <Flex sx={{ height: 'auto' }} variant="layout.flexCenterSpaceBetween">
          <Text variant="large" sx={{ flex: 1 }}>
            Markets
          </Text>
          <Link to="/markets" variant="unstyled">
            <Flex variant="layout.hStack">
              <Text color="white">See All</Text>
              <Box>
                <IconButton>
                  <BsArrowRight size="1.5em" />
                </IconButton>
              </Box>
            </Flex>
          </Link>
        </Flex>
      </Box>
      <Box p={1} />
      <Box>
        <ResponsivePoolCardContainer>
          <Grid columns={[3, 2, 3]} gap={6}>
            {btc && (
              <MarketCard
                currency={btc.s}
                icon={<TokenIcon type="btc" />}
                price={btc.c ?? ''}
                change={btc.P ?? ''}
              />
            )}
            {eth && (
              <MarketCard
                currency={eth.s}
                icon={<TokenIcon type="eth" />}
                price={eth.c ?? ''}
                change={eth.P ?? ''}
              />
            )}
            {bnb && (
              <MarketCard
                currency={bnb.s}
                icon={<TokenIcon type="bnb" />}
                price={bnb.c ?? ''}
                change={bnb.P ?? ''}
              />
            )}
          </Grid>
        </ResponsivePoolCardContainer>
      </Box>
    </Box>
  )
}

const LaunchPools = () => {
  const { data, error, mutate } = useSWR<IDataResponse<Pool>>(
    serialize(PoolService.getAll, { page: 1, limit: 3, type: 'launch' })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve launchpools information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const pools = PoolService.toRow(data).filter((item) => item.type === 'launch')

  if (_.isEmpty(pools)) {
    return <></>
  }

  return (
    <Box>
      <Box variant="layout.pageContainer">
        <Flex sx={{ height: 'auto' }} variant="layout.flexCenterSpaceBetween">
          <Text variant="large" sx={{ flex: 1 }}>
            Launchpools
          </Text>
          {pools.length > 3 && (
            <Link to="/stake/pools?tab=launchpool" variant="unstyled">
              <Flex variant="layout.hStack">
                <Text color="white">See All</Text>
                <Box>
                  <IconButton>
                    <BsArrowRight size="1.5em" />
                  </IconButton>
                </Box>
              </Flex>
            </Link>
          )}
        </Flex>
      </Box>
      <Box p={1} />
      <ResponsivePoolCardContainer>
        {/* TODO: need to add horizontal scroll to desktop as well */}
        <Grid columns={[3, 2, 3]} gap={6}>
          {pools.map((pool) => {
            return <LaunchpoolCard key={pool.id} pool={pool} />
          })}
        </Grid>
      </ResponsivePoolCardContainer>
    </Box>
  )
}

const Amplify = () => {
  const { data, error, mutate } = useSWR<IDataResponse<Pool>>(
    serialize(PoolService.getAll, { page: 1, limit: 3, type: 'amplify' })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message="Unable to retrieve amplify pools information"
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const pools = PoolService.toRow(data).filter((item) => item.type === 'amplify')

  if (_.isEmpty(pools)) {
    return <></>
  }

  return (
    <Box>
      <Box variant="layout.pageContainer">
        <Flex sx={{ height: 'auto' }} variant="layout.flexCenterSpaceBetween">
          <Text variant="large" sx={{ flex: 1 }}>
            Amplify
          </Text>
          {pools.length > 3 && (
            <Link to="/stake/pools?tab=amplify" variant="unstyled">
              <Flex variant="layout.hStack">
                <Text color="white">See All</Text>
                <Box>
                  <IconButton>
                    <BsArrowRight size="1.5em" />
                  </IconButton>
                </Box>
              </Flex>
            </Link>
          )}
        </Flex>
      </Box>
      <Box p={1} />
      <ResponsivePoolCardContainer>
        {/* TODO: need to add horizontal scroll to desktop as well */}
        <Grid columns={[3, 2, 3]} gap={6}>
          {pools.map((pool) => (
            <AmplifyCard key={pool.id} pool={pool} />
          ))}
        </Grid>
      </ResponsivePoolCardContainer>
    </Box>
  )
}

const VXT = () => {
  const { isMobile } = useResolution()
  const navigate = useNavigate()
  const { data, error, mutate } = useSWR<IDataResponse<Pool>>(
    serialize(PoolService.getAll, { page: 1, limit: 1, type: 'vxt' })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve VXT information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const pool = data?.data[0]

  return (
    <Box variant="layout.pageContainer">
      <Box>
        <Text variant="large">VXT Pool</Text>
      </Box>
      <Box p={1} />
      {!isMobile ? (
        <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: 'auto' }}>
          <Image src={'/assets/svg/user-logo.svg'} />

          <Box>
            <Box>
              <Text variant="mediumSmall">VXT Pool</Text>
            </Box>
            <Flex variant="layout.flexCenterStart">
              <Text sx={{ mr: 5 }}>VXT</Text>
              <Text variant="small" color="textMuted">
                Pbc
              </Text>
            </Flex>
          </Box>

          <Box>
            <Box>
              <Text>Current Pool Size</Text>
            </Box>
            <Box>
              <Text color="textMuted">{pool.pool_size} VXT</Text>
            </Box>
          </Box>

          <Box>
            <Box>
              <Text>
                Average Pool APY{' '}
                <Text variant="small" color="textMuted">
                  (last 3 mths)
                </Text>
              </Text>
            </Box>
            <Box>
              {pool.apy > 0 ? (
                <Text color="success">{pool.apy}%</Text>
              ) : (
                <Text color="success">No Sufficient Data Yet</Text>
              )}
            </Box>
          </Box>

          <Button onClick={() => navigate('/stake/pools?tab=vxt')}>Stake</Button>
        </Flex>
      ) : (
        <Box>
          <Flex variant="layout.hStack">
            <Image src={'/assets/svg/user-logo.svg'} sx={{ mr: 5 }} width={50} />
            <Box>
              <Box>
                <Text>VXT Pool</Text>
              </Box>
              <Flex variant="layout.flexCenterStart">
                <Text variant="large" sx={{ mr: 5 }}>
                  VXT
                </Text>
                <Text variant="small" color="textMuted">
                  Pbc
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Box p={2} />
          <Grid columns={2} gap={3}>
            <Box>
              <Text variant="large">Current</Text>
              <br />
              <Text variant="large">Pool Size</Text>
            </Box>

            <Box>
              <Text variant="mediumSmall">Average Pool APY</Text>
              <br />
              <Text color="textMuted">(last 3 mths)</Text>
            </Box>

            <Box>
              <Text color="textMuted">{pool.pool_size} VXT</Text>
            </Box>

            <Box>
              <Text color="success">{pool.apy ?? '-'}%</Text>
            </Box>
          </Grid>
          <Box p={2} />
          <Button sx={{ width: '100%' }} onClick={() => navigate('/stake/pools?tab=vxt')}>
            Stake
          </Button>
        </Box>
      )}
    </Box>
  )
}

const VideoView = () => {
  return (
    <Box variant="layout.pageContainer">
      <AspectRatio ratio={480 / 848}>
        <video
          style={{ width: '100%' }}
          src="https://pbc-nft.oss-cn-hongkong.aliyuncs.com/nfts/pbc_video.mp4"
          muted
          autoPlay
          playsInline
          loop
        >
          Sorry, your browser doesn't support HTML5 video tag.
        </video>
      </AspectRatio>
    </Box>
  )
}

const EventView = () => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getAll, {
      sort: 'id:asc',
      page: 1,
      limit: 1,
      status: 'active'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message="Unable to retrieve amplify pools information"
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  return (
    <Box variant="layout.pageContainer">
      <Box>
        <Grid columns={[1, 1, 1]} gap={6}>
          <EventCard
            title={'108 CP CLAN NFT'}
            subtitle={'DISCOVER NOW'}
            imageUrl="/animated.gif"
            available={data?.meta?.total ?? 0}
            total={108}
          />
        </Grid>
      </Box>
    </Box>
  )
}

const DiscoverNow = () => {
  return (
    <Box variant="layout.pageContainer">
      <Box>
        <Grid columns={[1, 2, 2]} gap={6}>
          <InfoActionCard
            title={'108 CP CLAN NFT'}
            subtitle={'DISCOVER NOW'}
            imageUrl="PBC_00055@2x.png"
            navigateUrl="/nfts"
          />
          <InfoActionCard
            title={'MINED YOUR NFT'}
            subtitle={'COMING SOON'}
            imageUrl="blindbox-11png@2x.png"
            disabled={true}
          />
        </Grid>
      </Box>
    </Box>
  )
}

const NewsView = () => {
  const { isMobile } = useResolution()
  const { data, error, mutate } = useSWR<IDataResponse<News>>(
    serialize(NewsService.getNewsList, {
      sort: 'published_at:desc',
      page: 1,
      limit: 10
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve news" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  const newsList = NewsService.toRow(data)

  return (
    <Box variant="layout.pageContainer">
      {newsList.length > 0 && (
        <>
          <Flex sx={{ height: 'auto' }} variant="layout.flexCenterSpaceBetween">
            <Text variant="large" sx={{ flex: 1 }}>
              News
            </Text>
            {newsList.length > 3 && (
              <Link to="/news" variant="unstyled">
                <Flex variant="layout.hStack">
                  <Text color="white">See All</Text>
                  <Box>
                    <IconButton>
                      <BsArrowRight size="1.5em" />
                    </IconButton>
                  </Box>
                </Flex>
              </Link>
            )}
          </Flex>
          <Box p={1} />
          {!isMobile && (
            <Box>
              <Grid columns={[2, 2, 3]} gap={6}>
                {_.take(newsList, 3).map((news, index) => (
                  <NewsCardLarge
                    key={index}
                    imageSrc={news.image_url}
                    title={news.title}
                    source="Pbc"
                    // source={news.url ?? ''}
                    date={news.published_at}
                  />
                ))}
              </Grid>
            </Box>
          )}
          <Box>
            <Grid columns={[1, 1, 2]} gap={[2, 1]}>
              {_.drop(newsList, 3).map((news, index) => (
                <NewsCardSmall
                  key={index}
                  imageSrc={news.image_url}
                  title={news.title}
                  source="Pbc"
                  // source={news.url ?? ''}
                  date={news.published_at}
                />
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  )
}

const NftPreview = () => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getAll, {
      sort: 'id:asc',
      page: 1,
      limit: 4
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message="Unable to retrieve amplify pools information"
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nfts = NFTService.toRow(data)
  // console.log(nfts)
  if (_.isEmpty(nfts)) {
    return <></>
  }

  return (
    <Box>
      <Box variant="layout.pageContainer">
        <Flex sx={{ height: 'auto' }} variant="layout.flexCenterSpaceBetween">
          <Text variant="large" sx={{ flex: 1 }}>
            NFT Preview
          </Text>
          {(data.meta?.total ?? 0) > 4 && (
            <Link to="/home/nft-preview" variant="unstyled">
              <Flex variant="layout.hStack">
                <Text color="white">See All</Text>
                <Box>
                  <IconButton>
                    <BsArrowRight size="1.5em" />
                  </IconButton>
                </Box>
              </Flex>
            </Link>
          )}
        </Flex>
      </Box>
      <Box p={1} />
      <ResponsivePoolCardContainer>
        <Grid columns={[4, 2, 4]} gap={6}>
          {nfts.map((nft) => {
            return (
              <NFTPreviewCard key={nft.id} NFT={nft} isBlind={nft.type === 'nft' ? false : true} />
            )
          })}
        </Grid>
      </ResponsivePoolCardContainer>
    </Box>
  )
}

const ExploreBlindBox = () => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getAll, {
      sort: 'id:asc',
      page: 1,
      limit: 4
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message="Unable to retrieve amplify pools information"
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nfts = NFTService.toRow(data)
  // console.log(nfts)
  if (_.isEmpty(nfts)) {
    return <></>
  }

  return (
    <Box>
      <Box variant="layout.pageContainer">
        <Flex sx={{ height: 'auto' }} variant="layout.flexCenterSpaceBetween">
          <Text variant="large" sx={{ flex: 1 }}>
            Explore our Blind Box
          </Text>
          {nfts.length > 4 && (
            <Link to="/stake/pools?tab=amplify" variant="unstyled">
              <Flex variant="layout.hStack">
                <Text color="white">See All</Text>
                <Box>
                  <IconButton>
                    <BsArrowRight size="1.5em" />
                  </IconButton>
                </Box>
              </Flex>
            </Link>
          )}
        </Flex>
      </Box>
      <Box pt={4} />
      <ResponsivePoolCardContainer>
        <Grid columns={[4, 2, 4]} gap={6}>
          {nfts.map((nft) => {
            return (
              <NFTPreviewCard key={nft.id} NFT={nft} isBlind={nft.type === 'nft' ? false : true} />
            )
          })}
        </Grid>
      </ResponsivePoolCardContainer>
    </Box>
  )
}
export default HomePage
