/** @jsxImportSource theme-ui */
import FormInput from 'components/form-input'
import MobileReturnHeader from 'components/MobileReturnHeader'
import TitleWithBack from 'components/TitleWithBack'
import { useResolution } from 'hooks/useResolution'
import AuthService, { ResetPassword } from 'network/services/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BsArrowRight } from 'react-icons/bs'
import { Box, Button, Card, Flex, Grid, IconButton, Text } from 'theme-ui'
import StatusModal from 'components/status-modal'

const LoginInformationPage = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const { isMobile } = useResolution()
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<ResetPassword>({
    shouldUseNativeValidation: false
  })

  let state: any = location.state
  let from = state?.from?.pathname || '/home'

  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const onModalClose = () => {
    setMessage({ isOpen: false })
    navigate(from, { replace: true })
  }

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { data: result } = await AuthService.changePassword(data)

      if (result.success) {
        setMessage({
          isOpen: true,
          success: true,
          message: 'Password updated successfully'
        })
      }
    } catch (e: any) {
      setError('password', {
        type: 'manual',
        message: 'Invalid email or password'
      })
    }
  })

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Security" delta={-1} />}
      <TitleWithBack title="Login Information" backRef="/security" />
      <Box p={2} />
      <Card
        sx={{
          width: ['100%', '80%', '70%', '60%', '50%'],
          p: [6, 10],
          background: 'darkBG'
        }}
      >
        <Box as="form" onSubmit={onSubmit} sx={{ '& input': { background: 'sidebarButtonBG' } }}>
          <Grid gap={8}>
            <Box>
              <Text variant="extraLarge">Change Password</Text>
            </Box>

            <Box>
              <FormInput<ResetPassword>
                id="password"
                type={showCurrentPassword ? 'text' : 'password'}
                name="password"
                label="Current Password"
                register={register}
                rules={{ required: 'You must enter your current password.' }}
                errors={errors}
                trailing={
                  <IconButton
                    px={4}
                    type="button"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  >
                    {showCurrentPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                }
              />
            </Box>

            <Box>
              <FormInput<ResetPassword>
                id="new_password"
                type={showNewPassword ? 'text' : 'password'}
                name="new_password"
                label="New Password"
                register={register}
                rules={{
                  required: 'You must enter your new password.',
                  pattern: {
                    value:
                      /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/,
                    message:
                      'The password must be at least 8 characters including at least a symbol, an uppercase letter and a number'
                  }
                }}
                errors={errors}
                trailing={
                  <IconButton
                    px={4}
                    type="button"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                }
              />
            </Box>

            <Box>
              <FormInput<ResetPassword>
                id="new_password-confirmation"
                type={showConfirmPassword ? 'text' : 'password'}
                name="new_password_confirmation"
                label="Confirm New Password"
                register={register}
                rules={{ required: 'You must confirm your new password.' }}
                errors={errors}
                trailing={
                  <IconButton
                    px={4}
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                }
              />
            </Box>

            <Flex variant="layout.flexCenterEnd">
              <Button type="submit" variant="primaryFlexCenter" onClick={() => clearErrors()}>
                <Text sx={{ mr: 3 }}>Change</Text>
                <BsArrowRight size="1em" />
              </Button>
            </Flex>
          </Grid>
        </Box>
      </Card>

      <StatusModal
        isOpen={message.isOpen}
        onRequestClose={onModalClose}
        success={message.success}
        children={message.message}
      />
    </Box>
  )
}

export default LoginInformationPage
