import { FC } from 'react'
import { IconButton, Image } from 'theme-ui'

const SideBarIcon: FC<{
  icon: string
  // link : String
}> = ({ icon }) => {
  return (
    <IconButton>
      {/* <svg width="100" height="50" viewBox="0 0 100 50 ">
        <circle r={50} cx={50} cy={50} fill="black" stroke="blue" strokeWidth={2} />
      </svg> */}
      <Image src={icon} />
    </IconButton>
  )
}

export default SideBarIcon
