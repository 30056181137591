import { Box, Container, Flex, Grid } from 'theme-ui'
import NFTPreviewCard from 'components/Stake/nft-preview-card'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import _ from 'lodash'
import { IDataResponse, serialize } from 'network/request'
import NFTService, { NFT } from 'network/services/nft'
import useSWR from 'swr'
import MobileReturnHeader from 'components/MobileReturnHeader'
import TitleWithBack from 'components/TitleWithBack'
import { useResolution } from 'hooks/useResolution'

const NftPreviewAll = () => {
  const { isMobile } = useResolution()

  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getAll, {
      sort: 'id:asc',
      page: 1,
      limit: 108
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message="Unable to retrieve amplify pools information"
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nfts = NFTService.toRow(data)
  if (_.isEmpty(nfts)) {
    return <></>
  }
  return (
    <Box>
      {isMobile && <MobileReturnHeader title="108 CP Clan NFT" delta={-1} />}
      <TitleWithBack backRef="/home" title="108 CP Clan NFT" />
      <Container>
        <Grid columns={[2, 2, 4]} gap={6}>
          {nfts.map((nft) => {
            return (
              <NFTPreviewCard
                key={nft.id}
                NFT={nft}
                showTitle
                isBlind={nft.type === 'nft' ? false : true}
              />
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default NftPreviewAll
