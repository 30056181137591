import { FC } from 'react'

const InfoIcon: FC<{ background?: string; fill?: string }> = ({
  background = '#212760',
  fill = '#8e9dea'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.488"
      height="18.488"
      viewBox="0 0 18.488 18.488"
    >
      <path
        d="M9.244,0a9.244,9.244,0,1,0,9.244,9.244A9.276,9.276,0,0,0,9.244,0Z"
        fill={background}
      />
      <g id="Group_1682" data-name="Group 1682" transform="translate(8.161 3.828)">
        <path
          d="M227.083,196A1.086,1.086,0,0,0,226,197.083V202.5a1.083,1.083,0,0,0,2.167,0v-5.416A1.086,1.086,0,0,0,227.083,196Z"
          transform="translate(-226 -192.75)"
          fill={fill}
        />
        <path
          d="M227.083,106a1.083,1.083,0,1,0,1.083,1.083A1.086,1.086,0,0,0,227.083,106Z"
          transform="translate(-226 -106)"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default InfoIcon
