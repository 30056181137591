import { FC, useMemo } from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Box, Button, Flex, IconButton, Text } from 'theme-ui'

const PageControl: FC<{
  page: number
  total: number
  setPage: (page: number) => any
  limit: number
  pageLimit?: number
}> = ({ page, total, setPage, limit, pageLimit = 15 }) => {
  const totalPages = useMemo(() => Math.ceil(total / limit), [total, limit])
  const pageOffset = useMemo(() => {
    if (page > Math.ceil(pageLimit / 2) + 1) {
      const offset = page - Math.ceil(pageLimit / 2)
      if (offset + pageLimit - 1 > totalPages) {
        return totalPages - pageLimit
      }
      return offset
    }
    return 0
  }, [page, totalPages, pageLimit])

  return (
    <Flex variant="layout.hStack" sx={{ width: '100%', justifyContent: 'center' }}>
      <Box>
        <IconButton
          onClick={() => {
            page - 1 > 0 && setPage(page - 1)
          }}
        >
          <BsArrowLeft size="1.5em" />
        </IconButton>
      </Box>

      {Array.from({ length: totalPages > pageLimit ? pageLimit : totalPages }).map((_, index) => {
        const pageNo = index + 1 + pageOffset
        return (
          <Box key={`page-btn-${pageNo}`}>
            {pageNo === page ? (
              <Text color="textMuted">{pageNo}</Text>
            ) : (
              <Button variant="text" onClick={() => setPage(pageNo)}>
                {pageNo}
              </Button>
            )}
          </Box>
        )
      })}
      {pageOffset + pageLimit < totalPages && (
        <Box>
          <Text>...</Text>
        </Box>
      )}

      <Box>
        <IconButton onClick={() => page + 1 <= totalPages && setPage(page + 1)}>
          <BsArrowRight size="1.5em" />
        </IconButton>
      </Box>
    </Flex>
  )
}

export default PageControl
