import { useState } from 'react'
import { Box, Button, Flex, Heading } from 'theme-ui'
import { useResolution } from 'hooks/useResolution'
// import MobileReturnHeader from 'components/MobileReturnHeader'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'
import NFTWithdrawPage from './withdraw-page'
// import NFTTransferPage from './transfer-page'

let AnimatedBox = motion(Box)

const NFTWithdraw = () => {
  const [pageView, setPageView] = useState<number>(0)
  const { isMobile } = useResolution()

  // console.log(pageView)

  return (
    <Box variant="layout.pageContainer">
      <AnimatePresence exitBeforeEnter>
        {
          {
            0: (
              <AnimatedBox
                key="withdraw"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <NFTWithdrawPage />
              </AnimatedBox>
            )
            // 1: (
            //   <AnimatedBox
            //     key="transfer"
            //     initial="hide"
            //     animate="show"
            //     exit="hide"
            //     variants={fadeAnim}
            //   >
            //     <NFTTransferPage />
            //   </AnimatedBox>
            // )
          }[pageView]
        }
      </AnimatePresence>
    </Box>
  )
}
export default NFTWithdraw
