import { createContext, useContext, useState } from 'react'

const navBarDisplayContext = createContext<{
  navBarDisplay: boolean
  setNavBarDisplay: (value: boolean) => any
}>({
  navBarDisplay: true,
  setNavBarDisplay: (value) => {}
})

export const useNavBarDisplay = () => useContext(navBarDisplayContext)

export const NavBarDisplayContextProvider = ({ children }: { children: JSX.Element }) => {
  const [navBarDisplay, setNavBarDisplay] = useState<boolean>(true)

  return (
    <navBarDisplayContext.Provider value={{ navBarDisplay, setNavBarDisplay }}>
      {children}
    </navBarDisplayContext.Provider>
  )
}
