/** @jsxImportSource theme-ui */
import { Box, Button, Flex, Grid, Heading, Select, Text, Textarea } from 'theme-ui'
import { useState, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import useSWR from 'swr'
import _ from 'lodash'

import { useResolution } from 'hooks/useResolution'
import { getProfile } from 'store/auth'
import { serialize } from 'network/request'
import TicketService, { Ticket, CreateTicket } from 'network/services/tickets'
import FileService from 'network/services/file'
import Table from 'components/Table'
import UploadButton from 'components/UploadButton'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import FormInput from 'components/form-input'
import StatusModal from 'components/status-modal'

const HelpCentrePage = () => {
  return (
    <Box variant="layout.pageContainer">
      <Heading variant="largeTitle">Help Centre</Heading>
      <Box p={4} />
      <PageView />
    </Box>
  )
}

const PageView = () => {
  const profile = getProfile()
  const navigate = useNavigate()
  const { isMobile } = useResolution()

  // files
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<string | null>(null)

  // tickets api
  const [page, setPage] = useState(1)
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const { data, error, mutate } = useSWR<{ data: Ticket[] }>(
    serialize(TicketService.getAll, {
      page: page,
      limit: 5,
      sort: 'created_at:desc'
    })
  )

  const method = useForm<CreateTicket>({
    shouldUseNativeValidation: false,
    defaultValues: {
      email: profile?.email,
      mobile: profile?.phone,
      name: profile?.username
    }
  })

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Announcement details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  // map data for table rows
  const tickets = TicketService.toRow(data).map((item) => {
    return {
      ticket_num: item.id,
      reason: item.subject,
      dateTime: item.created_at,
      status: (
        <Flex variant="layout.flexCenterSpaceBetween">
          <Text>{_.startCase(item.status)}</Text>
          <Button sx={{ ml: 3 }} onClick={() => navigate(`${item.id}`)}>
            View
          </Button>
        </Flex>
      )
    }
  })

  const total = TicketService.toPaginate(data).total

  const handleSubmit = method.handleSubmit(async (data) => {
    method.clearErrors()
    try {
      const { data: result } = await TicketService.createTicket(data)
      if (result.success) {
        method.reset()
        mutate()
        setMessage({
          isOpen: true,
          message: 'Submit Succesfully'
        })
        setUploadedFile(null)
      }
    } catch (e: any) {
      setMessage({
        success: false,
        isOpen: true,
        message: e.message ?? ''
      })
    }
  })

  const handleUpload = async (files: FileList | null) => {
    // make sure files is not empty
    if (files == null || _.isEmpty(files)) {
      return
    }

    setIsUploading(true)

    try {
      const { data } = await FileService.create(files[0])

      if (data.success) {
        method.setValue('attachment', data.data)
        setUploadedFile(data.data)
      } else {
        setMessage({
          isOpen: true,
          success: false,
          message: 'Failed to upload attachment, please try again later'
        })
      }
    } catch (e: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: e.message
      })
    }

    setIsUploading(false)
  }
  return (
    <Box>
      <Box as="form" onSubmit={handleSubmit}>
        <FormProvider {...method}>
          <Grid width="100%" gap={10}>
            <ChooseReason />
            <MessageText />
            <Box>
              <Box>
                <Text variant="large">Upload Your Attachment</Text>
              </Box>
              <Box p={1} />
              <UploadButton onChange={handleUpload} name="attachment">
                {isUploading ? (
                  <Text color="textMuted">Uploading...</Text>
                ) : (
                  uploadedFile != null && <Text>{uploadedFile}</Text>
                )}
              </UploadButton>
            </Box>
            <SubmitConfirm />
            <Box p={2} />
          </Grid>
        </FormProvider>
      </Box>
      <Table
        headers={['Ticket Number', 'Reason', 'Date & Time', 'Status']}
        data={tickets}
        width={isMobile ? 800 : '100%'}
        page={page}
        onPageChange={setPage}
        total={total}
      />
      <StatusModal
        isOpen={message.isOpen}
        children={message.message}
        success={message.success}
        onRequestClose={() => setMessage({ isOpen: false })}
      />
    </Box>
  )
}

const ChooseReason: FC = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateTicket>()
  return (
    <Box>
      <Box>
        <Box>
          <Text variant="large">
            Please choose a reason which best describe why you are contacting us.
          </Text>
        </Box>
        <Box p={1} />
        <Select
          variant="secondarySelect"
          sx={{ width: '100%', textAlign: 'left' }}
          {...register('subject', {
            required: 'You must select a subject'
          })}
        >
          <option key="General" value="General">
            General
          </option>
          <option key="Security" value="Security">
            Security
          </option>
        </Select>
        <FormInput<CreateTicket>
          errors={errors}
          name="subject"
          rules={{
            required: 'You must select a subject'
          }}
          containerSX={{ display: 'none' }}
        />
      </Box>
    </Box>
  )
}

const MessageText: FC = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateTicket>()
  return (
    <Box>
      <Box>
        <Text variant="large">Message</Text>
      </Box>
      <Box p={1} />
      <Textarea
        sx={{ width: '100%', height: '80%' }}
        placeholder="Your Message"
        {...register('message', {
          required: 'You must enter a Message'
        })}
      />
      <FormInput<CreateTicket>
        errors={errors}
        name="message"
        rules={{
          required: 'You must enter a Message'
        }}
        containerSX={{ display: 'none' }}
      />
    </Box>
  )
}

const SubmitConfirm: FC = () => {
  const {
    formState: { isSubmitting }
  } = useFormContext<CreateTicket>()
  return (
    <Flex sx={{ justifyContent: 'right', width: '100%' }}>
      <Button
        type="submit"
        variant="primaryFlexCenter"
        sx={{ justifyContent: 'space-between' }}
        disabled={isSubmitting}
      >
        <Box />
        <Text>Submit</Text>
        <BsArrowRight size="1em" />
      </Button>
    </Flex>
  )
}

export default HelpCentrePage
