/** @jsxImportSource theme-ui */
import { Box, Flex, Text, IconButton } from 'theme-ui'
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import useWebSocket from 'react-use-websocket'
import axios from 'axios'

import { BinanceSocketStream, currencyStore } from 'store/currency'
import HorizontalScroller from 'layout/horizontal-scroller'
import TitleWithBack from 'components/TitleWithBack'
import TokenIcon from 'components/icons/token'
import commaNumber from 'modules/comma-number'

interface MarketHeader {
  name: string
  key: string
}

interface SortStatus {
  key: string
  ascending: boolean
}

interface Market7D {
  priceChangePercent: string
  symbol: string
  volume: string
}

const MarketsPage = () => {
  const [sortStatus, setSortStatus] = useState<SortStatus>()
  const [headers, setHeaders] = useState<MarketHeader[]>([
    {
      name: 'Name',
      key: 'name'
    },
    {
      name: 'Price',
      key: 'price'
    },
    {
      name: '24h Change',
      key: 'change_24h'
    },
    {
      name: '7d Change',
      key: 'change_7d'
    },
    {
      name: '24h Volume',
      key: 'volume_24h'
    }
  ])

  // const handleSort = (key: keyof) => {
  //   console.log(sortStatus)
  //   var ascending = true
  //   if (key.toString() === sortStatus?.key) {
  //     ascending = !sortStatus.ascending
  //   }
  //   const sortedMarkets = markets.sort((a, b) =>
  //     ascending
  //       ? parseInt(a[key].toString()) - parseInt(b[key].toString())
  //       : parseInt(b[key].toString()) - parseInt(a[key].toString())
  //   )
  //   setSortStatus({
  //     key: key.toString(),
  //     ascending: ascending
  //   })
  //   setMarkets([...sortedMarkets])
  // }

  const [socketUrl] = useState(
    'wss://stream.binance.com:9443/stream?streams=btcusdt@ticker/ethusdt@ticker/bnbusdt@ticker'
  )
  const { lastMessage, readyState } = useWebSocket(socketUrl)
  const { btc, eth, bnb } = useSnapshot(currencyStore)
  const [btc7d, setBtc7d] = useState<Market7D | null>(null)
  const [eth7d, setEth7d] = useState<Market7D | null>(null)
  const [bnb7d, setBnb7d] = useState<Market7D | null>(null)

  useEffect(() => {
    if (lastMessage !== null) {
      let obj = JSON.parse(lastMessage.data) as BinanceSocketStream

      if (obj.stream === 'btcusdt@ticker') {
        currencyStore.btc = obj.data
      }
      if (obj.stream === 'ethusdt@ticker') {
        currencyStore.eth = obj.data
      }
      if (obj.stream === 'bnbusdt@ticker') {
        currencyStore.bnb = obj.data
      }
    }
  }, [lastMessage])

  useEffect(() => {
    // fetch ticker once
    axios
      .get(
        `https://api.binance.com/api/v3/ticker?windowSize=7d&symbols=%5B"BTCUSDT","ETHUSDT","BNBUSDT"%5D`
      )
      .then((res) => {
        let data = res.data
        data.forEach((datum: any) => {
          if (datum.symbol.toLowerCase() === 'btcusdt') {
            setBtc7d(datum)
          }
          if (datum.symbol.toLowerCase() === 'ethusdt') {
            setEth7d(datum)
          }
          if (datum.symbol.toLowerCase() === 'bnbusdt') {
            setBnb7d(datum)
          }
        })
      })
      .catch((err) => {})
  }, [])

  return (
    <Box variant="layout.pageContainer">
      <Box>
        <TitleWithBack backRef="/home" title="Markets" />
      </Box>

      <Box>
        <HorizontalScroller>
          <table sx={{ width: [700, '100%'] }} cellSpacing={0}>
            <thead>
              <tr
                sx={{
                  borderRadius: '10px',
                  background: '#181D48',
                  margin: 0
                }}
              >
                {headers?.map((item, index) => (
                  <th
                    key={index}
                    sx={
                      index === 0
                        ? { borderRadius: '10px 0 0 10px', pl: 10 }
                        : index === headers.length - 1
                        ? { borderRadius: '0 10px 10px 0' }
                        : {}
                    }
                  >
                    <Flex variant="layout.flexCenterStart" sx={{ py: 5 }}>
                      <Text variant="large" color="textMuted">
                        {item.name}
                      </Text>
                      <IconButton
                        sx={{ color: 'textMuted' }}
                        // onClick={() => handleSort(item.key as keyof Market)}
                      >
                        {sortStatus?.key === item.key ? (
                          sortStatus.ascending ? (
                            <TiArrowSortedDown />
                          ) : (
                            <TiArrowSortedUp />
                          )
                        ) : (
                          <TiArrowUnsorted />
                        )}
                      </IconButton>
                    </Flex>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {btc && (
                <tr>
                  <td sx={{ py: 8, pl: 10 }}>
                    <Flex variant="layout.hStack" sx={{ alignItems: 'flex-start' }}>
                      <Box>
                        <TokenIcon type="btc" />
                      </Box>
                      <Box>
                        <Flex variant="layout.vStack">
                          <Box>
                            <Text as="p" variant="large">
                              {btc.s}
                            </Text>
                          </Box>
                          <Box>
                            <Text as="p" color="textMuted">
                              {btc.s}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text>${commaNumber(parseFloat(btc.c).toFixed(2))}</Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text color={parseFloat(btc.P) > 0 ? 'success' : 'danger'}>
                      {parseFloat(btc.P) > 0 ? '+' : ''}
                      {btc.P}%
                    </Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text
                      color={
                        parseFloat(btc7d?.priceChangePercent ?? '0') >= 0 ? 'success' : 'danger'
                      }
                    >
                      {parseFloat(btc7d?.priceChangePercent ?? '0') >= 0 ? '+' : ''}
                      {btc7d?.priceChangePercent ?? 0}%
                    </Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text>{commaNumber(parseFloat(btc.v).toFixed(2))}</Text>
                  </td>
                </tr>
              )}
              {eth && (
                <tr>
                  <td sx={{ py: 8, pl: 10 }}>
                    <Flex variant="layout.hStack" sx={{ alignItems: 'flex-start' }}>
                      <Box>
                        <TokenIcon type="eth" />
                      </Box>
                      <Box>
                        <Flex variant="layout.vStack">
                          <Box>
                            <Text as="p" variant="large">
                              {eth.s}
                            </Text>
                          </Box>
                          <Box>
                            <Text as="p" color="textMuted">
                              {eth.s}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text>${commaNumber(parseFloat(eth.c).toFixed(2))}</Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text color={parseFloat(eth.P) > 0 ? 'success' : 'danger'}>
                      {parseFloat(eth.P) > 0 ? '+' : ''}
                      {eth.P}%
                    </Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text
                      color={
                        parseFloat(eth7d?.priceChangePercent ?? '0') >= 0 ? 'success' : 'danger'
                      }
                    >
                      {parseFloat(eth7d?.priceChangePercent ?? '0') >= 0 ? '+' : ''}
                      {eth7d?.priceChangePercent ?? 0}%
                    </Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text>{commaNumber(parseFloat(eth.v).toFixed(2))}</Text>
                  </td>
                </tr>
              )}
              {bnb && (
                <tr>
                  <td sx={{ py: 8, pl: 10 }}>
                    <Flex variant="layout.hStack" sx={{ alignItems: 'flex-start' }}>
                      <Box>
                        <TokenIcon type="bnb" />
                      </Box>
                      <Box>
                        <Flex variant="layout.vStack">
                          <Box>
                            <Text as="p" variant="large">
                              {bnb.s}
                            </Text>
                          </Box>
                          <Box>
                            <Text as="p" color="textMuted">
                              {bnb.s}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text>${commaNumber(parseFloat(bnb.c).toFixed(2))}</Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text color={parseFloat(bnb.P) > 0 ? 'success' : 'danger'}>
                      {parseFloat(bnb.P) > 0 ? '+' : ''}
                      {bnb.P}%
                    </Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text
                      color={
                        parseFloat(bnb7d?.priceChangePercent ?? '0') >= 0 ? 'success' : 'danger'
                      }
                    >
                      {parseFloat(bnb7d?.priceChangePercent ?? '0') >= 0 ? '+' : ''}
                      {bnb7d?.priceChangePercent ?? 0}%
                    </Text>
                  </td>
                  <td sx={{ py: 8 }}>
                    <Text>{commaNumber(parseFloat(bnb.v).toFixed(2))}</Text>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </HorizontalScroller>
      </Box>
    </Box>
  )
}

export default MarketsPage
