import { FC, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { Box, Button, Flex, Image, Text } from 'theme-ui'

import FormInput from 'components/form-input'
import { useResolution } from 'hooks/useResolution'

const TwoFactorAuthenticationStep2: FC<{ otpUrl: string; base32: string }> = ({
  otpUrl,
  base32
}) => {
  const [qr, setQr] = useState<string>('')

  useEffect(() => {
    if (!otpUrl) {
      setQr('')
      return
    }

    QRCode.toDataURL(otpUrl, {
      width: 300
    }).then((url) => setQr(url))
  }, [otpUrl])

  return (
    <Box>
      <Box>
        <Text variant="large">Step 2</Text>
      </Box>
      <Box p={1} />
      <Box>
        <Text color="secondaryText" variant="mediumSmall">
          Scan this QR code in the Google Authenticator app
        </Text>
      </Box>
      <Box p={2} />
      <Box>
        <Flex
          variant="layout.flexCenterCenter"
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: ['flex-start', 'center'],
            justifyContent: 'flex-start'
          }}
        >
          <Box sx={{ width: 300, height: 300 }}>
            <Image src={qr} />
          </Box>
          <Box p={[2, 0]} />
          <Box sx={{ ml: [0, 15], width: ['100%', 500] }}>
            <Box sx={{ width: ['100%', 300, 400] }}>
              <Text>
                If you are unable to scan the QR code, please copy the code and paste into the app.
              </Text>
            </Box>
            <Box p={5} />
            <Box>
              <CopyCode code={base32} />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

const CopyCode: FC<{ code: string }> = ({ code }) => {
  const { isMobile } = useResolution()

  const handleCopy = () => {
    navigator.clipboard.writeText(code)
    // TODO: show copy success dialog
  }

  return isMobile ? (
    <FormInput
      name=""
      containerSX={{ borderRadius: 30, pr: 0 }}
      disabled
      value={code}
      trailing={
        <Button
          onClick={handleCopy}
          sx={{ py: 5, borderRadius: '100%', minWidth: ['auto'] }}
          type="button"
        >
          <Image src="/assets/svg/copy.svg" />
        </Button>
      }
    />
  ) : (
    <Flex variant="layout.flexCenterStart">
      <FormInput name="" containerSX={{ borderRadius: 30, mr: 5 }} disabled value={code} />
      <Button variant="primaryFlexCenter" onClick={handleCopy} type="button">
        <Text>Copy</Text>
        <Image sx={{ ml: 3 }} src="/assets/svg/copy.svg" />
      </Button>
    </Flex>
  )
}

export default TwoFactorAuthenticationStep2
