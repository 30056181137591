import { Box, Text, Flex } from 'theme-ui'
import { MdOutlineCopyright } from 'react-icons/md'
import SideBarIcon from './sidebar-icon'
import socialLinkUrl from 'components/socialLinksUrl'

const Footer = () => {
  // const [colorMode, setColorMode] = useColorMode()

  // const switchColorMode = () => {
  //   colorMode === 'default' ? setColorMode('light') : setColorMode('default')
  // }

  return (
    <Flex
      variant="layout.flexCenterEnd"
      sx={{
        flex: 1,
        flexDirection: 'column',
        // alignItems: 'center',
        marginTop: 50
      }}
    >
      {/* <Button sx={{ width: 300 }} onClick={switchColorMode}>
        {colorMode === 'default' ? 'Switch to light mode' : 'Switch to dark mode'}
      </Button> */}
      <Box pt={4} />
      <Box>
        <a href={socialLinkUrl.facebook} target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/facebook.svg'} />
        </a>
        <a href={socialLinkUrl.telegram} target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/telegram.svg'} />
        </a>
        <a href={socialLinkUrl.twitter} target="_blank" rel="noreferrer">
          <SideBarIcon icon={'/assets/svg/twitter.svg'} />
        </a>
        <Box sx={{ display: 'inline-block', width: '25px', height: '32px' }}>
          <a href={socialLinkUrl.tiktok} target="_blank" rel="noreferrer">
            <SideBarIcon icon={'/assets/svg/tiktok.svg'} />
          </a>
        </Box>
        <Box sx={{ display: 'inline-block', width: '25px', height: '32px' }}>
          <a href={socialLinkUrl.instagram} target="_blank" rel="noreferrer">
            <SideBarIcon icon={'/assets/svg/instagram.svg'} />
          </a>
        </Box>
        <Box sx={{ display: 'inline-block', width: '25px', height: '32px' }}>
          <a href={socialLinkUrl.youtube} target="_blank" rel="noreferrer">
            <SideBarIcon icon={'/assets/svg/youtube.svg'} />
          </a>
        </Box>
        <Box sx={{ display: 'inline-block', width: '25px', height: '32px' }}>
          <a href={socialLinkUrl.xiaohongshu} target="_blank" rel="noreferrer">
            <SideBarIcon icon={'/assets/svg/xiaohongshu.svg'} />
          </a>
        </Box>
      </Box>
      <Box pt={4} />
      <Box>
        <Text>
          PBC <MdOutlineCopyright /> {new Date().getFullYear()}. All Rights Reserved
        </Text>
      </Box>
    </Flex>
  )
}

export default Footer
