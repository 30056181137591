import { FC } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'

const TwoFactorAuthenticationStep1: FC = () => {
  return (
    <Box>
      <Box>
        <Text variant="large">Step 1</Text>
      </Box>
      <Box p={1} />
      <Box>
        <Text color="secondaryText" variant="mediumSmall">
          Download and install the Google Authenticator app
        </Text>
      </Box>
      <Box p={2} />
      <Box>
        <Flex variant="layout.flexCenterStart">
          <Image src="/assets/svg/google-play.svg" />
          <Image src="/assets/svg/app-store.svg" sx={{ ml: 3 }} />
        </Flex>
      </Box>
    </Box>
  )
}

export default TwoFactorAuthenticationStep1
