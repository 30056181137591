import { FC } from 'react'
import { Box, Card, Heading, Text, Image, Flex, Button } from 'theme-ui'

const ErrorCard: FC<{ message?: string; refresh?: () => void }> = ({
  message = 'Something went wrong',
  refresh
}) => {
  return (
    <Box sx={{ pt: 13 }}>
      <Card sx={{ width: 300 }}>
        <Box sx={{ height: 80, position: 'relative' }}>
          <Flex
            variant="layout.flexCenterCenter"
            sx={{
              position: 'absolute',
              top: -12
            }}
          >
            <Image src="/assets/images/failed.png" />
          </Flex>
        </Box>
        <Box pt={4} />
        <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Heading>Failed</Heading>
          <Box pt={2} />
          <Text>{message}</Text>
          <Box pt={2} />
          <Button onClick={() => refresh}>Refresh</Button>
        </Box>
      </Card>
    </Box>
  )
}

export default ErrorCard
