import { NFT } from 'network/services/nft'
import { FC } from 'react'
import { AspectRatio, Box, Card, Grid, Image, Text } from 'theme-ui'

interface NFTSelectionCardProps {
  isSelected?: boolean
  NFT: NFT
  handleSelect?: (id: number | string) => any
}

const NFTSelectionCard: FC<NFTSelectionCardProps> = ({ isSelected = false, NFT, handleSelect }) => {
  return (
    <Card
      sx={{ border: isSelected ? 'solid 2px' : 'none', borderColor: 'primary', cursor: 'pointer' }}
      onClick={() => {
        handleSelect && handleSelect(NFT.id)
      }}
    >
      <Grid gap={3}>
        <Box sx={{ borderRadius: 10, overflow: 'hidden' }}>
          <AspectRatio ratio={1 / 1}>
            <Image
              src={NFT.type === 'nft' ? NFT.image_url : '/blind_box.jpg'}
              sx={{
                width: '100%',
                objectFit: 'cover'
              }}
              draggable={false}
            />
          </AspectRatio>
        </Box>

        <Box>
          <Text>{NFT.name}</Text>
        </Box>
        {NFT.type === 'blind_box' && (
          <Box>
            <Text sx={{ color: 'textMuted' }} variant="small">
              {NFT?.description.slice(0, 70)} {NFT?.description.length > 70 ? '...' : ''}
            </Text>
          </Box>
        )}
      </Grid>
    </Card>
  )
}

export default NFTSelectionCard
