/** @jsxImportSource theme-ui */
import { FC, useMemo, useState } from 'react'
import { Box, Card, Flex, Grid, Text } from 'theme-ui'

import { useResolution } from 'hooks/useResolution'
import Table from 'components/Table'
import PoolTokenCard from 'components/Stake/token-card'
import useSWR from 'swr'
import PoolService, { Pool, PoolProfit } from 'network/services/pool'
import { serialize } from 'network/request'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import PoolOverviewCard from 'components/Stake/overview-card'
import HorizontalScroller from 'layout/horizontal-scroller'
import { LineChart } from 'components/LineChart'
import toUTCTime from 'modules/to-utc-time'

const StakePoolVXTPage = () => {
  const { data, error, mutate } = useSWR<{ data: Pool[] }>(
    serialize(PoolService.getAll, { page: 1, limit: 1, type: 'vxt' })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve the overview of VXT pool" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Grid sx={{ width: '100%' }} gap={8}>
      <PoolTokenCard pool={data.data[0]} />

      <Grid sx={{ width: '100%' }} columns={[1, '1fr 2fr']} gap={8}>
        <PoolOverviewCard pool={data.data[0]} />

        <ChartView pool={data.data[0]} />
      </Grid>

      <TableView pool={data.data[0]} />
    </Grid>
  )
}

const ChartView: FC<{ pool: Pool }> = ({ pool }) => {
  const id = pool.id
  const { data, error, mutate } = useSWR<{ data: PoolProfit[] }>(
    id != null
      ? serialize(PoolService.getProfits(id), {
          page: 1,
          limit: 10,
          sort: 'distributed_at:desc'
        })
      : null
  )
  const chartData = useMemo(
    () =>
      data
        ? PoolService.profitToRow(data).map((item) => {
            const date = new Date(item.distributed_at)
            const mth = date.toLocaleString('en-us', { month: 'short' })
            return {
              x: `${mth} ${date.getDate()}`,
              y: item.profit_rate
            }
          })
        : undefined,
    [data]
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to display VXT profit chart" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  return (
    <Card sx={{ background: 'darkBG' }}>
      <HorizontalScroller breakpointEnabled={false}>
        <Box sx={{ height: 400, px: 6, py: 8, width: [800, '100%'] }}>
          <Box>
            <Text variant="large">Pool Profit</Text>
          </Box>
          <LineChart data={chartData} />
        </Box>
      </HorizontalScroller>
    </Card>
  )
}

const TableView: FC<{ pool: Pool }> = ({ pool }) => {
  const id = pool.id
  const { isMobile } = useResolution()
  const [page, setPage] = useState(1)
  const limit = 10
  const { data, error, mutate } = useSWR<{ data: PoolProfit[] }>(
    id != null
      ? serialize(PoolService.getProfits(id), {
          page: page,
          limit: limit,
          sort: 'distributed_at:desc'
        })
      : null
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve VXT profits" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  const tableData = PoolService.profitToRow(data).map((profit) => {
    return {
      last_distribution_date: toUTCTime(profit.distributed_at),
      pool_size: `${profit.total_vxt ?? 0} ${profit.token_type}`,
      profit: (
        <Text>
          {`${profit.profit_value ?? 0} ${profit.token_type} `}
          <Text color={profit.profit_rate > 0 ? 'success' : 'danger'}>({profit.profit_rate}%)</Text>
        </Text>
      )
    }
  })
  const total = PoolService.profitToPaginate(data).total

  return (
    <Table
      headers={['Last Distribution Date', 'Pool Size', 'Profit']}
      data={tableData}
      width={isMobile ? 800 : '100%'}
      page={page}
      limit={limit}
      total={total}
      onPageChange={setPage}
    />
  )
}

export default StakePoolVXTPage
