import { Suspense } from 'react'
import { Flex, ThemeProvider, useColorMode } from 'theme-ui'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import '@fontsource/poppins'
import theme from 'theme'
import { AnimatePresence } from 'framer-motion'

import AuthenticatedLayout from 'layout/authenticated'
import PublicLayout from 'layout/public'
import GeneralLayout from 'layout/general'
import { authStore, checkAuthorization } from 'store/auth'

import LoginPage from 'pages/SignIn'
import SignUpPage from 'pages/SignUp'
import SignUpDetail from 'pages/SignUp/sign-up-detail'
import ThemeReference from 'pages/reference'
import HomePage from 'pages/Home'
import NftPreviewPage from 'pages/Home/nft-preview'
import HelpCentrePage from 'pages/HelpCentre'
import HelpCentreChat from 'pages/HelpCentre/help-chat'
import ForgetPasswordPage from 'pages/ForgetPassword'
import GiftRewardsPage from 'pages/GiftReward'
import WalletPage from 'pages/Wallet'
import WalletDeposit from 'pages/Wallet/deposit'
import WalletWithdraw from 'pages/Wallet/withdraw'
import StakePage from 'pages/Stake'
import StakeMyContract from 'pages/Stake/my-contract'
import StakeSubmissionPage from 'pages/Stake/stake-submission'
import HowProfitDistributionWorksPage from 'pages/Stake/how-profit-distribution-works'
import NotificationPage from 'pages/Notification'
import NFTsPage from 'pages/NFTs'
import NFTWithdraw from 'pages/NFTs/nft-withdraw'
import NFTDetail from 'pages/NFTs/nft-detail'
import MyNFTsDetails from 'pages/NFTs/my-nfts-details'
import MarketsPage from 'pages/Markets'
import SecurityPage from 'pages/Security'
import LoginInformationPage from 'pages/Security/login-information'
import TwoFactorAuthenticationPage from 'pages/Security/two-factor-authentication'
import LastLoginPage from 'pages/Security/last-login'
import StakePoolsPage from 'pages/Stake/stake-pools'
import IdentityVerificationPage from 'pages/IdentityVerification'
import IDVerification from 'pages/IdentityVerification/id-verification'
import PassportVerification from 'pages/IdentityVerification/passport-verification'
import StakeOverview from 'pages/Stake/stake-overview'
import AnnouncementsPage from 'pages/Announcements'
import AnnouncementDetail from 'pages/Announcements/announcement-detail'
import AboutUsPage from 'pages/AboutUs'
import HowItWorksPage from 'pages/HowItWorks'
import TermsConditionPage from 'pages/TermsCondition'
import PrivacyPolicyPage from 'pages/PrivacyPolicy'
import SystemAcquisitionPage from 'pages/SecurityPolicy/SystemAcquisition'
import OperationSecurityPage from 'pages/SecurityPolicy/OperationsSecurity'
import InformationSecurityPage from 'pages/SecurityPolicy/InformationSecurity'
import NetworkSecurityPage from 'pages/SecurityPolicy/NetworkSecurity'
import PasswordPolicyPage from 'pages/SecurityPolicy/PasswordPolicy'
import UserAgreementPage from 'pages/UserAgreement'
import NewsPage from 'pages/News'
import ProposalsPage from 'pages/Proposals'
import ProposalsDetailPage from 'pages/Proposals/proposals-detail'

import './i18n_config'
import I18n from './i18n'
import LoadingCard from 'components/loading'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { token: isLoggedIn } = useSnapshot(authStore)
  let location = useLocation()

  if (!isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <Flex sx={{ height: '90vh' }} variant="layout.flexCenterCenter">
            <LoadingCard />
          </Flex>
        }
      >
        <I18n />
        <Init />
      </Suspense>
    </ThemeProvider>
  )
}

function Init() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [colorMode, setColorMode] = useColorMode()

  setColorMode('default')

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes>
        <Route
          element={
            <RequireAuth>
              <AuthenticatedLayout />
            </RequireAuth>
          }
        >
          {/* <Route path="/about-us" element={<AboutUsPage />} /> */}
          <Route path="/how-it-works" element={<HowItWorksPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/home/nft-preview" element={<NftPreviewPage />} />
          <Route path="/help" element={<HelpCentrePage />} />
          <Route path="/help/:id" element={<HelpCentreChat />} />
          <Route path="/markets" element={<MarketsPage />} />
          {/* <Route path="/gift-rewards" element={<GiftRewardsPage />} /> */}
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/announcements">
            <Route path="" element={<AnnouncementsPage />} />
            <Route path=":id" element={<AnnouncementDetail />} />
          </Route>
          <Route path="/security">
            <Route path="" element={<SecurityPage />} />
            <Route path="login-information" element={<LoginInformationPage />} />
            <Route path="2-factor-authentication" element={<TwoFactorAuthenticationPage />} />
            <Route path="last-login" element={<LastLoginPage />} />
          </Route>
          <Route path="/wallet">
            <Route path="" element={<WalletPage />} />
            {/* <Route path="deposit" element={<WalletDeposit />} /> */}
            <Route path="withdraw" element={<WalletWithdraw />} />
          </Route>
          <Route path="/stake">
            <Route path="" element={<StakePage />} />
            <Route path=":id" element={<StakeMyContract />} />
            <Route path="pools" element={<StakePoolsPage />} />
            <Route path="pools/:id/how" element={<HowProfitDistributionWorksPage />} />
            <Route path="submit/:id" element={<StakeSubmissionPage />} />
            <Route path="overview/:id" element={<StakeOverview />} />
          </Route>
          {/* <Route path="/identity-verification">
            <Route path="" element={<IdentityVerificationPage />} />
            <Route path="ic" element={<IDVerification />} />
            <Route path="passport" element={<PassportVerification />} />
          </Route> */}
          <Route path="/nfts">
            <Route path="" element={<NFTsPage />} />
            <Route path=":id/withdraw" element={<NFTWithdraw />} />
            <Route path="shop/:id" element={<NFTDetail />} />
            <Route path=":id" element={<MyNFTsDetails />} />
          </Route>
          <Route path="/news">
            <Route path="" element={<NewsPage />} />
          </Route>
          <Route path="/proposals">
            <Route path="" element={<ProposalsPage />} />
            <Route path=":id" element={<ProposalsDetailPage />} />
          </Route>
        </Route>

        {/* <Route path="/reference" element={<ThemeReference />} /> */}

        <Route element={<GeneralLayout />}>
          <Route path="/terms-condition" element={<TermsConditionPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/user-agreement" element={<UserAgreementPage />} />
          <Route
            path="/system-acquisition-development-maintenance"
            element={<SystemAcquisitionPage />}
          />
          <Route path="/operations-security" element={<OperationSecurityPage />} />
          <Route path="/information-security-policy" element={<InformationSecurityPage />} />
          <Route path="/network-communication-security" element={<NetworkSecurityPage />} />
          <Route path="/password-policy" element={<PasswordPolicyPage />} />
        </Route>

        <Route element={<PublicLayout />}>
          <Route path="/forget-password" element={<ForgetPasswordPage />} />
          {/* <Route path="/sign-up" element={<SignUpDetail />} /> */}
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="/" element={<LoginPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default App
