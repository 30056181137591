import { FC, useState } from 'react'
import { Box, Card, Heading, Text, Image, Flex } from 'theme-ui'
import CustomModal from './modal'

const ErrorPopUpCard: FC<{ message?: string; onRequestClose?: () => void }> = ({
  message = 'Something went wrong',
  onRequestClose
}) => {
  return (
    <CustomModal
      isOpen={true}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
      contentStyle={{
        height: '50%'
      }}
    >
      <Box sx={{ pt: 15 }}>
        <Card sx={{ width: 300 }}>
          <Box sx={{ height: 80, position: 'relative' }}>
            <Flex
              variant="layout.flexCenterCenter"
              sx={{
                position: 'absolute',
                top: -12
              }}
            >
              <Image src="/assets/images/failed.png" />
            </Flex>
          </Box>
          <Box pt={4} />
          <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
            <Heading>Failed</Heading>
            <Box pt={2} />
            <Text>{message}</Text>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

export default ErrorPopUpCard
