import client, { IDataResponse } from '../request'

export type Asset = {
  id: string | number
  network_type?: string
  name: string
  symbol: string
  type?: string
  handle?: string
  base?: number
  contract_address?: string
  price?: number
  decimal?: number
  blocked: boolean
  allow_withdraw?: boolean
  allow_deposit?: boolean
  created_at: string
  updated_at: string
}

// useSWR, return strings
const getAll = '/assets'

// useSWR, return strings
const get = (id: string | number) => {
  return `/assets/${id}`
}

// axios
const create = (data: Asset) => {
  return client.post('/assets', data)
}

// axios
const update = (id: string | number, data: Asset) => {
  return client.put(`/assets/${id}`, data)
}

// processing
const toRow = (data: IDataResponse<Asset>): Asset[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<Asset>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id: string | number) => {
  return client.delete(`/assets/${id}`)
}

const AssetService = {
  getAll,
  get,
  create,
  update,
  toPaginate,
  toRow,
  remove
}

export default AssetService
