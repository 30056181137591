/** @jsxImportSource theme-ui */
import { Box, Flex, Heading, IconButton } from 'theme-ui'
import { FC, ReactNode, useMemo, useState } from 'react'

import InfoIcon from 'components/icons/info'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const MoreInfo: FC<{ title?: string; direction?: string; children?: ReactNode }> = ({
  title,
  direction = 'right',
  children
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false)

  const positionStyle = useMemo(() => {
    switch (direction) {
      case 'right':
        return {
          left: '100%'
        }
      case 'left':
        return {
          right: '100%'
        }
      case 'top':
        return {
          x: '-50%',
          bottom: '100%'
        }
      case 'bottom':
        return {
          x: '-50%',
          top: '100%'
        }
      case 'topLeft':
        return {
          x: '-70%',
          bottom: '100%'
        }
    }
  }, [direction])

  return (
    <Flex sx={{ position: 'relative' }}>
      {title && <Heading sx={{ mr: 2 }}>{title}</Heading>}
      <IconButton
        type="button"
        onClick={() => {
          setShowInfo(!showInfo)
        }}
        sx={{ mr: 1 }}
      >
        <InfoIcon />
      </IconButton>
      <AnimatePresence>
        {showInfo && (
          <AnimatedBox
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnim}
            style={{ position: 'absolute', ...positionStyle }}
          >
            {children}
          </AnimatedBox>
        )}
      </AnimatePresence>
    </Flex>
  )
}

export default MoreInfo
