import Tab from 'components/Tab'
import { Box, Flex, Grid } from 'theme-ui'
import ShopActive from './shop-active'
import { useState } from 'react'
import CompletedListing from './completed-listing'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'
import EventCard from 'components/Home/event-card'
import ErrorCard from 'components/error'
import { IDataResponse, serialize } from 'network/request'
import NFTService, { NFT } from 'network/services/nft'
import useSWR from 'swr'

let AnimatedBox = motion(Box)

const NFTShop = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getAll, {
      sort: 'id:asc',
      page: 1,
      limit: 1,
      status: 'active'
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard
          message="Unable to retrieve amplify pools information"
          refresh={() => mutate()}
        />
      </Flex>
    )
  }

  return (
    <Box>
      <Grid>
        <EventCard
          title={'108 CP CLAN NFT'}
          subtitle={'DISCOVER NOW'}
          imageUrl="/animated.gif"
          available={data?.meta?.total ?? 0}
          total={108}
        />
      </Grid>
      <Box p={4} />
      <Tab defaultTab={currentTab} tabs={['Available', 'Sold']} onTabChange={setCurrentTab} />
      <Box p={3} />

      <AnimatePresence exitBeforeEnter>
        {
          {
            0: (
              <AnimatedBox
                key="active"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <ShopActive />
              </AnimatedBox>
            ),
            1: (
              <AnimatedBox
                key="completed"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <CompletedListing />
              </AnimatedBox>
            )
          }[currentTab]
        }
      </AnimatePresence>
    </Box>
  )
}

export default NFTShop
