import { DeepMap, FieldError, get, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Box, Input, InputProps, Label, Text, ThemeUICSSObject } from 'theme-ui'
import { ReactElement } from 'react'

export type FormInputProps<TFormValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register?: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  label?: string
  heading?: ReactElement
  trailing?: ReactElement
  inputSX?: ThemeUICSSObject
  containerSX?: ThemeUICSSObject
  mb?: number
} & Omit<InputProps, 'name'>

export const FormInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  label,
  trailing,
  heading,
  inputSX,
  containerSX,
  mb = 2,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <>
      {label && (
        <Label htmlFor={name} mb={mb}>
          {label}
        </Label>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          bg: 'input',
          borderRadius: 10,
          background: 'input',
          width: '100%',
          px: 4,
          ...containerSX
        }}
      >
        {heading != null && heading}
        <Input
          autoComplete="off"
          autofillBackgroundColor={containerSX?.background?.toString() ?? 'input'}
          name={name}
          aria-invalid={hasError}
          {...props}
          {...(register && register(name, rules))}
          sx={{
            px: 0,
            flex: 1,
            background: 'none',
            '&:focus': { background: 'none' },
            ...inputSX
          }}
        />
        {trailing != null && trailing}
      </Box>
      {errors != null && (
        <ErrorMessage
          errors={errors}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name={name as any}
          render={({ message }) => <Text color="red">{message}</Text>}
        />
      )}
    </>
  )
}

export default FormInput
