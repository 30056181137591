import client, { IDataResponse } from '../request'
import toUTCTime from 'modules/to-utc-time'

export type Ticket = {
  id: string | number
  subject: string
  message: string
  email: string
  mobile: string
  name: string
  status: string
  user_id: string | number
  created_at: string
  updated_at: string
}

export type Message = {
  id: string | number
  message: string
  ticket_id: string | number
  sender_id: string | number
  recipient_id: string | number
  created_at: string
  updated_at: string
}

export type CreateTicket = {
  subject: string
  message: string
  email: string
  mobile: string
  name: string
  attachment: string
}

export type CreateMessage = {
  message: string
  ticket_id: string
}

const getAll = '/me/tickets'

const getTicket = (id: string | number) => {
  return `/me/tickets/${id}`
}

const getTicketMessage = (id: string | number) => {
  return `/me/tickets/${id}/messages`
}

const createTicket = (data: CreateTicket) => {
  return client.post('/me/tickets', data)
}

const createMessage = (data: CreateMessage) => {
  return client.post('/me/tickets/messages', data)
}

const toRow = (data: IDataResponse<Ticket>): Ticket[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

const toRowMessage = (data: any): Message[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element: Message) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data?: IDataResponse<Ticket>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const TicketService = {
  getAll,
  getTicket,
  getTicketMessage,
  createTicket,
  createMessage,
  toRow,
  toRowMessage,
  toPaginate
}

export default TicketService
