/** @jsxImportSource theme-ui */
import { Box, Card, Flex, Text, Grid } from 'theme-ui'
import { Notifications } from 'network/services/notification'
import { FC } from 'react'

const NotiCard: FC<{ notification: Notifications }> = ({ notification }) => {
  return (
    <Box>
      <Card sx={{ marginBottom: 1 }}>
        <Flex variant="layout.flexCenterSpaceBetween">
          <Flex>
            {notification.read ? (
              <Box pl={12} />
            ) : (
              <svg width="48" height="30">
                <circle r={5} cx={20} cy={20} fill="white" />
              </svg>
            )}
            <Grid gap={1}>
              <Text>{notification.title}</Text>
              <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
                {notification.content}
              </Text>
            </Grid>
          </Flex>
          <Text sx={{ color: 'textMuted' }}>{notification.created_at}</Text>
        </Flex>
      </Card>
    </Box>
  )
}

export default NotiCard
