import { FC, useEffect, useState } from 'react'
import { AspectRatio, Box, Card, Flex, Image, Text } from 'theme-ui'

import { useResolution } from 'hooks/useResolution'

const NewsCardSmall: FC<{
  imageSrc: string
  title: string
  source?: string
  date: string
}> = ({ imageSrc, title, source, date }) => {
  const { isMobile } = useResolution()
  const [slicedTitle, setSlicedTitle] = useState<string>()

  // TODO: use css for this instead
  useEffect(() => {
    if ((isMobile && title.length > 40) || (!isMobile && title.length > 750))
      setSlicedTitle(`${title.slice(0, isMobile ? 41 : 76)} ...`)
    else setSlicedTitle(title)
  }, [title, isMobile])

  return (
    <Card
      sx={{
        background: 'none',
        height: [100, 170],
        width: '100%',
        px: [0, 'auto'],
        pr: [0, 10],
        py: [3, 'auto'],
        overflow: 'hidden',
      }}
    >
      <Flex variant="layout.flexCenterStart">
        <Box
          sx={{
            mr: 5,
            height: ['10vh', '100%'],
            width: ['10vh', '40%'],
            overflow: 'hidden',
            borderRadius: 10
          }}
        >
          <AspectRatio ratio={isMobile ? 1 / 1 : 4 / 3}>
            <Image
              src={imageSrc}
              sx={{
                objectFit: 'cover'
              }}
            />
          </AspectRatio>
        </Box>
        <Box sx={{ my: 'auto', height: '90%', width: '60%' }}>
          <Box sx={{ height: '80%', pr: '3%' }}>
            <Text variant='mediumSmall'>{slicedTitle}</Text>
          </Box>
          <Box
            sx={{
              verticalAlign: 'bottom',
              height: '20%'
            }}
          >
            <Flex variant="layout.hStack">
              <Text variant="small" color="textMuted" sx={{ textAlign: 'left' }}>
                {source}
              </Text>
              <Text variant="small" color="primary" sx={{ mx: 3 }}>
                |
              </Text>
              <Text variant="small">{date}</Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Card>
  )
}

export default NewsCardSmall
