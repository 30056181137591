import Link from 'components/link'
import ProposalService, { Proposal } from 'network/services/proposals'
import { FC, useState } from 'react'
import { Box, Button, Card, Flex, Grid, Heading, Text } from 'theme-ui'
import { IDataResponse, serialize } from 'network/request'
import useSWR from 'swr'
import PageControl from 'components/page-control'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import { DateTime } from 'luxon'
import _ from 'lodash'
import CountdownCardProposal from './countdown-card'
import { useResolution } from 'hooks/useResolution'

const ProposalsPage = () => {
  const [page, setPage] = useState(1)
  const limit = 5

  const { data, error, mutate } = useSWR<IDataResponse<Proposal>>(
    serialize(ProposalService.getAll, {
      read: 0, // false for unread proposals only
      sort: 'created_at:desc',
      page: page,
      limit: limit
    })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve proposals" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const proposals = ProposalService.toRow(data)
  const total = ProposalService.toPaginate(data).total

  if (proposals.length === 0) {
    return (
      <Flex variant="layout.flexCenterCenter" sx={{ height: '80vh' }}>
        <Text>There are no proposals at the moment</Text>
      </Flex>
    )
  }

  return (
    <Grid gap={12} sx={{ width: '100%' }} px={[2, 0]}>
      <TitleSearchBar />
      <Grid columns={[1, 2]}>
        {proposals.map((proposal) => {
          return <ProposalsCard proposal={proposal} />
        })}
      </Grid>
      <Box pt={2} />
      {data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={setPage} />
      )}
    </Grid>
  )
}

const TitleSearchBar = () => {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: ['column', 'row']
      }}
    >
      <Heading color="secondaryText">Proposals list</Heading>
      {/* <Flex paddingTop={[4, 0]}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            background: 'input',
            pl: 4,
            pt: 4,
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
            cursor: 'pointer'
          }}
        >
          <AiOutlineSearch color="blue" size="28" />
        </Box>
        <Input
          placeholder="Search"
          // onChange={(e) => setSearch(e.currentTarget.value.toString())}
          sx={{
            flex: 1,
            borderRadius: 0
          }}
        />
        <Select
          defaultValue="All"
          variant="forms.secondarySelect"
          sx={{
            borderTopRightRadius: 30,
            borderBottomRightRadius: 30,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            minWidth: 'auto !important'
          }}
        >
          <option>All</option>
          <option>Active</option>
          <option>Closed</option>
        </Select>
      </Flex> */}
    </Flex>
  )
}

const ProposalsCard: FC<{ proposal: Proposal }> = ({ proposal }) => {
  const date = DateTime.fromISO(proposal.expired_at)
  const expiredDay = date.diffNow('days').days
  const { isMobile } = useResolution()

  return (
    <Card sx={{ px: 8 }}>
      <Box sx={{ textAlign: ['center', 'left'] }}>
        <Text variant="mediumTitle" color="white">
          {proposal.title}
        </Text>
      </Box>
      {isNaN(expiredDay) ? (
        '-'
      ) : Math.ceil(expiredDay) > 0 ? (
        isMobile ? (
          <Grid gap={0} sx={{ minWidth: '50px', pt: 3 }}>
            <Link
              to={`/proposals/${proposal.id}`}
              variant="unstyled"
              style={{ textAlign: 'center' }}
            >
              <Button>Vote</Button>
            </Link>
            <Box pt={4} />
            <CountdownCardProposal expired_at={proposal.expired_at} />
          </Grid>
        ) : (
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'column', 'row'],
              align: 'center',
              justifyContent: 'space-between',
              pt: 3
            }}
          >
            <Link to={`/proposals/${proposal.id}`} variant="unstyled" sx={{ alignSelf: 'center' }}>
              <Button>Vote</Button>
            </Link>
            <Box pt={4} pr={8} />
            <CountdownCardProposal expired_at={proposal.expired_at} />
          </Flex>
        )
      ) : (
        <Flex sx={{ justifyContent: 'space-between', pt: 3 }}>
          <Link to={`/proposals/${proposal.id}`} variant="unstyled">
            <Button>View</Button>
          </Link>
          <Text color="textMuted">Closed</Text>
        </Flex>
      )}
    </Card>
  )
}

export default ProposalsPage
