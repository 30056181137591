import { Box, Card, Heading, Link, Text } from 'theme-ui'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'
import { useResolution } from 'hooks/useResolution'

const HowItWorksPage = () => {
  const { isMobile } = useResolution()
  return (
    <Box variant="layout.pageContainer">
      {/* {isMobile && <MobileReturnHeader title="How it works?" delta={-1} />}
      <TitleWithBack delta={-1} title="" /> */}
      <Card>
        <Heading>How it works : </Heading>
        <Box p={3} />
        {/* <Text>
          How does Pbc work? Pooling funds together with stakers for a fixed term allows the team of
          traders to better manage overall risk and turn profits. This benefits investors and the
          company in the long run as traders are able to make more strategic market decisions and
          hedges.
        </Text>
        <Box p={3} />
        <Text>
          Moreover, the introduction and initial token sale of the official Pbc token, $PBC, enables
          the team to further develop a suite of programs and improved tools that utilises $PBC
          throughout Pbc’s development plans. This also ensures a sustainable Pbc staking-reward
          ecosystem
        </Text> */}
        <Text>Coming Soon</Text>
      </Card>
    </Box>
  )
}

export default HowItWorksPage
