import { FC } from 'react'
import { Box } from 'theme-ui'

const ProgressBar: FC<{ value: number }> = ({ value }) => (
  <Box sx={{ width: '100%', height: 8, background: '#212760 0% 0% no-repeat padding-box', borderRadius: 20, p: 0 }}>
    <Box
      sx={{
        borderRadius: 20,
        width: `${value * 100}%`,
        height: '100%',
        background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
      }}
    ></Box>
  </Box>
)

export default ProgressBar
