/** @jsxImportSource theme-ui */
import { ResponsivePie } from '@nivo/pie'
import { useThemeUI } from 'theme-ui'
import { FC } from 'react'

const PieChart: FC<{ data: any[]; title: string }> = ({ data, title }) => {
  const { theme } = useThemeUI()

  const CenteredMetric = (props: any) => {
    const { centerX, centerY } = props

    return (
      <g>
        <text
          x={centerX}
          y={centerY - 10}
          textAnchor="middle"
          dominantBaseline="central"
          sx={{
            fontSize: [13, 20],
            fill: 'white',
            width: 10
          }}
        >
          {title} Months
        </text>
        <text
          x={centerX}
          y={centerY + 10}
          textAnchor="middle"
          dominantBaseline="central"
          sx={{
            fontSize: [13, 20],
            fill: 'white',
            width: 10
          }}
        >
          Subscriptions
        </text>
      </g>
    )
  }

  return (
    <>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.7}
        padAngle={0.7}
        borderWidth={1}
        borderColor={{
          from: 'color'
        }}
        enableArcLabels={false}
        arcLinkLabel={(d) => `${d.label} ${d.value}%`}
        arcLinkLabelsTextColor={theme.colors?.white?.toString()}
        arcLinkLabelsThickness={0}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabelsDiagonalLength={10}
        arcLabelsSkipAngle={10}
        arcLinkLabelsStraightLength={0}
        isInteractive={false}
        colors={{ datum: 'data.color' }}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      />
    </>
  )
}

export default PieChart
