import client, { IDataResponse } from '../request'

export type Pool = {
  id: string | number
  token_name: string
  token_type: string
  type: string
  blocked: boolean
  min_amount: number
  max_amount: number
  payout_day: number
  subscription_fee_type: number
  subscription_fee: number
  termination_fee_type: number
  termination_fee: number
  has_tenure_12: boolean
  has_tenure_24: boolean
  stake_rate_12: number
  stake_rate_s4: number
  nft_rate_12: number
  nft_rate_24: number
  vxt_rate_12: number
  vxt_rate_24: number
  incentive_rate_12: number
  incentive_rate_24: number
  reserve_rate_12: number
  reserve_rate_24: number
  team_rate_12: number
  team_rate_24: number
  burn_rate_12: number
  burn_rate_24: number
  // profit_rate_12: number
  // profit_rate_24: number
  quota_limit: number
  allow_terminate: boolean
  allow_subscribe: boolean
  pool_size: number
  max_pool_size?: number
  created_at: string
  updated_at: string
  apy: number
  earn_vxt_percentage: number
  link_nft_percentage: number
  total_participants_count: number
}

export type PoolProfit = {
  id: string | number
  profit_rate: number
  profit_value: number
  exchange_rate: number
  total_subscribe: number
  total_subscribe_12: number
  total_subscribe_24: number
  total_nft_subscribe: number
  total_nft_subscribe_12: number
  total_nft_subscribe_24: number
  total_incentive_subscribe: number
  total_incentive_subscribe_12: number
  total_incentive_subscribe_24: number
  // total_vxt_subscribe12: number
  // total_vxt_subscribe24: number
  // total_vxt_subscribe: number
  total_profit_12: number
  total_profit_24: number
  total_profit: number
  total_nft_12: number
  total_nft_24: number
  total_nft: number
  total_vxt_12: number
  total_vxt_24: number
  total_vxt: number
  total_incentive_12: number
  total_incentive_24: number
  total_incentive: number
  total_reserve_12: number
  total_reserve_24: number
  total_reserve: number
  total_team_12: number
  total_team_24: number
  total_team: number
  total_burn_12: number
  total_burn_24: number
  total_burn: number
  stake_rate_12: number
  stake_rate_24: number
  nft_rate_12: number
  nft_rate_24: number
  vxt_rate_12: number
  vxt_rate_24: number
  incentive_rate_12: number
  incentive_rate_24: number
  reserve_rate_12: number
  reserve_rate_24: number
  team_rate_12: number
  team_rate_24: number
  burn_rate_12: number
  burn_rate_24: number
  published: boolean
  distributed: boolean
  published_at: string
  distributed_at: string
  report_url: string
  token_type: string
  created_at: string
  updated_at: string
  poolId: number
  total_vxt_interest: number
  total_interest: number
}

// useSWR, return strings
const getAll = '/pools'

// useSWR, return strings
const get = (id: string | number) => {
  return `/pools/${id}`
}

// useSWR, return strings
const getProfits = (id: string | number) => {
  return `/pools/${id}/profits`
}

// axios
const create = (data: Pool) => {
  return client.post('/pools', data)
}

// axios
const update = (id: string | number, data: Pool) => {
  return client.put(`/pools/${id}`, data)
}

// processing
const toRow = (data: IDataResponse<Pool>): Pool[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<Pool>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// processing
const profitToRow = (data: IDataResponse<PoolProfit>): PoolProfit[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const profitToPaginate = (data?: IDataResponse<PoolProfit>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id: string | number) => {
  return client.delete(`/assets/${id}`)
}

const PoolService = {
  getAll,
  get,
  getProfits,
  create,
  update,
  toPaginate,
  toRow,
  remove,
  profitToPaginate,
  profitToRow
}

export default PoolService
