/** @jsxImportSource theme-ui */
import { FC, FormEvent, useEffect, useState } from 'react'
import { Box, Grid, Text, Card, Image, Flex, Button, Heading } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import useSWR from 'swr'
import { BsArrowRight } from 'react-icons/bs'
import { TiArrowUnsorted } from 'react-icons/ti'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useResolution } from 'hooks/useResolution'
import NetworkService, { NetworkState } from 'network/services/network'
import WalletService, { Wallet } from 'network/services/wallet'
import WithdrawService, { CreateWithdraw, WithdrawResponse } from 'network/services/withdraw'
import MobileReturnHeader from 'components/MobileReturnHeader'
import TitleWithBack from 'components/TitleWithBack'
import CustomModal from 'components/modal'
import FormInput from 'components/form-input'
import MoreInfo from 'components/more-info'
import { SelectTokenModal } from 'components/Wallet'
import SelectNetworkModal from 'components/Wallet/select-network-modal'
import TokenIcon from 'components/icons/token'
import LoadingCard from 'components/loading'
import StatusModal from 'components/status-modal'
import UserSecurityService, { SecurityCodeVerification } from 'network/services/user_security'
import ErrorCard from 'components/error'
import { UserSecurity } from 'network/services/user_security'
import { useNavigate } from 'react-router-dom'
import AssetService from 'network/services/asset'
import { IDataResponse, serialize } from 'network/request'
import { Asset } from 'network/services/asset'

const WalletWithdraw = () => {
  const { isMobile } = useResolution()

  const method = useForm<CreateWithdraw>({
    shouldUseNativeValidation: false
  })

  const [token, setToken] = useState<Asset | null>(null)
  const [network, setNetwork] = useState<NetworkState | null>(null)
  const [displaySuccess, setDisplaySuccess] = useState<boolean>(false)
  const [displayVerification, setDisplayVerification] = useState<boolean>(false)
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const [withdrawResult, setWithdrawResult] = useState<string>('')

  const { data: tokenData, error: tokenError } = useSWR(
    serialize(AssetService.getAll, { allow_withdraw: 1 })
  )

  const { data: networkData, error: networkError } = useSWR(
    token?.symbol ? NetworkService.getByToken(token.symbol) : null
  )

  const tokens: Asset[] = AssetService.toRow(tokenData)
  const networks: NetworkState[] = NetworkService.toRow(networkData).map((item) => item)

  const {
    data: walletData,
    mutate: walletMutate,
    error: walletError
  } = useSWR<{ data: Wallet }>(WalletService.getByTokenNetwork(token?.symbol, network?.name))

  //manually set the form value for token and network
  useEffect(() => {
    token?.symbol && method.setValue('token_type', token?.symbol)
    network?.name && method.setValue('network_type', network?.name)
  }, [token, network, method])

  const onSubmit = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    setDisplayVerification(true)
  }

  const submitWithdraw = method.handleSubmit(async (data) => {
    method.clearErrors()
    try {
      const { data: result } = await WithdrawService.create(data)
      if (result.success) {
        const withdrawResponse: WithdrawResponse = result.data
        setWithdrawResult(`${withdrawResponse.amount_requested} ${withdrawResponse.token_type}`)
        setDisplaySuccess(true)
        walletMutate()
      }
    } catch (e: any) {
      setMessage({
        success: false,
        isOpen: true,
        message: e.message
      })
    }
  })

  const onVerificationSuccess = () => {
    setDisplayVerification(false)
    submitWithdraw()
  }

  if (!tokenData) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  if (tokenError) {
    setMessage({
      success: false,
      isOpen: true,
      message: 'Unable to load tokens'
    })
  }

  if (networkError) {
    setMessage({
      success: false,
      isOpen: true,
      message: 'Unable to load networks'
    })
  }

  if (walletError) {
    setMessage({
      success: false,
      isOpen: true,
      message: 'Unable to laod wallet information'
    })
  }

  const wallet = walletData?.data

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Withdraw Token" delta={-1} />}
      <TitleWithBack backRef="/wallet" title="Withdraw Token" />
      <Box p={2} />
      <Box as="form" onSubmit={onSubmit}>
        <FormProvider {...method}>
          <SelectTokenNetwork
            token={token}
            network={network}
            setToken={setToken}
            setNetwork={setNetwork}
            tokens={tokens}
            networks={networks}
          />
          <Box p={4} />

          {wallet != null && !wallet?.wallet_address?.asset?.allow_withdraw ? (
            <>
              <Grid columns={[1, 2]} gap={[5, 15]} sx={{ alignItems: 'flex-end' }}>
                <Box sx={{ gridRow: [1, 1] }}>
                  <WithdrawBlocked />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              {/* <WithdrawTo /> */}
              <Box p={4} />
              <WithdrawAmount wallet={walletData?.data} decimal={token?.decimal} />
              <Box p={4} />
              <Grid columns={[1, 2]} gap={[5, 15]} sx={{ alignItems: 'flex-end' }}>
                <Box sx={{ gridRow: [2, 1] }}>
                  <WithdrawConfirm decimal={token?.decimal} />
                </Box>

                <Box sx={{ gridRow: [1, 1] }}>
                  <WithdrawGuide />
                </Box>
              </Grid>
            </>
          )}

          <CustomModal
            isOpen={displaySuccess}
            onRequestClose={() => {
              method.reset()
              setDisplaySuccess(false)
            }}
            overlayStyle={{
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Box>
              <Card>
                <Text>Withdrawal Submitted</Text>
                <Box pt={4} />
                <Text variant="small">You have successfully submitted withdrawal of</Text>
                <Box pt={1} />
                <Text>{withdrawResult}</Text>
                <Box pt={4} />
                <Text variant="small">
                  Note: Please allow up to 48 hours for withdrawals to be processed.
                </Text>
                <Box pt={4} />
                <Button
                  type="button"
                  onClick={() => {
                    method.reset()
                    setDisplaySuccess(false)
                  }}
                  sx={{ width: '90%' }}
                >
                  Close
                </Button>
              </Card>
            </Box>
          </CustomModal>

          <VerificationView
            onVerificationSuccess={onVerificationSuccess}
            isOpen={displayVerification}
            onRequestClose={() => setDisplayVerification(false)}
          />

          <StatusModal
            isOpen={message.isOpen}
            children={message.message}
            success={message.success}
            onRequestClose={() => setMessage({ isOpen: false })}
          />
        </FormProvider>
      </Box>
    </Box>
  )
}

const VerificationView: FC<{
  onVerificationSuccess: () => any
  onRequestClose: () => any
  isOpen: boolean
}> = ({ onVerificationSuccess, isOpen, onRequestClose }) => {
  const { data, error, mutate } = useSWR<{ data: UserSecurity }>(UserSecurityService.get)
  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <CustomModal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          overlayStyle={{
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Box>
            <ErrorCard message="Unable to retrieve security information" refresh={() => mutate()} />
          </Box>
        </CustomModal>
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <CustomModal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          overlayStyle={{
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <LoadingCard />
        </CustomModal>
      </Flex>
    )
  }

  const userSecurity = data.data
  return (
    // <Box>
    //   {userSecurity?.two_fa_enabled ? (
    //     <VerificationModal
    //       onVerificationSuccess={onVerificationSuccess}
    //       isOpen={isOpen}
    //       onRequestClose={onRequestClose}
    //     />
    //   ) : (
    //     <Enable2FAModal isOpen={isOpen} onRequestClose={onRequestClose} />
    //   )}
    // </Box>
    <ConfirmationModel
      onVerificationSuccess={onVerificationSuccess}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    />
  )
}

const VerificationModal: FC<{
  onVerificationSuccess: () => any
  onRequestClose: () => any
  isOpen: boolean
}> = ({ onVerificationSuccess, isOpen, onRequestClose }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateWithdraw>()

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box
        variant="layout.flexCenterCenter"
        sx={{
          minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
          py: [5, 16],
          position: 'relative'
        }}
      >
        <Card px={10} py={12}>
          <Box
            variant="layout.flexStartCenter"
            sx={{ minHeight: 'inherit', flexDirection: 'column' }}
          >
            <Heading sx={{ textAlign: 'left' }}>Security Verification</Heading>
            <Box pt={5} />
            <Text>To secure your account, please complete the following verification.</Text>
            <Box pt={5} />
            <Box>
              <Text>Google </Text>
              <Text>Authentication Code</Text>
            </Box>
            <Box pt={2} />
            <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
              <FormInput
                sx={{ my: 3, background: 'sidebarButtonBG' }}
                name="two_fa_code"
                register={register}
                rules={{ required: 'You must enter your authentication code.' }}
                errors={errors}
              />
            </Flex>
            <Box pt={5} />
            <Grid columns={2} gap={[1, 3]} sx={{ width: '100%' }}>
              <Button type="button" onClick={onRequestClose} sx={{ width: '100%' }}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => {
                  onVerificationSuccess()
                }}
                sx={{ width: '100%' }}
              >
                Next
              </Button>
            </Grid>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

const Enable2FAModal: FC<{ onRequestClose: () => any; isOpen: boolean }> = ({
  isOpen,
  onRequestClose
}) => {
  const navigate = useNavigate()

  const goTo2Fa = () => {
    onRequestClose()
    navigate('/security/2-factor-authentication')
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
      contentStyle={{
        height: '36%'
      }}
    >
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Card sx={{ p: 10 }}>
          <Box>
            <Text>Please Enable Google Authenticator before withdrawal</Text>
          </Box>
          <Box p={5} />
          <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
            <Button onClick={onRequestClose} sx={{ background: 'input' }}>
              Cancel
            </Button>
            <Button onClick={goTo2Fa}>Confirm</Button>
          </Flex>
        </Card>
      </Box>
    </CustomModal>
  )
}

const ConfirmationModel: FC<{
  onVerificationSuccess: () => any
  onRequestClose: () => any
  isOpen: boolean
}> = ({ onVerificationSuccess, isOpen, onRequestClose }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateWithdraw>()

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayStyle={{
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box
        variant="layout.flexCenterCenter"
        sx={{
          minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
          py: [5, 16],
          position: 'relative'
        }}
      >
        <Card px={10} py={12}>
          <Box
            variant="layout.flexStartCenter"
            sx={{ minHeight: 'inherit', flexDirection: 'column' }}
          >
            <Heading sx={{ textAlign: 'left' }}>Confirmation</Heading>
            <Box pt={5} />
            <Text>Are you confirm to withdraw?</Text>
            <Box pt={5} />
            <Box pt={2} />
            <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
              <FormInput
                sx={{ my: 3, background: 'sidebarButtonBG' }}
                name="two_fa_code"
                register={register}
                rules={{ required: 'You must enter your authentication code.' }}
                errors={errors}
                placeholder="Please input your trx password"
              />
            </Flex>
            <Box pt={5} />
            <Grid columns={2} gap={[1, 3]} sx={{ width: '100%' }}>
              <Button type="button" onClick={onRequestClose} sx={{ width: '100%' }}>
                No
              </Button>
              <Button
                type="button"
                onClick={() => {
                  onVerificationSuccess()
                }}
                sx={{ width: '100%' }}
              >
                Yes
              </Button>
            </Grid>
          </Box>
        </Card>
      </Box>
    </CustomModal>
  )
}

const SelectTokenNetwork: FC<{
  token?: Asset | null
  setToken: (token: Asset) => void
  network?: NetworkState | null
  setNetwork: (network: NetworkState | null) => void
  tokens: Asset[]
  networks: NetworkState[]
}> = ({ token, setToken, network, setNetwork, tokens, networks }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CreateWithdraw>()
  const [selectToken, setSelectToken] = useState<boolean>(false)
  const [selectNetwork, setSelectNetwork] = useState<boolean>(false)

  const openToken = () => {
    setSelectToken(true)
  }

  const closeToken = () => {
    setSelectToken(false)
  }

  const openNetwork = () => {
    setSelectNetwork(true)
  }

  const closeNetwork = () => {
    setSelectNetwork(false)
  }

  const onChangeToken = (token: Asset) => {
    setNetwork(null)
    setToken(token)
  }

  return (
    <Box>
      <Grid columns={[1, 2]} gap={[5, 15]}>
        <Box>
          <Text>Select Token</Text>
          <Box p={3} />
          <Flex variant="layout.vStack">
            <Text>Token</Text>
            <Box>
              <Card sx={{ height: 60, py: 0, cursor: 'pointer' }} onClick={openToken}>
                <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: '100%' }}>
                  {token ? (
                    <Flex>
                      <TokenIcon type={token.symbol} />
                      <Text sx={{ ml: 3 }}>{token.symbol}</Text>
                      <Text sx={{ ml: 3, color: 'textMuted' }}>{token.name}</Text>
                    </Flex>
                  ) : (
                    <Text>Please Select Token</Text>
                  )}
                  <TiArrowUnsorted />
                </Flex>
              </Card>
            </Box>
          </Flex>
          <FormInput
            register={register}
            errors={errors}
            name="token_type"
            rules={{ required: 'You must select a token type' }}
            containerSX={{ display: 'none' }}
          />
        </Box>

        <Box>
          <Text>Select Network</Text>
          <Box p={3} />
          <Flex variant="layout.vStack">
            <Text>Network</Text>
            <Box>
              {/* will change to pop up message ltr */}
              <Card sx={{ height: 60, py: 0, cursor: 'pointer' }} onClick={openNetwork}>
                <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: '100%' }}>
                  {network ? (
                    <Flex>
                      <Text>{network.name}</Text>
                    </Flex>
                  ) : (
                    <Text>Please Select Network</Text>
                  )}
                  <TiArrowUnsorted />
                </Flex>
              </Card>
            </Box>
          </Flex>
          <FormInput
            register={register}
            errors={errors}
            name="network_type"
            rules={{ required: 'You must select a network type' }}
            containerSX={{ display: 'none' }}
          />
        </Box>
      </Grid>

      <SelectTokenModal
        tokens={tokens}
        tokenSelected={token}
        isOpen={selectToken}
        setToken={onChangeToken}
        onRequestClose={closeToken}
        setIsOpen={setSelectToken}
      />

      <SelectNetworkModal
        networks={networks}
        networkSelected={network}
        isOpen={selectNetwork}
        setNetwork={setNetwork}
        onRequestClose={closeNetwork}
        setIsOpen={setSelectNetwork}
      />
    </Box>
  )
}

const WithdrawTo: FC = () => {
  const { isMobile } = useResolution()
  const {
    register,
    formState: { errors },
    setValue
  } = useFormContext<CreateWithdraw>()

  const handlePaste = async () => {
    const address = await navigator.clipboard.readText()
    setValue && setValue('recipient_addr', address)
  }

  return (
    <Box>
      <Text>Withdraw to</Text>
      <Box p={2} />
      <Card>
        <Flex variant="layout.flexCenterSpaceBetween">
          <Box sx={{ width: '100%', mx: 4 }}>
            <FormInput
              label="Address"
              name="recipient_addr"
              register={register}
              errors={errors}
              containerSX={{ background: 'none', pl: 0 }}
              mb={0}
              placeholder="Enter or paste the recipient address here"
              rules={{
                required: 'You must enter an address'
              }}
            />
          </Box>
          {!isMobile && (
            <Button variant="primaryFlexCenter" onClick={handlePaste} type="button">
              <Box>
                <Image src="/assets/svg/copy.svg" />
              </Box>
              <Box sx={{ ml: 3 }}>
                <Text>Paste</Text>
              </Box>
            </Button>
          )}
        </Flex>
      </Card>
      {isMobile && (
        <Flex sx={{ justifyContent: 'flex-end', pt: 3 }}>
          <Button variant="primaryFlexCenter" onClick={handlePaste} type="button">
            <Box>
              <Image src="/assets/svg/copy.svg" />
            </Box>
            <Box sx={{ ml: 3 }}>
              <Text>Paste</Text>
            </Box>
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const WithdrawAmount: FC<{
  wallet?: Wallet
  decimal?: number
}> = ({ wallet, decimal = 2 }) => {
  const {
    register,
    formState: { errors },
    setValue
  } = useFormContext<CreateWithdraw>()

  const handleMax = () => {
    if (wallet && setValue) {
      setValue('amount', wallet.balance)
    }
  }

  return (
    <Box>
      <Text>Withdraw amount</Text>
      <Box p={2} />
      <FormInput
        name="amount"
        register={register}
        errors={errors}
        placeholder="Minimum 10"
        type="number"
        containerSX={{ background: 'primaryCardBG' }}
        trailing={
          <Flex
            variant="layout.hStack"
            sx={{
              width: 'auto',
              mr: 2
            }}
          >
            <Box>
              <Text>{wallet?.token_type}</Text>
            </Box>
            <Box>
              <Text color="primary"> | </Text>
            </Box>
            <Box>
              <Button variant="text" color="primary" type="button" onClick={handleMax}>
                Max
              </Button>
            </Box>
          </Flex>
        }
      />

      <Box p={1} />
      <Text color="textMuted">
        Available Balance :{' '}
        {`${wallet?.balance?.toFixed(decimal) ?? ''} ${wallet?.token_type ?? ''}`}
      </Text>
    </Box>
  )
}

const WithdrawConfirm: FC<{
  decimal?: number
}> = ({ decimal = 2 }) => {
  const infoDirection = useResponsiveValue(['bottom', 'right'])

  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext<CreateWithdraw>()
  const amount = watch('amount')
  // TODO: get network fee from backend
  const networkFee = 0

  return (
    <Box>
      <Box>
        <Flex sx={{ flexDirection: 'column' }}>
          <Text>Receive Amount</Text>
          <Box p={1} />
          <Text variant="extraLarge" color="secondaryText">
            {amount > networkFee ? amount - networkFee : 0} USDT
          </Text>
          <Box p={1} />
        </Flex>
        <Flex>
          <Text color="textMuted">Network fee {networkFee} USDT</Text>
          <MoreInfo direction={infoDirection}>
            <WithdrawGuideOverlay />
          </MoreInfo>
        </Flex>
        <Box p={3} />
        <Button
          variant="primaryFlexCenter"
          sx={{ justifyContent: 'space-between', opacity: isSubmitting ? 0.5 : 1 }}
          type="submit"
          disabled={isSubmitting}
        >
          <Box />
          <Text>Withdraw</Text>
          <BsArrowRight size="1em" />
        </Button>
      </Box>
    </Box>
  )
}

const WithdrawGuide = () => {
  return (
    <Box>
      <Card
        sx={{
          p: 10,
          borderRadius: 20,
          display: 'flex',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Text>Remarks: </Text>
        <Box p={1} />
        <Text>1. Withdrawals to Pbc addresses will receive a refund for network fees. </Text>
        <Box p={2} />
        <Text>
          2. Do not withdraw directly to a crowdfund or ICO. We will not credit your account with
          tokens from that sale.
        </Text>
        <Box p={2} />
        <Text>3. All withdrawals are vetted and is processed within 48 hours.</Text>
      </Card>
    </Box>
  )
}

const WithdrawBlocked = () => {
  return (
    <Box>
      <Card
        sx={{
          p: 10,
          borderRadius: 20,
          display: 'flex',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Text>Remarks: </Text>
        <Box p={1} />
        <Text>Withdraws are being disabled at this moment</Text>
      </Card>
    </Box>
  )
}

const WithdrawGuideOverlay = () => {
  return (
    <Card variant="primarySmall" sx={{ width: 'max-content' }}>
      <Flex variant="layout.vStack">
        <Text color="textMuted" variant="small">
          Withdrawal fees are returned to Pbc deposit addresses.
        </Text>
        <Box>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text color="textMuted" variant="small">
              Fee will be returned to: Withdraw account
            </Text>
            <Text variant="small" color="primary" sx={{ cursor: 'pointer' }}>
              Change
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

export default WalletWithdraw
