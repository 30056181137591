import client, { IDataResponse } from '../request'
import toUTCTime from 'modules/to-utc-time'
import { User } from './auth'

export type Proposal = {
  id: string | number
  title: string
  description: string
  status: string
  has_voted: boolean
  can_vote: boolean
  votes: Vote[]
  me_votes: Vote[]
  votes_count: number
  yes_votes: number
  no_votes: number
  yes_percentage: number
  no_percentage: number
  expired_at: string
  created_at: string
  updated_at: string
  choices: string[]
  choices_count: number[]
  choices_percentage: number[]
}

export type Vote = {
  id: string | number
  proposal_id: string | number
  user_id: string | number
  answer: string
  txn_hash: string
  user: User
  created_at: string
  updated_at: string
}

export type CreateVote = {
  answer: string
}

const getAll = '/me/proposals'

const getAllVotes = (id: string | number) => {
  return `/me/proposals/${id}/votes`
}

const getProposal = (id: string | number) => {
  return `/me/proposals/${id}`
}

const createVote = (id: string | number, data: CreateVote) => {
  return client.post(`/me/proposals/${id}/votes`, data)
}

const votesToRow = (data: IDataResponse<Vote>): Vote[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

const votesToPaginate = (data?: IDataResponse<Vote>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const toRow = (data: IDataResponse<Proposal>): Proposal[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        created_at: toUTCTime(element.created_at),
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data?: IDataResponse<Proposal>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const ProposalService = {
  getAll,
  getAllVotes,
  getProposal,
  createVote,
  toRow,
  toPaginate,
  votesToRow,
  votesToPaginate
}

export default ProposalService
