import { useBreakpointIndex } from '@theme-ui/match-media'

export const useResolution = () => {
  // const windowDimensions = () => {
  //   const { innerWidth: width, innerHeight: height } = window
  //   return {
  //     width: width,
  //     heigth: height,
  //     isMobile: width < 500
  //   }
  // }
  // const [resolution, setResolution] = useState(windowDimensions())

  // useEffect(() => {
  //   const handleResize = () => {
  //     setResolution(windowDimensions())
  //   }
  //   window.addEventListener('resize', handleResize)

  //   return () => window.removeEventListener('resize', handleResize)
  // }, [])

  // return resolution
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 0 })
  const isMobile = breakpointIndex === 0

  return { isMobile }
}
