/** @jsxImportSource theme-ui */
import { FC, useState } from 'react'
import { Box } from 'theme-ui'

export type HorizontalScrollerProps = {
  children?: JSX.Element
  onScroll?: (scrollPercentage: number) => any
  breakpoint?: number
  breakpointEnabled?: boolean
}

const HorizontalScroller: FC<HorizontalScrollerProps> = ({
  children,
  onScroll: handleScroll = (scrollPercentage) => {},
  breakpoint = 0.5,
  breakpointEnabled = false
}) => {
  const [currentSection, setCurrentSection] = useState(0)
  const [previousScrollLocation, setPreviousScrollLoation] = useState(0)

  const onScrollEnd = (e: React.UIEvent) => {
    const target = e.currentTarget
    const currentScrollLocation = target.scrollLeft
    const maxScroll = target.scrollWidth - target.clientWidth

    if (breakpointEnabled) {
      if (
        currentScrollLocation > previousScrollLocation &&
        currentScrollLocation >= maxScroll * breakpoint
      ) {
        target.scrollTo({
          left: maxScroll,
          behavior: 'smooth'
        })
        setCurrentSection(1)
      } else if (
        currentScrollLocation < previousScrollLocation &&
        currentScrollLocation < maxScroll * breakpoint
      ) {
        target.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        setCurrentSection(0)
      } else {
        target.scrollTo({
          left: currentSection * maxScroll,
          behavior: 'smooth'
        })
      }
    }
  }

  const onScroll = (e: React.UIEvent) => {
    const target = e.currentTarget
    const currentScrollLocation = target.scrollLeft
    const maxScroll = target.scrollWidth - target.clientWidth
    handleScroll(currentScrollLocation / maxScroll)
  }

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'scroll',
        scrollbarWidth: ['auto', 'none'], //for firefox
        '::-webkit-scrollbar': {
          display: ['auto', 'none']
        }
      }}
      onScroll={onScroll}
      onTouchEnd={onScrollEnd}
      onTouchStart={(e) => setPreviousScrollLoation(e.currentTarget.scrollLeft)}
    >
      {children}
    </Box>
  )
}

export default HorizontalScroller
