import { FC, FormEvent, ReactNode, useRef } from 'react'
import { Button, Card, Flex, Input, Text, Box, ThemeUICSSProperties, Image } from 'theme-ui'

const UploadButton: FC<{
  onChange?: (files: FileList | null) => any
  name?: string
  sx?: ThemeUICSSProperties
  children?: ReactNode
}> = ({
  name = '',
  sx = {},
  onChange: changeHandler = (files: FileList | null) => {},
  children
}) => {
  const uploadRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (changeHandler != null) {
      changeHandler(e.currentTarget.files ?? null)
    }
  }

  const handleUpload = () => {
    uploadRef.current?.click()
  }

  return (
    <Card variant="upload" sx={{ ...sx, cursor: 'pointer' }} onClick={handleUpload}>
      <Input
        name={name}
        type="file"
        sx={{ display: 'none' }}
        ref={uploadRef}
        onChange={handleChange}
      />
      <Flex variant="layout.flexCenterStart">
        <Box>
          <Button variant="round" sx={{ p: 1 }} type="button">
            <Image src="/assets/svg/paper-clip.svg" />
          </Button>
        </Box>
        <Box p={3} />
        {children ? children : <Text color="textMuted">Upload an attachment</Text>}
      </Flex>
    </Card>
  )
}

export default UploadButton
