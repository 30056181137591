/** @jsxImportSource theme-ui */
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Card, Flex, Text, Grid, Image, Button, Divider, Paragraph } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import { BsArrowRight } from 'react-icons/bs'
import useSWR from 'swr'
import _ from 'lodash'

import { useResolution } from 'hooks/useResolution'
import StakeService, { Stake } from 'network/services/stake'
import NFTService, { NFT } from 'network/services/nft'
import Table from 'components/Table'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'
import CustomModal from 'components/modal'
import TokenIcon from 'components/icons/token'
import Link from 'components/link'
import ErrorCard from 'components/error'
import { IDataResponse, serialize } from 'network/request'
import toUTCTime from 'modules/to-utc-time'
import LoadingCard from 'components/loading'
import StatusModal from 'components/status-modal'
import MoreInfo from 'components/more-info'
import { NFTSelectionCard } from 'components/Stake'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const StakeMyContract = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="View My Contract" delta={-1} />}
      <TitleWithBack backRef="/stake" title="View My Contract" />
      <Box p={2} />
      <PageView />
    </Box>
  )
}

const PageView: FC = () => {
  const { id } = useParams()
  const { data, error, mutate } = useSWR<Stake>(StakeService.get(id!))
  const [message, setMessage] = useState<{ isOpen: boolean; message?: string; success?: boolean }>({
    isOpen: false,
    success: true,
    message: ''
  })
  const [nftSelected, setnftSelected] = useState<string | number | null>(null)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve contract details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const refresh = () => {
    mutate()
  }

  const selectNFT = (data: number | string | null) => {
    setnftSelected(data)
  }

  const unlinkNFT = async () => {
    const value = { stake_id: data.id, nft_id: data.nft_id }
    try {
      const { data: response } = await StakeService.unlinkNFT(value)
      if (response.success) {
        refresh()
        setMessage({
          isOpen: true,
          message: 'Unlink NFT Succesfully'
        })
      }
    } catch (error: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: error.message
      })
    }
  }

  const linkNFTConfirm = async () => {
    const value = { stake_id: data.id, nft_id: nftSelected }
    try {
      const { data: response } = await StakeService.linkNFT(value)
      if (response.success) {
        setMessage({
          isOpen: true,
          message: 'Link NFT Succesfully'
        })
        refresh()
      }
    } catch (error: any) {
      setMessage({
        isOpen: true,
        success: false,
        message: error.message
      })
    }
  }

  return (
    <>
      <PoolDetail />
      <Earning />
      {data?.nft_id != null ? (
        <NFTCard nft_id={data.nft_id} unlinkNFT={unlinkNFT} />
      ) : (
        <LinkNFT selectNFT={selectNFT} nftSelected={nftSelected} linkNFTConfirm={linkNFTConfirm} />
      )}
      <Box p={3} />
      <ContractDetail />
      <Box p={4} />
      <TransactionHistory />
      <StatusModal
        isOpen={message.isOpen}
        onRequestClose={() => setMessage({ isOpen: false })}
        success={message.success}
        children={message.message}
      />
    </>
  )
}

const PoolDetail = () => {
  const { id } = useParams()
  const { isMobile } = useResolution()
  const { data: stake } = useSWR<Stake>(StakeService.get(id!))

  return (
    <Box>
      <Card>
        {isMobile ? (
          <Flex variant="layout.vStack">
            <Box sx={{ width: 50, display: 'inline-flex', svg: { width: '100%', height: '100%' } }}>
              <TokenIcon type={stake!.token_type} />
            </Box>
            <Box>
              <Flex variant="layout.flexCenterSpaceBetween">
                <Box>
                  <Text variant="mediumSmall">{_.startCase(stake!.type)} Pool</Text>
                  <Flex variant="layout.hStack">
                    <Text variant="large">{stake!.token_type}</Text>
                    <Box>
                      <Text variant="small" color="textMuted">
                        {stake!.token_name}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                <Flex>
                  <Box sx={{ textAlign: 'right' }}>
                    <Grid>
                      <Text variant="large">
                        {stake!.stake_rate}%
                        {(stake!.incentive_rate ?? 0) > 0 &&
                          stake!.earn_vxt === true &&
                          `+ ${stake!.incentive_rate}%`}
                        {(stake!.nft_rate ?? 0) > 0 &&
                          stake!.link_nft === true &&
                          `+ ${stake!.nft_rate}%`}
                      </Text>
                      <Text variant="small" color="textMuted">
                        of Staked Base Profit Share
                      </Text>
                    </Grid>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        ) : (
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Flex variant="layout.hStack">
              <Box
                sx={{ width: 50, display: 'inline-flex', svg: { width: '100%', height: '100%' } }}
              >
                <TokenIcon type={stake!.token_type} />
              </Box>
              <Box>
                <Text variant="mediumSmall">{_.startCase(stake!.type)} Pool</Text>
                <Flex variant="layout.hStack">
                  <Text variant="large">{stake!.token_type}</Text>
                  <Box>
                    <Text variant="small" color="textMuted">
                      {stake!.token_name}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Flex>
              <Box sx={{ textAlign: 'right' }}>
                <Grid>
                  <Text variant="large">
                    {stake!.stake_rate}%
                    {(stake!.incentive_rate ?? 0) > 0 &&
                      stake!.earn_vxt === true &&
                      ` + ${stake!.incentive_rate}%`}
                    {(stake!.nft_rate ?? 0) > 0 &&
                      stake!.link_nft === true &&
                      ` + ${stake!.nft_rate}%`}
                  </Text>
                  <Text variant="small" color="textMuted">
                    of Staked Base Profit Share
                  </Text>
                </Grid>
              </Box>
            </Flex>
          </Flex>
        )}
      </Card>
    </Box>
  )
}

const Earning = () => {
  const { id } = useParams()
  const { data: stake } = useSWR<Stake>(StakeService.get(id!))
  const variant = useResponsiveValue(['layout.vStack', 'layout.hStack'])

  return (
    <Box>
      <Flex
        variant={variant}
        sx={{
          '> * + *': {
            ml: [0, 8],
            mt: [4, 0]
          }
        }}
        pt={stake!.earn_vxt || stake!.link_nft ? 6 : 0}
      >
        {stake!.earn_vxt && (
          <Flex variant="layout.hStack">
            <Box variant="layout.iconContainer">
              <Image src={'/assets/svg/VXT-icon.svg'} />
            </Box>
            <Box>
              <Text>You are earning in VXT</Text>
            </Box>
          </Flex>
        )}
        {stake!.link_nft && (
          <Flex variant="layout.hStack">
            <Box variant="layout.iconContainer">
              <Image src={'/assets/svg/blockchain.svg'} />
            </Box>
            <Box>
              <Text>You have an NFT linked</Text>
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

const NFTCard: FC<{ nft_id: number; unlinkNFT: () => void }> = ({ nft_id, unlinkNFT }) => {
  const { data, error, mutate } = useSWR<{ data: NFT }>(serialize(NFTService.getMyNFT(nft_id)))
  const [unlinkOpen, setUnlinkOpen] = useState<boolean>(false)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve nft details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const openModal = () => {
    setUnlinkOpen(true)
  }

  const closeModal = () => {
    setUnlinkOpen(false)
  }

  const unlinkConfirm = () => {
    setUnlinkOpen(false)
    unlinkNFT()
  }

  const nft = data.data

  return (
    <Box pt={6}>
      <Card>
        <Flex sx={{ flexDirection: ['column', 'row'] }}>
          <Image
            sx={{ width: [100, 150] }}
            src={nft.type === 'nft' ? nft.image_url : '/blind_box.jpg'}
          />
          <Flex sx={{ px: [0, 10], mt: [10, 0], flexDirection: 'column', alignSelf: 'center' }}>
            <Text>{nft.name}</Text>
            <Box p={3} />
            <Grid columns={2}>
              <Link to={`/nfts/${nft.id}`}>
                <Button sx={{ background: 'input' }}>View</Button>
              </Link>
              <Button onClick={openModal}>
                <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
                  <Text>Unlink</Text>
                  <BsArrowRight />
                </Flex>
              </Button>
            </Grid>
          </Flex>
        </Flex>
      </Card>

      <CustomModal
        isOpen={unlinkOpen}
        onRequestClose={closeModal}
        overlayStyle={{
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Card>
            <Flex variant="layout.vStack">
              <Box>
                <Text>Unlink NFT</Text>
              </Box>
              <Box>
                <Paragraph>
                  You will not be eligible for NFT holder profit share distribution, unless it is
                  linked to the pool before the end of the month. Are you sure you want to unlink
                  this NFT?
                </Paragraph>
              </Box>
              <Box>
                <Flex variant="layout.hStack" sx={{ justifyContent: 'center' }}>
                  <Button onClick={closeModal} sx={{ background: 'input' }}>
                    Cancel
                  </Button>
                  <Button onClick={() => unlinkConfirm()}>Confirm</Button>
                </Flex>
              </Box>
            </Flex>
          </Card>
        </Box>
      </CustomModal>
    </Box>
  )
}

const ContractDetail = () => {
  const { id } = useParams()
  const { data: stake } = useSWR<Stake>(StakeService.get(id!))

  return (
    <Box>
      <Card sx={{ p: 0, borderRadius: 20 }} variant="mixedColor">
        <Card sx={{ p: 0, borderRadius: 20 }}>
          <Grid sx={{ py: 5, px: 6 }} columns={[1, 2]} gap={[2, 3]}>
            <Text variant="mediumSmall" color="textMuted">
              Staked Amount
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {stake!.principal_amount} {stake!.token_type}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Terms (Months)
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {stake!.tenure}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Base Profit Share
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {stake!.stake_rate}%
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Pool Incentive Profit Share
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {`${stake?.earn_vxt === true ? stake!.incentive_rate : 0}%`}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              NFT Holder Profit Share
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {`${stake?.link_nft === true ? stake!.nft_rate : 0}%`}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Earned (VXT)
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {stake!.total_vxt_profit} VXT
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Earned ({stake!.token_type})
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {stake!.total_profit} {stake!.token_type}
            </Text>
          </Grid>
          <Divider />
          <Grid sx={{ py: 5, px: 6 }} columns={[1, 2]} gap={[2, 3]}>
            <Text variant="mediumSmall" color="textMuted">
              Staked Date (Deposited At)
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {/* {DateTime.fromISO(stake!.deposited_at).toFormat('MMM dd, yyyy')} */}
              {stake?.deposited_at ? toUTCTime(stake!.deposited_at) : '-'}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Value Date (Started At)
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {/* {DateTime.fromISO(stake!.started_at).toFormat('MMM dd, yyyy')} */}
              {stake?.started_at ? toUTCTime(stake!.started_at) : '-'}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Redemption Date (Matured At)
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {/* {DateTime.fromISO(stake!.matured_at).toFormat('MMM dd, yyyy')} */}
              {stake?.matured_at ? toUTCTime(stake!.matured_at) : '-'}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              First Payout Date
            </Text>
            {/* TODO, UTC format or just show date? */}
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {/* {DateTime.fromISO(stake!.first_payout_at).toFormat('MMM dd, yyyy')} */}
              {stake?.first_payout_at ? toUTCTime(stake!.first_payout_at) : '-'}
            </Text>
            <Text variant="mediumSmall" color="textMuted">
              Last Payout Date
            </Text>
            <Text variant="mediumSmall" sx={{ textAlign: [null, 'right'] }}>
              {/* {DateTime.fromISO(stake!.last_payout_at).toFormat('MMM dd, yyyy')} */}
              {stake?.last_payout_at ? toUTCTime(stake!.last_payout_at) : '-'}
            </Text>
          </Grid>
        </Card>
        <Link to={`/stake/pools/${id}/how`}>
          <Button variant="text" sx={{ width: '100%', py: 6, color: 'buttonText' }}>
            How Profit Distribution Works?
          </Button>
        </Link>
      </Card>
    </Box>
  )
}

const TransactionHistory: FC = () => {
  const { id } = useParams()
  const limit = 10
  const [page, setPage] = useState(1)
  const { data, error, mutate } = useSWR(
    serialize(StakeService.getTransactions(id!), {
      page: page,
      limit: limit,
      sort: 'created_at:desc'
    })
  )
  const total = StakeService.transactionToPaginate(data).total

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve transaction history" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const tableData = StakeService.transactionToRow(data).map((item) => {
    return {
      created_at: toUTCTime(item.created_at),
      type: item.txn_type.charAt(0).toUpperCase() + item.txn_type.slice(1),
      amount: `${Math.abs(item.amount)} ${item.wallet.token_type}`,
      //TODO: not sure what destination refers to
      destination: item.amount > 0 ? 'Wallet' : 'Contract'
    }
  })

  return (
    <Box>
      <Text>Transaction History</Text>
      <Box pt={3} />
      <Table
        headers={['Date & Time', 'Type', 'Amount', 'Destination']}
        data={tableData}
        page={page}
        onPageChange={setPage}
        limit={limit}
        total={total}
      />
    </Box>
  )
}

const LinkNFT: FC<{
  selectNFT: (data: string | number | null) => void
  nftSelected: string | number | null | undefined
  linkNFTConfirm: () => void
}> = ({ selectNFT, nftSelected, linkNFTConfirm }) => {
  const [linkOpen, setLinkOpen] = useState<boolean>(false)

  const unlinkNFT = () => {
    selectNFT(null)
  }

  const openLink = () => {
    if (nftSelected != null) {
      setLinkOpen(true)
    }
  }

  const closeLink = () => {
    setLinkOpen(false)
  }

  return (
    <Box pt={5}>
      <Flex sx={{ flexDirection: ['column', 'row'] }}>
        <Grid sx={{ flex: 2 }}>
          <Flex>
            <Text>Link a Pbc NFT</Text>
            <MoreInfo />
          </Flex>
          <NFTTotal />
        </Grid>
        <Flex variant="layout.hStack" sx={{ pt: [3, 0] }}>
          <AnimatePresence exitBeforeEnter>
            {nftSelected && (
              <AnimatedBox
                key="cancel_modal"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <Button type="button" variant="secondary" onClick={unlinkNFT}>
                  Cancel
                </Button>
              </AnimatedBox>
            )}
            {nftSelected && (
              <AnimatedBox
                key="open_modal"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <Button variant="primaryFlexCenter" type="button" onClick={openLink}>
                  <Box sx={{ flex: 1 }} />
                  <Text>Confirm</Text>
                  <BsArrowRight sx={{ flex: 1, ml: 2 }} />
                </Button>
              </AnimatedBox>
            )}
          </AnimatePresence>
        </Flex>
      </Flex>

      <Box p={3} />

      <Avatars nftSelected={nftSelected} selectNFT={selectNFT} />

      <Box p={3} />

      <Potions nftSelected={nftSelected} selectNFT={selectNFT} />

      <CustomModal
        isOpen={linkOpen}
        onRequestClose={closeLink}
        contentStyle={{
          height: '60%'
        }}
      >
        <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Card sx={{ p: [null, 10] }}>
            <Box>
              <Text>Link NFT</Text>
            </Box>
            <Box p={3} />
            <Paragraph>
              Linking an NFT to a pool allows you to earn additional profit share in VXT. You can
              unlink an NFT at any time, however it must be linked to the pool before the end of
              each month to be eligible for upcoming NFT holder profit distribution.
            </Paragraph>
            <Box p={3} />
            <Grid columns={2}>
              <Button
                type="button"
                onClick={() => {
                  unlinkNFT()
                  closeLink()
                }}
                sx={{ background: 'input' }}
              >
                Cancel
              </Button>
              <Button
                variant="primaryFlexCenter"
                type="button"
                onClick={() => {
                  closeLink()
                  linkNFTConfirm()
                }}
              >
                <Box sx={{ flex: 1 }} />
                <Text>Confirm</Text>
                <BsArrowRight sx={{ flex: 1, ml: 2 }} />
              </Button>
            </Grid>
          </Card>
        </Box>
      </CustomModal>
    </Box>
  )
}

const NFTTotal = () => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, { linked: false })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Error while retriving NFT details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nfts = NFTService.toPaginate(data)

  return (
    <Text sx={{ color: 'textMuted' }} variant="small">
      You have {nfts.total} NFTs suitable for this pool
    </Text>
  )
}

const Avatars: FC<{
  selectNFT: (data: string | number | null) => void
  nftSelected: string | number | null | undefined
}> = ({ selectNFT, nftSelected }) => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, { linked: false })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve avatars" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const nfts = NFTService.toRow(data)
  const avatars = nfts.filter((nft) => nft.type === 'avatar')

  return (
    <Box sx={{ display: avatars.length > 0 ? 'block' : 'none' }}>
      <Text variant="extraLarge">Avatar</Text>
      <Box p={1} />
      <Grid columns={[1, 3, 4]}>
        {avatars.map((nft) => (
          <NFTSelectionCard
            key={nft.id}
            NFT={nft}
            handleSelect={(id) => {
              selectNFT(id)
            }}
            isSelected={nftSelected === nft.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

const Potions: FC<{
  selectNFT: (data: string | number | null) => void
  nftSelected: string | number | null | undefined
}> = ({ selectNFT, nftSelected }) => {
  const { data, error, mutate } = useSWR<IDataResponse<NFT>>(
    serialize(NFTService.getMyNFTs, { linked: false })
  )

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve potions" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }
  const nfts = NFTService.toRow(data)
  const potions = nfts.filter((nft) => nft.type === 'potion')

  return (
    <Box sx={{ display: potions.length > 0 ? 'block' : 'none' }}>
      <Text variant="extraLarge">Potion</Text>
      <Box p={1} />
      <Grid columns={[1, 3, 4]}>
        {potions.map((nft) => (
          <NFTSelectionCard
            key={nft.id}
            NFT={nft}
            handleSelect={(id) => {
              selectNFT(id)
            }}
            isSelected={nftSelected === nft.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default StakeMyContract
