import Tab from 'components/Tab'
import { Box } from 'theme-ui'
import DepositRequest from './deposit-request'
import { useState } from 'react'
import TransactionHistory from './transaction-history'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const NFTDeposit = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <Box>
      <Tab
        defaultTab={currentTab}
        tabs={['Deposit Request', 'Transaction History']}
        onTabChange={setCurrentTab}
      />
      <Box p={3} />

      <AnimatePresence exitBeforeEnter>
        {
          {
            0: (
              <AnimatedBox
                key="deposit"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <DepositRequest />
              </AnimatedBox>
            ),
            1: (
              <AnimatedBox
                key="history"
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnim}
              >
                <TransactionHistory />
              </AnimatedBox>
            )
          }[currentTab]
        }
      </AnimatePresence>
    </Box>
  )
}

export default NFTDeposit
