import client, { IDataResponse, serialize } from '../request'
import { Asset } from './asset'

export type WalletAddress = {
  id: number | string
  type: number
  token_type: string
  network_type: string
  address: string
  blocked: boolean
  asset_id?: number | string
  user_id: number | string
  wallet_id: number | string
  created_at: string
  updated_at: string
  asset: Asset
}

export type Wallet = {
  id: string | number
  type: number
  token_type: string
  blocked: boolean
  user_id: string | number
  created_at: string
  updated_at: string
  wallet_address?: WalletAddress
  balance: number
}

export type CreateWallet = {
  token_type: string
  network_type: string
}

// useSWR, return strings
const getAll = '/me/wallets'

// useSWR, return strings
const get = (id: string | number) => {
  return `/me/wallets/${id}`
}

// useSWR, return strings
const getByToken = (token_type: string) => {
  return `/me/wallets/by-token/${token_type}`
}

// useSWR, return strings
const getByTokenNetwork = (token_type?: string, network_type?: string) => {
  if (token_type && network_type) {
    return serialize('me/wallets/by-token-network', {
      token_type: token_type,
      network_type: network_type
    })
  }
}

// axios
const create = (data: CreateWallet) => {
  return client.post('/me/wallets', data)
}

// processing
const toRow = (data: IDataResponse<Wallet>): Wallet[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<Wallet>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id: string | number) => {
  return client.delete(`/me/wallets/${id}`)
}

const WalletService = {
  getAll,
  get,
  getByToken,
  getByTokenNetwork,
  create,
  toPaginate,
  toRow,
  remove
}

export default WalletService
