/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Text, Flex, Box } from 'theme-ui'

import HorizontalScroller from 'layout/horizontal-scroller'
import LoadingCard from 'components/loading'
import PageControl from 'components/page-control'

const Table: FC<{
  data?: any[]
  headers?: string[]
  limit?: number
  total?: number
  width?: number | string
  breakpointOffset?: number
  displayPage?: boolean
  onPageChange?: (page: number) => any
  page?: number
}> = ({
  data,
  headers = [],
  width = '100%',
  limit = 10,
  displayPage = true,
  onPageChange = (page: number) => {},
  total = 1,
  page = 1
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <HorizontalScroller>
        <table style={{ width: width }} cellSpacing={0}>
          <thead>
            <tr
              key="table-header"
              sx={{
                background: 'darkBG',
                margin: 0
              }}
            >
              {headers?.map((item, index) => (
                <th
                  sx={
                    index === 0
                      ? { borderRadius: '10px 0 0 10px' }
                      : index === headers.length - 1
                      ? { borderRadius: '0 10px 10px 0' }
                      : {}
                  }
                  key={index}
                >
                  <Flex sx={{ py: 5, pl: 10, pr: index === headers.length - 1 ? 10 : 0 }}>
                    <Text sx={{ fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2 }}>
                      {item}
                    </Text>
                  </Flex>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr key={item.key ?? index}>
                  {Object.keys(item).map(
                    (key) =>
                      key !== 'key' && (
                        <td
                          sx={{
                            py: 8,
                            borderWidth: '0 0 2px 0',
                            borderColor: 'darkBG',
                            borderStyle: 'solid'
                          }}
                        >
                          <Flex
                            sx={{
                              py: 1,
                              pl: 10
                            }}
                          >
                            {typeof item[key] === 'string' || typeof item[key] === 'number' ? (
                              <Text sx={{ flex: 1 }}>{item[key]}</Text>
                            ) : (
                              item[key]
                            )}
                          </Flex>
                        </td>
                      )
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </HorizontalScroller>

      {!data && (
        <Flex variant="layout.flexCenterCenter">
          <LoadingCard />
        </Flex>
      )}

      <Box p={2} />

      {data?.length === 0 && (
        <Flex variant="layout.flexCenterCenter">
          <Text>No records found</Text>
        </Flex>
      )}

      {displayPage && data && total > 0 && (
        <PageControl page={page} total={total} limit={limit} setPage={onPageChange} />
      )}
    </Box>
  )
}

export default Table
