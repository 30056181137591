import { Box, Card, Heading, Text } from 'theme-ui'

const PrivacyPolicyPage = () => {
  return (
    <Box
      variant="layout.newTabContainer"
      sx={{
        minHeight: ['calc(30vh - 51px - 144px)', 'inherit'],
        py: 5,
        position: 'relative'
      }}
    >
      <Card>
        <Box sx={{ textAlign: 'center' }}>
          <Heading>PBC Privacy Policy and Data Protection</Heading>
        </Box>
        <Box p={[1, 10]}>
          <Text>
            This privacy notice (Privacy Statement) applies to Personal Information or Data held by
            VOXFI Ltd, the owner and creator of PBC Website and Platform (“PBC”) or/and our partners
            or the organization responsible for personal information under our control, as described
            below. It explains what information we collect about you, how we’ll use that
            information, who we’ll share it with, the circumstances when we’ll share it and what
            steps we’ll take to make sure it stays private and secure. It continues to apply even if
            your use of our services comes to an end.
          </Text>
          <Box p={2} />
          <Text>
            The information we collect from you (as described below) is required to provide you with
            access to our services and to enable us to fulfil our legal and regulatory compliances.
            Failure to provide your information, (or if all personal data submitted to us is not
            complete, accurate, true and correct) may mean it is not possible to provide you with
            access to certain products or services which you or the entity you are acting on behalf
            of have requested.
          </Text>
          <Box p={2} />
          <Text>
            By accessing and using our website and the associated application program used in
            various devices such as desktop, laptop and mobile you indicate acceptance to the terms
            of this Privacy Policy.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Collection of Information
          </Heading>
          <Text>
            PBC can collect, process and store Personal Data collected through various sources such
            as website, software, mobile application and other applicable technologies in process of
            using our services from you will be regarded as given consent. We’ll only collect your
            information in line with relevant regulations and laws of the land. The information we
            collect may include: -
          </Text>
          <ol>
            <li>
              <Text>
                Personal Identification Information: Full name, date of birth, nationality, gender,
                signature, utility bills, photographs, phone number, home address, and/or email.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Formal Identification Information: Government issued identity document such as
                Passport, Driver's License, National Identity Card, Visa information, and/or any
                other information deemed necessary to comply with our legal obligations under
                financial or anti-money laundering laws.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Institutional Information: Employer Identification number (or comparable number
                issued by a government), proof of legal formation (e.g. Articles of Incorporation),
                personal identification information for all material beneficial owners.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Financial Information: Bank account information, payment card primary account number
                (PAN), transaction history, and/or tax identification.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Employment Information: Office location, job title, and/or description of role.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Other information about you that you give us by filling in forms, surveys, or by
                communicating with us, whether face-to-face, by phone, email, online, or otherwise.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Cookies and similar technologies we use to recognize you, remember your preferences
                and tailor the content we provide to you.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Investigations data, e.g. due diligence checks, sanctions and anti-money laundering
                checks, external intelligence reports, content and metadata related to relevant
                exchanges of information between and among individuals, organizations, including
                emails, voicemail, live chat;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Records of correspondence and other communications between us, including email,
                telephone conversations, live chat, instant messages and social media
                communications;
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Information that we need to support our regulatory obligations, e.g. information
                about transaction details, purpose of payment, ordering Client information,
                counterparty/beneficiary information, identification documents, detection of any
                suspicious and unusual activity and information about parties connected to you or
                these activities.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Information from third party providers, e.g. client user information collected
                through 3rd party application service providers that we have a mutual relationship
                with and you have separately agreed with can share information with.
              </Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            How PBC Use User’s Information
          </Heading>
          <Text>
            Our primary purpose in collecting personal information is to provide you a secure,
            smooth, efficient, and customized experience based on your individual usage habits,
            improve our marketing and promotional efforts, analyze site usage and disclose
            aggregated statistics, improve our content and service offerings, aid in our
            monetization efforts and customize our Platform’s content, layout and services. We’ll
            only use your information where we have your consent or we have another lawful reason
            for using it. We may use the information in the following ways: -
          </Text>
          <ol>
            <li>
              <Text>
                Legal and Regulatory compliance: The services provided by PBC are subjects to legal
                and regulatory compliances which require us to collect, use, and store your personal
                information. This may include using it to help, detect or prevent crime (including
                terrorism financing, money laundering and other financial crimes). We’ll only do
                this on the basis that it’s needed to comply with a legal obligation or it’s in our
                legitimate interests and that of others.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Enforcement of terms of PBC: PBC is handling sensitive information of the Client and
                therefore it is highly necessary to monitor, investigate, prevent and mitigate any
                potentially prohibited or illegal activities with third parties and/or prevent and
                detect violations of any of our terms and conditions for other Services. In
                addition, we may need to collect fees based on your use of our Services. We collect
                information about your account usage and closely monitor your interactions with our
                Services. We may use any of your personal information collected for these purposes.
                The consequences of not processing your personal information for such purposes is
                the termination of your account.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                To prevent fraudulent activities: We will use your personal information to detect,
                prevent and mitigate fraud and abuse of our services and to protect you against
                account compromise or funds loss.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                We process your personal information when you contact us to resolve any questions,
                disputes, collect fees or to troubleshoot problems. We also process it for quality
                control and staff training to make sure we continue to provide you with accurate
                information.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                To provide PBC Services: We process your personal information to provide you our
                Services. Third parties such as identity verification service providers may also
                access and/or collect your personal information when providing identity verification
                and/or fraud prevention services. As a part of our Services, we send administrative
                or account-related information to you to keep you updated about our Services, inform
                you of relevant security issues or updates, or provide other transaction-related
                information. Without such communications, you may not be aware of important
                developments relating to your account that may affect how you can use our Services.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                We process your information to enhance security, monitor and verify identity or
                service access, combat spam or other malware or security risks and to comply with
                applicable security laws and regulations.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                We process your personal information to better understand the way you use and
                interact with our Services. In addition, we use such information to customize,
                measure and improve our Services and the content and layout of our website and
                applications and to develop new services.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                Based on your usage of our Services, we may send you marketing communications via,
                SMS, emails etc. to inform you about our events or our partner events, to deliver
                targeted marketing and to provide you with promotional offers. Our marketing will be
                conducted in accordance with your advertising marketing preferences and as permitted
                by applicable law.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                We may share your information with third party partners or third parties hired by us
                to perform functions and provide services to us subject to confidentiality
                obligations consistent with this Privacy Policy and on the condition that the third
                parties use your information only on our behalf and pursuant to our instructions.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                We may disclose any information in response to a legal request such as a subpoena,
                court order, or government demand, to investigate or report illegal activity, or to
                enforce our rights or defend claims. We may also transfer your information to
                another company in connection with a partnership or a corporate restructuring, such
                as a sale or merger.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                For any purpose that you provide your consent: We will not use your personal
                information for purposes other than those purposes we have disclosed to you, without
                your permission. From time to time, and as required under the applicable law, we may
                request your permission to allow us to share your personal information with third
                parties. In such instances, you may opt out of having your personal information
                shared with third parties, or allowing us to use your personal information for any
                purpose that is incompatible with the purposes for which we originally collected it
                or subsequently obtained your authorization. If you choose to limit the use of your
                personal information, certain features or Services of PBC may not be available to
                you.
              </Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Sharing Of Information With Third Parties
          </Heading>
          <Text>
            Your personal information’s are accessed only by those parties who require access to
            perform their tasks and duties and to share only with third parties who have a
            legitimate purpose for accessing it. We will never sell or rent your personal
            information to third parties without your explicit consent. We will only share your
            information in the following circumstances: -
          </Text>
          <ol>
            <li>
              <Text>
                With third party identity verification service providers for preventing any
                fraudulent activities. There services are used by PBC to confirm your identity by
                comparing the information you provide us to public records and other third party
                databases. These service providers may create derivative data based on your personal
                information that can be used in connection with the provision of identity
                verification and fraud prevention services.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                With financial institutions with which we partner to process payments you have
                authorized.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                With service providers under contract who help with parts of our business
                operations. Our contracts require these service providers to only use your
                information in connection with the services they perform for us, and prohibit them
                from selling your information to anyone else.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                With companies or other entities that we plan to merge with or be acquired by. You
                will receive prior notice of any change in applicable policies.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                With our professional advisors who provide banking, legal, compliance, insurance,
                accounting, or other consulting services.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                With law enforcement, officials, or other third parties when we are compelled to do
                so by a subpoena, court order, or similar legal procedures.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                We share personal information about you with our Affiliates as a normal part of
                conducting business and offering Services to you.
              </Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Children’s Privacy
          </Heading>
          <Text>
            PBC does not allow persons under 18 to register for any service and we do not knowingly
            collect any personally identifiable information from persons under the age of 18. If you
            are aware of someone under the age of 18 using our Platform, please contact us
            immediately.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Third Party Websites
          </Heading>
          <Text>
            Our Platform may contain links to other websites that we believe may be of interest or
            use to you. These third-party websites are not screened for privacy or security issues
            by PBC. Please note that our Privacy Policy and information collection, use and sharing
            procedures do not apply to other websites and we take no responsibility for and incur no
            liability for other websites’ collection, use or sharing of information. Users are
            advised to use their discretion while clicking on any such links.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Storage And Security Of Information
          </Heading>
          <Text>
            We understand the secrecy of your personal information and therefore PBC maintains
            appropriate physical, technical and administrative safeguards to protect the security
            and confidentiality of the personal information you entrust to us.
          </Text>
          <Box p={2} />
          <Text>
            We may store and process all or part of your personal and transactional information,
            including certain payment information, where our facilities or our service providers are
            located. We protect your personal information by maintaining physical, electronic and
            procedural safeguards in compliance with the applicable laws and regulations.
          </Text>
          <Box p={2} />
          <Text>
            However, we cannot guarantee that loss, misuse, unauthorized acquisition, or alteration
            of your data will not occur. Please recognize that you play a vital role in protecting
            your own personal information. When registering with our Services, it is important to
            choose a password of sufficient length and complexity, to not reveal this password to
            any third-parties and to immediately notify us if you become aware of any unauthorized
            access to or use of your account. Whenever the Client changes or accesses the Client’s
            account information, we offer the use of a secure server. Once the Personal Data is in
            our possession we adhere to strict security guidelines, protecting it against
            unauthorized access.
          </Text>
          <Box p={2} />
          <Text>
            Furthermore, we cannot ensure or warrant the security or confidentiality of information
            you transmit to us or receive from us by Internet or wireless connection, including
            email, phone, or SMS, since we have no way of protecting that information once it leaves
            and until it reaches us.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Retention Of Information
          </Heading>
          <Text>
            We keep your information in accordance with our data retention policy. This enables us
            to comply with legal and regulatory requirements or use it where we need to for our
            legitimate purposes such as managing your account and dealing with any disputes or
            concerns that may arise. We may need to retain your information for a longer period
            where we need the information to comply with regulatory or legal requirements or where
            we may need it for our legitimate purposes e.g. to help us respond to queries or
            complaints, fighting fraud and financial crime, responding to requests from regulators,
            etc. If we don’t need to retain information for this period of time, we may destroy,
            delete or anonymise it more promptly.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            International Transfers of Personal Data
          </Heading>
          <Text>
            We may transfer, store and process your personal information within our Affiliates,
            third-party partners and service providers which may possibly in other countries. When
            we do this, we’ll ensure it has an appropriate level of protection and that the transfer
            is lawful. We may need to transfer your information in this way to carry out our
            contract with you, to fulfil a legal obligation, to protect the public interest and/or
            for our legitimate interests. In some countries/territories the law might compel us to
            share certain information, e.g. with tax authorities. Even in these cases, we’ll only
            share your information with people who have the right to see it. By communicating
            electronically with PBC, you acknowledge and agree to your personal information being
            processed in this way.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Cookie Policy
          </Heading>
          <Text>
            A “cookie” is a small piece of information stored by a web server on a web browser so it
            can be later read back from that browser. As you open our website or use any of our
            software and technology some data will be saved in your browser and devices. which is
            known as cookies. Such data saved on your browser and devices help us to identify our
            user, collect information about you which ultimately help us to enhance our product and
            services to better its quality and ensure the security of our product and services are
            not compromised. At the same time, we also ensure that such collection of personal
            information through cookie are in compliance with local act and regulations. You can
            always opt out of cookie with the help of setting in your browser and devices, however,
            in doing so some of the services and functions may get affected.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Promotional Material
          </Heading>
          <Text>
            We may use your personal data and information in order to communicate any of our
            promotional material which may be in various forms such as news, blog, market
            commentary, various publication, promotional offers, information related to marketing
            and promotion of our product and services. Such activities can be conducted by the
            company and/or its affiliates and in order to facilitate such activities, we may have to
            share your personal information with the affiliates as well. Therefore, as you accept
            this privacy policy you agree to receive any promotional or marketing communication. The
            client can always avoid such materials by clicking unsubscribe if the materials are sent
            through email or in other cases can request to the company. However, in the case of
            communication related to product/services that you are using, the information
            dissemination would be mandatory and the client cannot choose to evade such information.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Client’s Privacy Rights
          </Heading>
          <Text>Subject to applicable law, you have the right(s) to: - </Text>
          <ol type="a">
            <li>
              <Text>
                To access information we hold about you and to obtain information about how we
                process it.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>To rectify your Data if it is inaccurate or incomplete.</Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                To delete your data in certain circumstances, where it is no longer necessary for us
                to process your Personal Information to fulfil our processing purposes, or where you
                have exercised your right to object to the processing.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                To restrict the processing of your Personal Information where, the information is
                inaccurate or it is no longer necessary for us to process such information or where
                you have exercised your right to object to our processing.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                To withdraw your consent for processing your data. Exercising this right does not
                affect the lawfulness of the processing based on the consent given before the
                withdrawal of the latter
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>
                To define instructions relating to the reteniton, deletion and communication of your
                Data after your death.
              </Text>
              <Box p={1} />
            </li>
            <li>
              <Text>To lodge complaint to the relevant data protection authority. </Text>
              <Box p={1} />
            </li>
          </ol>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Consent
          </Heading>
          <Text>
            By using and/or visiting the Platform, using our Services and/or by providing the
            Client’s Personal Data, Users hereby consent to the collection and use of the
            information they disclose on the Platform in accordance with this Privacy Policy,
            including but not limited to their consent for sharing their information as per this
            privacy policy.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Legal Disclaimer
          </Heading>
          <Text>
            The information provided on this Privacy Policy does not, and is not intended to,
            constitute legal advice of any sort, instead, all information, content, and materials
            available on this site are for general information purpose only. Information on this
            website may not constitute to the most up-to-date legal or other information
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            No Misrepresentation
          </Heading>
          <Text>
            No misrepresentation or warranty or undertaking of PBC contained in this document or any
            other relevant documents, any schedule, annexure or exhibit hereto or thereto or any
            agreement, instrument or certificate furnished by PBC to Users pursuant to this document
            contains any untrue statement of a material fact or omits to state a material fact
            required to be stated therein or necessary to make the statements therein not misleading
            and untrue.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Contact Us
          </Heading>
          <Text>
            If you have any questions about this Privacy Policy, please contact us by sending an
            email to support@pbc.com with the subject “Privacy Policy Request”.
          </Text>
          <Box p={3} />
          <Heading as="h3" sx={{ textDecoration: 'underline' }}>
            Updates
          </Heading>
          <Text>
            Any update regarding the Privacy Policy in the future will be communicated by email or
            publishing in the website or the platforms used by Users. The Website Policies and Terms
            & Conditions including this Privacy Policy may be changed or updated occasionally to
            meet the requirements and standards. Therefore, Users are encouraged to refer the
            website or notifications in order to be updated about the changes.
          </Text>
        </Box>
      </Card>
    </Box>
  )
}

export default PrivacyPolicyPage
