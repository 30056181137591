import { Box, Flex, Grid, Text } from 'theme-ui'
import { FC } from 'react'
import { Notifications } from 'network/services/notification'

const NotiPop: FC<{ notification: Notifications }> = ({ notification }) => {
  return (
    <Box>
      <Flex sx={{ py: 2 }}>
        <Box>
          <svg width="50" height="30">
            <circle r={5} cx={20} cy={20} fill="white" />
          </svg>
        </Box>
        <Grid gap={1}>
          <Text>{notification.title}</Text>
          <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
            {notification.content}
          </Text>
          <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
            {notification.created_at}
          </Text>
        </Grid>
      </Flex>
    </Box>
  )
}

export default NotiPop
