import { FC } from 'react'
import { Card, Flex, Text, Box } from 'theme-ui'
import Countdown, { zeroPad } from 'react-countdown'
import { DateTime } from 'luxon'

interface InputDate {
  title?: string
  expired_at: string
}
const CountdownCardProposal: FC<InputDate> = ({ title = 'Remaining', expired_at }) => {
  return (
    <Card variant="" padding={0}>
      <Flex
        variant="layout.flexCenterSpaceBetween"
        sx={{
          flexDirection: ['column', null, null, null, 'row'],
          width: '100%',
          py: 1,
          px: 1,
          alignItems: ['center', 'flex-start', 'center']
        }}
      >
        <Box>
          <Text as="p" color="textMuted" style={{ paddingRight: 2 }}>
            {title}
          </Text>
        </Box>
        <Box mt={[4, 4, 4, 2, 0]}>
          <Countdown
            date={DateTime.fromISO(expired_at, {
              zone: 'Asia/Kuala_Lumpur'
            }).toJSDate()}
            zeroPadTime={2}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                // Render a completed state
                return <Text variant="largeTitle">NOW</Text>
              } else {
                // Render a countdown
                return (
                  <Box sx={{ flex: 1 }}>
                    <Flex>
                      <Card
                        bg="#1f2152"
                        mr={1}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 1
                          // width: 70
                        }}
                      >
                        <Text variant="small">{zeroPad(days)}</Text>
                        <Text variant="small">Days</Text>
                      </Card>
                      <Card
                        bg="#1f2152"
                        mr={1}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 1
                          // width: 70
                        }}
                      >
                        <Text variant="small">{zeroPad(hours)}</Text>
                        <Text variant="small">Hours</Text>
                      </Card>
                      <Card
                        bg="#1f2152"
                        mr={1}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 1
                          // width: 70
                        }}
                      >
                        <Text variant="small">{zeroPad(minutes)}</Text>
                        <Text variant="small">Minutes</Text>
                      </Card>
                      <Card
                        bg="#1f2152"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 1
                          // width: 70
                        }}
                      >
                        <Text variant="small">{zeroPad(seconds)}</Text>
                        <Text variant="small">Seconds</Text>
                      </Card>
                    </Flex>
                  </Box>
                )
              }
            }}
          />
        </Box>
      </Flex>
    </Card>
  )
}

export default CountdownCardProposal
