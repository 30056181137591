import toUTCTime from 'modules/to-utc-time'
import { IDataResponse } from '../request'

export type News = {
  id: string | number
  title: string
  description: string
  url: string
  image_url: string
  published_at: string
}

// useSWR, return strings
const getNewsList = '/news'

const getNews = (id: string | number) => {
  return `/news/${id}`
}

// processing
const toRow = (data: IDataResponse<News>): News[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id,
        published_at: toUTCTime(element.published_at)
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<News>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const NewsService = {
  getNewsList,
  getNews,
  toRow,
  toPaginate
}

export default NewsService
