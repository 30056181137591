/** @jsxImportSource theme-ui */
import { Box, Card, Flex, Text, Grid, useThemeUI } from 'theme-ui'
import { FC } from 'react'
import { Announcement } from 'network/services/announcement'
import { useNavigate } from 'react-router-dom'

const AnnouceCard: FC<{ announcement: Announcement }> = ({ announcement }) => {
  const navigate = useNavigate()
  return (
    <Box>
      <Card onClick={() => navigate(`${announcement.id}`)}>
        <Flex variant="layout.flexCenterSpaceBetween">
          <Flex>
            <svg width="50" height="30">
              <circle r={5} cx={20} cy={20} fill="white" />
            </svg>
            <Grid gap={1}>
              <Text>{announcement.title}</Text>
              <Text variant="mediumSmall" sx={{ color: 'textMuted' }}>
                {announcement.content}
              </Text>
            </Grid>
          </Flex>
          <Text sx={{ color: 'textMuted' }}>{announcement.published_at}</Text>
        </Flex>
      </Card>
    </Box>
  )
}

export default AnnouceCard
