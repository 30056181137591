/** @jsxImportSource theme-ui */
import PoolService, { Pool } from 'network/services/pool'
import { FC } from 'react'
import useSWR from 'swr'
import { Box, Card, Flex, Text } from 'theme-ui'

const PoolOverviewCard: FC<{ pool: Pool }> = ({ pool }) => {
  const { data, error } = useSWR(PoolService.getProfits(pool.id))

  if (error) {
    return <></>
  }

  if (!data) {
    return <></>
  }

  return (
    <Card sx={{ background: 'darkBG', p: 0 }}>
      <Flex sx={{ flexDirection: 'column' }}>
        <Box variant="layout.underlineBox" sx={{ p: 6, flex: 3 }}>
          <Box>
            <Box>
              <Text variant="large">Current Pool Size</Text>
            </Box>
            <Box>
              <Text variant="large" color="textMuted">
                {pool.pool_size} VXT
              </Text>
            </Box>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Box>
              <Text variant="large">Average Pool APY </Text>
              <Text color="textMuted">(last 3 mths)</Text>
            </Box>
            <Box>
              {pool.apy > 0 ? (
                <Text variant="large" color="success">
                  {pool.apy}%
                </Text>
              ) : (
                <Text variant="large" color="success">
                  No Sufficient Data Yet
                </Text>
              )}
            </Box>
          </Box>
        </Box>
        <Box variant="layout.underlineBox" sx={{ p: 6, flex: 1 }}>
          <Flex variant="layout.flexCenterCenter">
            <Box sx={{ flex: 1 }}>
              <Box>
                <Text as="p" variant="large">
                  Fund Size
                </Text>
                <Text as="p" variant="small">
                  Earning in VXT
                </Text>
              </Box>
              <Box>
                <Text color="textMuted">{(pool?.earn_vxt_percentage * 100).toFixed(2)}%</Text>
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box>
                <Text as="p" variant="large">
                  NFT Linked
                </Text>
                <Text as="p" variant="small">
                  Deposit Size
                </Text>
              </Box>
              <Box>
                <Text color="textMuted">{(pool?.link_nft_percentage * 100).toFixed(2)}%</Text>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box variant="layout.underlineBox" sx={{ p: 6, borderWidth: 0, flex: 1 }}>
          <Box>
            <Text variant="large">Total Participants</Text>
          </Box>
          <Box>
            <Text variant="large" color="textMuted">
              {pool.total_participants_count}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Card>
  )
}

export default PoolOverviewCard
