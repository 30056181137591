/** @jsxImportSource theme-ui */
import MobileReturnHeader from 'components/MobileReturnHeader'
import Tab from 'components/Tab'
import { useResolution } from 'hooks/useResolution'
import { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, Flex, Text } from 'theme-ui'
import StakePoolAmplifyPage from './stake-pool-amplify-page'
import StakePoolLaunchPoolPage from './stake-pool-launchpool-page'
import StakePoolVXTPage from './stake-pool-vxt-page'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'

let AnimatedBox = motion(Box)

const StakePoolsPage = () => {
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const [currentTab, setCurrentTab] = useState<number>(0)
  const { isMobile } = useResolution()
  const navigate = useNavigate()

  useEffect(() => {
    if (!tab) {
      return
    }

    setCurrentTab(tab === 'amplify' ? 0 : tab === 'launchpool' ? 1 : 2)
  }, [tab])

  return (
    <Box variant="layout.pageContainer">
      {isMobile ? (
        <MobileReturnHeader title="BTC Pool Info" delta={-1} />
      ) : (
        <Box>
          {/* TODO: navigate seems to break */}
          <Button
            variant="primaryFlexCenter"
            sx={{ width: 280 }}
            onClick={() => navigate('/stake')}
          >
            <Flex variant="layout.flexCenterSpaceBetween">
              <BsArrowLeft />
              <Text>Back to active contract</Text>
            </Flex>
          </Button>
        </Box>
      )}
      <Box p={4} />
      <Box>
        <Tab
          defaultTab={currentTab}
          onTabChange={setCurrentTab}
          tabs={['Amplify', 'Launchpool', 'VXT Pool']}
        />
        <Box p={4} />

        <AnimatePresence exitBeforeEnter>
          {
            {
              0: (
                <AnimatedBox
                  key="amplify"
                  initial="hide"
                  animate="show"
                  exit="hide"
                  variants={fadeAnim}
                >
                  <StakePoolAmplifyPage />
                </AnimatedBox>
              ),
              1: (
                <AnimatedBox
                  key="launch"
                  initial="hide"
                  animate="show"
                  exit="hide"
                  variants={fadeAnim}
                >
                  <StakePoolLaunchPoolPage />
                </AnimatedBox>
              ),
              2: (
                <AnimatedBox
                  key="vxt"
                  initial="hide"
                  animate="show"
                  exit="hide"
                  variants={fadeAnim}
                >
                  <StakePoolVXTPage />
                </AnimatedBox>
              )
            }[currentTab]
          }
        </AnimatePresence>
      </Box>
    </Box>
  )
}

export default StakePoolsPage
