import { Box, Button, Card, Heading, Flex, Text, Image, IconButton } from 'theme-ui'
import { FC, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { proxy, useSnapshot } from 'valtio'
import { useInterval } from 'usehooks-ts'

import AuthService, { ForgotPassword } from 'network/services/auth'
import FormInput from 'components/form-input'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import StatusModal from 'components/status-modal'
import { useNavigate } from 'react-router-dom'

// import { useResponsiveValue } from '@theme-ui/match-media'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

// import Tab from 'components/Tab'

const forgotPasswordStore = proxy<{ step: 0 | 1 | 2; isEmail: boolean | null }>({
  step: 0,
  isEmail: true
})

const ForgetPasswordPage: FC = () => {
  const navigate = useNavigate()
  const methods = useForm<ForgotPassword>({
    shouldUseNativeValidation: false
  })
  const { step } = useSnapshot(forgotPasswordStore)
  const [modalMessage, setModalMessage] = useState<{ success: boolean; message: string } | null>(
    null
  )

  const onSubmit = methods.handleSubmit(async (formData) => {
    try {
      const { data: result } = await AuthService.forgotPassword(formData)
      if (result.success) {
        setModalMessage({ success: true, message: 'Password reset successfully!' })
      }
    } catch (error) {
      console.log(error)
      setModalMessage({ success: true, message: 'Something went wrong' })
    }
  })

  useEffect(() => {
    return () => {
      forgotPasswordStore.step = 0
      forgotPasswordStore.isEmail = true // change this to false if phone input is enabled
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box
          as="form"
          onSubmit={onSubmit}
          onKeyDown={(e) => {
            if (e.code === 'Enter') e.preventDefault()
          }}
        >
          {
            {
              0: <EmailPhonePicker />,
              1: <Verification />,
              2: <PasswordForm />
            }[step]
          }
        </Box>
      </FormProvider>
      <StatusModal
        isOpen={modalMessage != null}
        onRequestClose={() => {
          setModalMessage(null)
          if (modalMessage?.success) {
            navigate('/')
          }
        }}
        success={modalMessage?.success ?? false}
        children={modalMessage?.message ?? ''}
      />
    </>
  )
}

const EmailPhonePicker: FC = () => {
  const isEmail = useSnapshot(forgotPasswordStore)
  const {
    register,
    trigger,
    formState: { errors }
  } = useFormContext<ForgotPassword>()

  return (
    <Box
      variant="layout.flexCenterCenter"
      sx={{
        minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
        py: [5, 16],
        position: 'relative'
      }}
    >
      <Card px={10} py={12}>
        <Box
          variant="layout.flexCenterCenter"
          sx={{ minHeight: 'inherit', flexDirection: 'column' }}
        >
          <Heading sx={{ textAlign: 'left' }}>Reset Your Password</Heading>
          <Box pt={5} />
          {isEmail == null ? (
            <>
              <Button
                type="button"
                sx={{
                  background: 'input',
                  width: [300, null, null, 360],
                  marginLeft: 0,
                  textAlign: 'left'
                }}
                onClick={() => {
                  forgotPasswordStore.isEmail = true
                }}
              >
                <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box variant="layout.iconContainer">
                    <Image src="/assets/svg/phone.svg" />
                  </Box>
                  <Text>Phone Number Verification</Text>
                  <BsArrowRight />
                </Flex>
              </Button>
              <Box pt={5} />
              <Button
                type="button"
                sx={{
                  background: 'input',
                  width: [300, null, null, 360],
                  marginLeft: 0,
                  textAlign: 'left'
                }}
                onClick={() => {
                  forgotPasswordStore.isEmail = false
                }}
              >
                <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box variant="layout.iconContainer">
                    <Image src="/assets/svg/mail.svg" />
                  </Box>
                  <Text>Email Verification</Text>
                  <BsArrowRight />
                </Flex>
              </Button>
            </>
          ) : (
            <>
              {/* <Box sx={{ width: '100%' }}>
          <Tab defaultTab={isEmail ? 0 : 1} tabs={['Email', 'Phone']} onTabChange={setIsEmail} />
        </Box>
        <Box pt={10} /> */}
              <Box as="form" sx={{ width: '100%' }}>
                <FormInput<ForgotPassword>
                  id="email"
                  name="email"
                  label="E-mail Address"
                  register={register}
                  rules={{
                    required: 'Please enter your email',
                    pattern: {
                      value: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
                      message: 'Enter a valid email address.'
                    }
                  }}
                  errors={errors}
                />
                {/* <Input sx={{ width: ['100%', null, null, 360] }} name="email" id="email" /> */}
                {/* {isEmail ? (
            <>
              <Label htmlFor="email">E-mail Address</Label>
              <Box pt={2} />
              <Input sx={{ width: [230, null, null, 360] }} name="email" id="email" />
            </>
          ) : (
            <>
              <Label htmlFor="phone">Phone Number</Label>
              <Box pt={2} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  bg: 'input',
                  borderRadius: 10,
                  background: 'input',
                  width: '100%',
                  height: 60,
                  px: 4
                }}
                variant="forms.reactTelInput"
              >
                <PhoneInput
                  country={'us'}
                  enableSearch={true}
                  dropdownStyle={{
                    width: phoneInputWidth
                  }}
                />
              </Box>
            </>
          )} */}
              </Box>
              <Box pt={10} />
              <Button
                type="button"
                sx={{ width: [250, null, null, 360] }}
                onClick={async () => {
                  if (await trigger('email')) {
                    forgotPasswordStore.step = 1
                  }
                }}
              >
                Continue
              </Button>
            </>
          )}
        </Box>
      </Card>
    </Box>
  )
}

const Verification: FC = () => {
  const { isEmail } = useSnapshot(forgotPasswordStore)
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext<ForgotPassword>()
  const email = watch('email')
  const code = watch('auth_code')
  const [otpCooldown, setOtpCooldown] = useState<number>(30)
  const [otpSent, setOtpSent] = useState<boolean>(false)

  useInterval(
    () => {
      if (otpCooldown > 0) {
        setOtpCooldown(otpCooldown - 1)
        return
      }

      setOtpSent(false)
      setOtpCooldown(30)
    },
    otpSent ? 1000 : null
  )

  const getAuthCode = async () => {
    try {
      const { data: result } = await AuthService.forgotPasswordGetAuth({ email })
      if (result.success) {
        setOtpSent(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const verifyAuthCode = async () => {
    try {
      const { data: result } = await AuthService.verifyAuth({ email, code })
      if (result.success) {
        forgotPasswordStore.step = 2
      }
      // console.log(result)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box
      variant="layout.flexCenterCenter"
      sx={{
        minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
        py: [5, 16],
        position: 'relative'
      }}
    >
      <Card px={10} py={12}>
        <Box
          variant="layout.flexStartCenter"
          sx={{ minHeight: 'inherit', flexDirection: 'column' }}
        >
          <Heading sx={{ textAlign: 'left' }}>Security Verification</Heading>
          <Box pt={5} />
          <Text>To secure your account, please complete the following verification.</Text>
          <Box pt={5} />
          <Box>
            {isEmail ? <Text>Email </Text> : <Text>Phone Number</Text>}
            <Text>Verification Code</Text>
          </Box>
          <Box pt={2} />
          <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
            <FormInput<ForgotPassword>
              sx={{ my: 3, background: 'sidebarButtonBG' }}
              name="auth_code"
              trailing={
                <Button
                  type="button"
                  sx={{ p: 4, display: 'inline-block' }}
                  variant="text"
                  onClick={async (e) => {
                    e.preventDefault()
                    await getAuthCode()
                  }}
                  disabled={otpSent}
                >
                  {otpSent ? otpCooldown : 'Get OTP'}
                </Button>
              }
              register={register}
              rules={{ required: 'You must enter OTP.' }}
              errors={errors}
            />
          </Flex>
          <Box pt={2} />
          {/* <Text variant="small" sx={{ color: 'textMuted' }}>
            Enter the 6-digit code sent to ******
          </Text>
          <Box pt={5} />
          <Box>
            <Text sx={{ color: 'primary' }} variant="mediumSmall">
              {`Switch to `}
            </Text>
            {type === 'email' ? (
              <Text sx={{ color: 'primary' }} variant="mediumSmall">
                Phone Verification
              </Text>
            ) : (
              <Text sx={{ color: 'primary' }} variant="mediumSmall">
                Email Verification
              </Text>
            )}
          </Box> */}
          <Box pt={5} />
          <Button
            // type="submit"
            type="button"
            onClick={() => {
              // clearErrors()
              verifyAuthCode()
            }}
            sx={{ width: '100%' }}
          >
            Next
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

const PasswordForm: FC = () => {
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register,
    watch,
    clearErrors,
    formState: { errors }
  } = useFormContext<ForgotPassword>()

  const passwordConfirmation = watch('password_confirmation')

  return (
    <Box
      variant="layout.flexCenterCenter"
      sx={{
        minHeight: ['calc(100vh - 51px - 144px)', 'inherit'],
        py: [5, 16],
        position: 'relative'
      }}
    >
      <Card px={10} py={12}>
        <Box
          variant="layout.flexStartCenter"
          sx={{ minHeight: 'inherit', flexDirection: 'column', minWidth: [280, null, null, 400] }}
        >
          <Heading sx={{ textAlign: 'left' }}>Update your password</Heading>
          <Box pt={5} />
          <FormInput<ForgotPassword>
            id="password"
            type={showNewPassword ? 'text' : 'password'}
            name="password"
            label="New Password"
            register={register}
            rules={{ required: 'You must enter your new password.' }}
            errors={errors}
            trailing={
              <IconButton px={4} type="button" onClick={() => setShowNewPassword(!showNewPassword)}>
                {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </IconButton>
            }
          />
          <Box pt={2} />
          <FormInput<ForgotPassword>
            id="password-confirmation"
            type={showConfirmPassword ? 'text' : 'password'}
            name="password_confirmation"
            label="Confirm New Password"
            register={register}
            rules={{
              required: 'You must confirm your new password.',
              validate: (v) => {
                if (v !== passwordConfirmation) {
                  return 'Password does not match'
                }
              }
            }}
            errors={errors}
            trailing={
              <IconButton
                px={4}
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </IconButton>
            }
          />
          <Box pt={5} />
          <Button
            type="submit"
            onClick={() => {
              clearErrors()
            }}
            sx={{ width: '100%' }}
          >
            Next
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

export default ForgetPasswordPage
