import { useResolution } from 'hooks/useResolution'
import { Box, Card, Button, Flex, Text } from 'theme-ui'
import { BsArrowLeft } from 'react-icons/bs'
import AboutUs from './about-us'
import Footer from './footer'
import QuickAccess from './quick-access'
import UserInfo from './user-info'
import { useSideBarDisplay } from 'context/sideBarDisplay'

const Sidebar = () => {
  const { isMobile } = useResolution()
  const { setSideBarDisplay, sidebarDisplay } = useSideBarDisplay()

  return (
    <Box
      sx={{
        zIndex: 5,
        display: [sidebarDisplay ? 'block' : 'none', 'block'],
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        height: 'auto',
        overflowY: 'scroll',
        width: ['100%', 'auto'],
        scrollbarWidth: 'none', //for firefox
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      {isMobile && (
        <Flex
          sx={{ background: 'sidebarButtonBG', p: 3, py: 4, height: 'auto' }}
          variant="layout.flexCenterSpaceBetween"
        >
          <Button variant="round" onClick={() => setSideBarDisplay(false)}>
            <BsArrowLeft />
          </Button>
          <Text color="secondaryText" variant="extraLarge">
            Profile
          </Text>
          <Box p={2} />
        </Flex>
      )}
      <Card
        bg="darkBG"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          px: [24]
        }}
      >
        <Box>
          <UserInfo />
          <Box pt={6} />
          <QuickAccess />
          <Box pt={6} />
          <AboutUs />
        </Box>
        <Footer />
      </Card>
    </Box>
  )
}

export default Sidebar
