/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Input,
  Select,
  Text
} from 'theme-ui'
import useSWR from 'swr'
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { FC, useState } from 'react'
import { useSnapshot } from 'valtio'
import _ from 'lodash'

import { currencyStore, findUsd } from 'store/currency'
import TransactionService, { Transaction } from 'network/services/transaction'
import WalletService, { Wallet } from 'network/services/wallet'
import { IDataResponse, serialize } from 'network/request'
import Table from 'components/Table'
import TokenIcon from 'components/icons/token'
import Link from 'components/link'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'
import PageControl from 'components/page-control'
import HorizontalScroller from 'layout/horizontal-scroller'
import commaNumber from 'modules/comma-number'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeAnim } from 'lib/animation'
import CustomModal from 'components/modal'

interface TransactionHeader {
  name: string
  key: keyof Transaction
}

interface SortStatus {
  key: string
  ascending: boolean
}

interface FilterQuery {
  txn_number: string
  txn_type: string
  token_type: string
  status: string
}

let AnimatedBox = motion(Box)

const WalletPage = () => {
  const [query, setQuery] = useState<FilterQuery>({
    txn_type: '',
    token_type: '',
    status: '',
    txn_number: ''
  })

  return (
    <Box variant="layout.pageContainer">
      <Grid gap={0}>
        <Heading>Wallet Overview</Heading>

        <Box p={2} />

        <WalletOverview />

        <Box p={5} />

        <WalletsSummary />

        <Box p={5} />

        <Heading>Transaction History</Heading>

        <Box p={2} />

        <Filters query={query} setQuery={setQuery} />

        <WalletTransactions query={query} />
      </Grid>
    </Box>
  )
}

const WalletOverview: FC = () => {
  // const currency = useSnapshot(currencyStore)
  const { data, error, mutate } = useSWR<IDataResponse<Wallet>>(WalletService.getAll)
  const [showTransfer, setShowTransfer] = useState(false)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve wallet information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const usd = data.data.reduce((prev, next) => {
    return prev + findUsd(next.balance, next.token_type)
  }, 0)
  // const btc = usd / parseFloat(currency.btc?.c ?? '0')

  return (
    <>
      <Card variant="secondary" sx={{ p: 8, borderRadius: 20 }}>
        <Flex variant="layout.flexCenterSpaceBetween" sx={{ flexDirection: ['column', 'row'] }}>
          <Grid>
            <Text variant="large">Wallet Balance (USDT)</Text>
            <Box p={1} />
            <Box variant="layout.hStack">
              <Box>
                <Text variant="extraLarge" color="secondaryText">
                  {usd.toFixed(10)}
                </Text>
              </Box>
              <Box>
                <Text variant="extraLarge" color="textMuted">
                  ≈
                </Text>
              </Box>
              <Box>
                <Text variant="extraLarge" color="textMuted">
                  ${commaNumber(usd.toFixed(2))}
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid columns={1} gap={[2, 3]} sx={{ pt: [3, 0] }}>
            {/* <Link to="deposit">
            <Button sx={{ minWidth: [85, 90, 150], px: 0 }}>Deposit</Button>
          </Link> */}
            {/* <Link to="/stake">
            <Button sx={{ minWidth: [85, 90, 150], px: 0 }}>Stake</Button>
          </Link> */}
            <Button
              sx={{ minWidth: [85, 90, 150], px: 0 }}
              onClick={() => {
                setShowTransfer(true)
              }}
            >
              Transfer
            </Button>
          </Grid>
        </Flex>
      </Card>
      {showTransfer && (
        <>
          <CustomModal
            isOpen={showTransfer}
            onRequestClose={() => {
              setShowTransfer(false)
            }}
            overlayStyle={{
              justifyContent: 'center',
              textAlign: 'center'
            }}
            contentStyle={{
              height: '60%'
            }}
          >
            <Box sx={{ pt: 15 }}>
              <Card sx={{ width: [320, 320, 400], mx: 'auto' }}>
                <Box sx={{ height: 80, position: 'relative' }}>
                  <Flex
                    variant="layout.flexCenterCenter"
                    sx={{
                      position: 'absolute',
                      top: -12,
                      left: '-30px'
                    }}
                  >
                    <Image src="/assets/images/timer.png" />
                  </Flex>
                </Box>
                <Box p={2} />
                <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
                  <Text variant="largeTitle2">Stay Tuned</Text>
                  <Box p={2} />
                  <Box sx={{ width: '80%', margin: 'auto' }}>
                    We're making some improvements to this feature at the moment.
                  </Box>
                  <Box sx={{ width: '80%', margin: 'auto' }}>
                    Stay tuned for the exciting updates!
                  </Box>
                  <Box p={4} />
                  <Button
                    onClick={() => {
                      setShowTransfer(false)
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Card>
            </Box>
          </CustomModal>
        </>
      )}
    </>
  )
}

const WalletsSummary = () => {
  const { data, error, mutate } = useSWR<IDataResponse<Wallet>>(WalletService.getAll)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve wallet information" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  // access directly to ensure rerender on data changed
  const tableData = data.data.map((item) => {
    return {
      symbol: (
        <Flex key={item.id} variant="layout.hStack" sx={{ alignItems: 'flex-start' }}>
          <TokenIcon type={item.token_type} />
        </Flex>
      ),
      name: item.token_type,
      balance: commaNumber(item.balance),
      usd_value: `~$${commaNumber(findUsd(item.balance, item.token_type).toFixed(2))}`
    }
  })

  return (
    <Table
      headers={['Symbol', 'Name', 'Balance', 'USD Value']}
      data={tableData}
      displayPage={false}
    />
  )
}

const Filters: FC<{ query: FilterQuery; setQuery: any }> = ({ query, setQuery }) => {
  const [filterKey, setFilterKey] = useState('')

  return (
    <Flex variant="layout.flexCenterSpaceBetween" sx={{ height: 'auto' }}>
      <Flex variant="layout.hStack" sx={{ background: 'dark', p: 5, borderRadius: 30 }}>
        <Box>
          <Text variant="mediumSmall">Filter By</Text>
        </Box>
        <Box>
          <Text color="primary" ml={4}>
            |
          </Text>
        </Box>
        <Box>
          <Select
            sx={{
              background: 'none',
              padding: 0,
              color: 'text',
              fontSize: ['12px', null, null, '14px', '14px']
            }}
            onChange={(e) => {
              setFilterKey(e.target.value)
              setQuery({})
            }}
          >
            <option value="">None</option>
            {/* <option value="txn_number">Txn Number</option> */}
            <option value="txn_type">Type</option>
            <option value="token_type">Currency</option>
            <option value="status">Status</option>
          </Select>
        </Box>
        {filterKey !== '' && (
          <>
            <Box>
              <Text color="primary" ml={4}>
                |
              </Text>
            </Box>
            <Box>
              <AnimatePresence exitBeforeEnter>
                {/* repeat select to prevent it from remembering last selected value */}
                {
                  {
                    txn_type: (
                      <AnimatedBox
                        key="txn_type"
                        initial="hide"
                        animate="show"
                        exit="hide"
                        variants={fadeAnim}
                      >
                        <Select
                          sx={{
                            background: 'none',
                            padding: 0,
                            color: 'text',
                            fontSize: ['12px', null, null, '14px', '14px']
                          }}
                          onChange={(e) => {
                            let newQuery: any = {}
                            newQuery[filterKey] = e.target.value
                            setQuery(newQuery)
                          }}
                        >
                          <option value="" defaultChecked>
                            All
                          </option>
                          <option value="stake">Stake</option>
                          <option value="deposit">Deposit</option>
                          <option value="withdraw">Withdraw</option>
                          {/* <option value="nft_purchase">NFT Purchase</option> */}
                          {/* <option value="nft_deposit">NFT Deposit</option>
                          <option value="nft_withdraw">NFT Withdraw</option> */}
                        </Select>
                      </AnimatedBox>
                    ),
                    token_type: (
                      <AnimatedBox
                        key="token_type"
                        initial="hide"
                        animate="show"
                        exit="hide"
                        variants={fadeAnim}
                      >
                        <Select
                          sx={{
                            background: 'none',
                            padding: 0,
                            color: 'text',
                            fontSize: ['12px', null, null, '14px', '14px']
                          }}
                          onChange={(e) => {
                            let newQuery: any = {}
                            newQuery[filterKey] = e.target.value
                            setQuery(newQuery)
                          }}
                        >
                          <option value="" defaultChecked>
                            All
                          </option>
                          <option value="USDT">USDT</option>
                          {/* <option value="ETH">ETH</option>
                          <option value="BTC">BTC</option>
                          <option value="PBC">PBC</option> */}
                        </Select>
                      </AnimatedBox>
                    ),
                    status: (
                      <AnimatedBox
                        key="status"
                        initial="hide"
                        animate="show"
                        exit="hide"
                        variants={fadeAnim}
                      >
                        <Select
                          sx={{
                            background: 'none',
                            padding: 0,
                            color: 'text',
                            fontSize: ['12px', null, null, '14px', '14px']
                          }}
                          onChange={(e) => {
                            let newQuery: any = {}
                            newQuery[filterKey] = e.target.value
                            setQuery(newQuery)
                          }}
                        >
                          <option value="" defaultChecked>
                            All
                          </option>
                          <option value="completed">Completed</option>
                          <option value="pending">Pending</option>
                        </Select>
                      </AnimatedBox>
                    )
                    // txn_number: (
                    //   <AnimatedBox
                    //     key="txn_number"
                    //     initial="hide"
                    //     animate="show"
                    //     exit="hide"
                    //     variants={fadeAnim}
                    //     style={{ paddingLeft: '12px' }}
                    //   >
                    //     <Input
                    //       placeholder="Search txn number"
                    //       sx={{
                    //         flex: 1,
                    //         paddingRight: '2px'
                    //         // borderTopLeftRadius: 0,
                    //         // borderBottomLeftRadius: 0
                    //       }}
                    //       onChange={(e) => {
                    //         let newQuery: any = {}
                    //         newQuery[filterKey] = e.target.value
                    //         setQuery(newQuery)
                    //       }}
                    //     />
                    //   </AnimatedBox>
                    // )
                  }[filterKey]
                }
              </AnimatePresence>
            </Box>
          </>
        )}
      </Flex>
      {/* <Flex variant="layout.hStack" sx={{ background: 'dark', p: 5, borderRadius: 30 }}>
      <Box>
        <Text>2022-04-01</Text>
      </Box>
      <BsArrowRight />
      <Box>
        <Text>2022-04-01</Text>
      </Box>
      <Image src="/assets/svg/calendar.svg" />
    </Flex> */}
    </Flex>
  )
}

const WalletTransactions: FC<{ query: FilterQuery }> = ({ query }) => {
  const [sortStatus, setSortStatus] = useState<SortStatus>({ key: 'id', ascending: false })
  const limit = 10
  const [page, setPage] = useState(1)
  const { data, error, mutate } = useSWR<IDataResponse<Transaction>>(
    serialize(TransactionService.getTransaction, {
      page: page,
      limit: limit,
      sort: `${sortStatus.key}:${sortStatus.ascending ? 'asc' : 'desc'}`,
      ...query
    })
  )

  const [headers] = useState<TransactionHeader[]>([
    {
      name: 'Type',
      key: 'txn_type'
    },
    {
      name: 'Currency',
      key: 'token_type'
    },
    {
      name: 'Amount',
      key: 'amount_in' || 'amount_out'
    },
    {
      name: 'Date & Time',
      key: 'created_at'
    },
    {
      name: 'Status',
      key: 'status'
    },
    {
      name: 'Ref No',
      key: 'txn_number'
    }
  ])

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve transaction history" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!data) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  const handleSort = (key: keyof Transaction) => {
    setSortStatus({ key: key, ascending: !sortStatus.ascending })
  }

  const transactions = TransactionService.toRow(data).map((item) => {
    return {
      type: item.txn_type?.includes('_')
        ? _.startCase(item.txn_type?.split('_').join(' '))
        : _.startCase(item.txn_type),
      currency: item.token_type,
      amount:
        item.amount > 0 ? (
          <Text sx={{ color: 'success' }}>{item.amount_in}</Text>
        ) : (
          <Text sx={{ color: 'danger' }}>{item.amount_out}</Text>
        ),
      date_time: item.created_at,
      status: _.startCase(item.status),
      txn_number: item?.withdraw?.txn_number ?? item?.deposit?.ref_no ?? '-'
    }
  })

  const total = TransactionService.toPaginate(data).total

  if (transactions.length === 0) {
    return (
      <Flex variant="layout.flexCenterCenter" pt={6}>
        <Text>No records found</Text>
      </Flex>
    )
  }

  return (
    <Box>
      <Box p={2} />
      <Box sx={{ width: '100%' }}>
        <HorizontalScroller>
          <table sx={{ width: ['800', '100%'] }} cellSpacing={0}>
            <thead>
              <tr
                key="table-header"
                sx={{
                  background: 'darkBG',
                  margin: 0
                }}
              >
                {headers?.map((item, index) => (
                  <th
                    sx={
                      index === 0
                        ? { borderRadius: '10px 0 0 10px' }
                        : index === headers.length - 1
                        ? { borderRadius: '0 10px 10px 0' }
                        : {}
                    }
                    key={index}
                  >
                    <Flex sx={{ py: 5, pl: 10, pr: index === headers.length - 1 ? 10 : 0 }}>
                      <Text sx={{ fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2 }}>
                        {item.name}
                      </Text>
                      <IconButton
                        sx={{ color: 'text', fontSize: 10 }}
                        onClick={() => handleSort(item.key as keyof Transaction)}
                      >
                        {sortStatus?.key === item.key ? (
                          sortStatus.ascending ? (
                            <TiArrowSortedDown size="0.1em" />
                          ) : (
                            <TiArrowSortedUp size="0.1em" />
                          )
                        ) : (
                          <TiArrowUnsorted size="0.1em" />
                        )}
                      </IconButton>
                    </Flex>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TableBody data={transactions} />
            </tbody>
          </table>
        </HorizontalScroller>
        {!data && (
          <Flex variant="layout.flexCenterCenter">
            <LoadingCard />
          </Flex>
        )}

        <Box p={2} />

        {data && total > 0 && (
          <PageControl page={page} total={total} limit={limit} setPage={setPage} />
        )}
      </Box>
    </Box>
  )
}

const TableBody: FC<{ data: any[] }> = ({ data }) => {
  return (
    <>
      {data &&
        data.map((item, index) => (
          <tr key={item.key ?? index}>
            {Object.keys(item).map(
              (key) =>
                key !== 'key' && (
                  <td
                    sx={{
                      py: 8,
                      borderWidth: '0 0 2px 0',
                      borderColor: 'darkBG',
                      borderStyle: 'solid'
                    }}
                  >
                    <Flex
                      sx={{
                        py: 1,
                        pl: 10
                      }}
                    >
                      {typeof item[key] === 'string' || typeof item[key] === 'number' ? (
                        <Text sx={{ flex: 1 }}>{item[key]}</Text>
                      ) : (
                        item[key]
                      )}
                    </Flex>
                  </td>
                )
            )}
          </tr>
        ))}
    </>
  )
}

export default WalletPage
