import { IDataResponse } from '../request'
import toUTCTime from 'modules/to-utc-time'
import client from '../request'

export type Notifications = {
  id: string | number
  title: string
  content: string
  image: string
  type: string
  scope: string | number
  read: boolean
  user_id: string | number
  from_user_id: string
  created_at: string
  updated_at: string
}

export type UpdateNotification = {
  ids: (string | number)[]
}

const getNotifications = '/me/notifications'

const markNotificationsAsRead = (data: UpdateNotification) => {
  return client.post('/me/notifications/read', data)
}

// processing
const toRow = (data: IDataResponse<Notifications>): Notifications[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data.map((element) => {
      return {
        ...element,
        key: element.id,
        created_at: toUTCTime(element.created_at)
      }
    })
  }

  return []
}

// processing
const toPaginate = (data?: IDataResponse<Notifications>) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const NotificationsService = {
  getNotifications,
  markNotificationsAsRead,
  toRow,
  toPaginate
}

export default NotificationsService
