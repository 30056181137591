import { Theme } from '@theme-ui/css'

const theme: Theme = {
  config: {
    initialColorModeName: 'dark'
  },
  borderWidths: {
    '0': '0',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    px: '1px'
  },
  // breakpoints: ['640px', '768px', '1024px', '1280px', '1440px', '1920px'],
  // mobile, tablet, laptop, desktop, ultrawide
  breakpoints: ['996px', '1280px', '1440px', '1920px'],
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    gray: [
      '',
      '#f7fafc',
      '#edf2f7',
      '#e2e8f0',
      '#cbd5e0',
      '#a0aec0',
      '#718096',
      '#4a5568',
      '#2d3748',
      '#1a202c'
    ],
    red: [
      '',
      '#fff5f5',
      '#fed7d7',
      '#feb2b2',
      '#fc8181',
      '#f56565',
      '#e53e3e',
      '#c53030',
      '#9b2c2c',
      '#742a2a'
    ],
    orange: [
      '',
      '#fffaf0',
      '#feebc8',
      '#fbd38d',
      '#f6ad55',
      '#ed8936',
      '#dd6b20',
      '#c05621',
      '#9c4221',
      '#7b341e'
    ],
    yellow: [
      '',
      '#fffff0',
      '#fefcbf',
      '#faf089',
      '#f6e05e',
      '#ecc94b',
      '#d69e2e',
      '#b7791f',
      '#975a16',
      '#744210'
    ],
    green: [
      '',
      '#f0fff4',
      '#c6f6d5',
      '#9ae6b4',
      '#68d391',
      '#48bb78',
      '#38a169',
      '#2f855a',
      '#276749',
      '#22543d'
    ],
    teal: [
      '',
      '#e6fffa',
      '#b2f5ea',
      '#81e6d9',
      '#4fd1c5',
      '#38b2ac',
      '#319795',
      '#2c7a7b',
      '#285e61',
      '#234e52'
    ],
    blue: [
      '',
      '#ebf8ff',
      '#bee3f8',
      '#90cdf4',
      '#63b3ed',
      '#4299e1',
      '#3182ce',
      '#2b6cb0',
      '#2c5282',
      '#2a4365'
    ],
    indigo: [
      '',
      '#ebf4ff',
      '#c3dafe',
      '#a3bffa',
      '#7f9cf5',
      '#667eea',
      '#5a67d8',
      '#4c51bf',
      '#434190',
      '#3c366b'
    ],
    purple: [
      '',
      '#faf5ff',
      '#e9d8fd',
      '#d6bcfa',
      '#b794f4',
      '#9f7aea',
      '#805ad5',
      '#6b46c1',
      '#553c9a',
      '#44337a'
    ],
    pink: [
      '',
      '#fff5f7',
      '#fed7e2',
      '#fbb6ce',
      '#f687b3',
      '#ed64a6',
      '#d53f8c',
      '#b83280',
      '#97266d',
      '#702459'
    ],
    grayDark: '#2d3748',
    text: '#FFFFFF',
    secondaryText: '#DBDDF6',
    buttonText: '#FFFFFF',
    sidebarButtonBG: '#181D48',
    buttonSecondaryBG: '#181D48',
    buttonBG: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%',
    modelTransparentColor: 'rgba(0, 0, 0, 0.75)',
    background: '#0D0F23',
    input: '#181D48',
    primary: '#2667FF',
    secondary: '#A10D5F',
    muted: '#212760',
    success: '#0ECB81',
    // info: '#111536',
    info: '#C9D9FD',
    danger: '#F6465D',
    light: '#212760',
    dark: '#111536',
    inviteBannerBG: '',
    darkBlue: '#141736',
    textMuted: '#8E9DEA',
    primaryCardBG: '#141736',
    secondaryCardBG: 'transparent linear-gradient(180deg, #1F244F 0%, #1F244F66 100%) 0% 0%',
    darkBG: '#111536',
    tableHeaderBG: '#181D48',
    iconContainerBG: '#212760',
    mobileNavBarBG: '#181D488C',
    lineChartTooltipBG: '#215B866A',
    lightBorderColor: '#8E9DEA',
    underlineBorderColor: '#191E4E'
    // modes: {
    //   light: {
    //     text: '#0E1766',
    //     secondaryText: '#707A8A',
    //     buttonText: '#FFFFFF',
    //     background: '#FFFFFF',
    //     sidebarButtonBG: '#FFFFFF',
    //     lineChartTooltipBG: '#FFFFFF6A',
    //     buttonSecondaryBG: '#F5F4F8',
    //     input: '#F5F7FF',
    //     primary: '#2667FF',
    //     secondary: '#A10D5F',
    //     muted: '#F5F7FF',
    //     success: '#0ECB81',
    //     info: '#C9D9FD ',
    //     danger: '#F6465D',
    //     light: '#C9D9FD',
    //     dark: '#C9D9FD',
    //     lightBorderColor: '#DDDFF6',
    //     textMuted: '#707A8A',
    //     primaryCardBG: '#F5F7FF',
    //     secondaryCardBG:
    //       'transparent linear-gradient(180deg, #F5F7FF 0%, #FFFFFF66 100%) 0% 0% no-repeat padding-box;',
    //     darkBG: '#F5F7FF',
    //     tableHeaderBG: '#F5F7FF',
    //     iconContainerBG: '#C9D9FD'
    //   }
    // }
  },
  grids: {
    primary: {},
    table: {
      width: '100%',
      borderRadius: 10,
      py: 5,
      pl: 15
    }
  },
  fonts: {
    sans: 'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    serif: 'Georgia,Cambria,"Times New Roman",Times,serif',
    mono: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    // body: 'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    body: 'Poppins',
    heading: 'inherit',
    monospace: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace'
  },
  fontSizes: [
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.875rem',
    '2.25rem',
    '3rem',
    '4rem',
    '4.5rem'
  ],
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
    body: 400,
    heading: 700
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  },
  lineHeights: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
    body: '1.625',
    heading: '1.25'
  },
  // sizes: {
  //   '0': '0',
  //   '1': '0.25rem',
  //   '2': '0.5rem',
  //   '3': '0.75rem',
  //   '4': '1rem',
  //   '5': '1.25rem',
  //   '6': '1.5rem',
  //   '8': '2rem',
  //   '10': '2.5rem',
  //   '12': '3rem',
  //   '16': '4rem',
  //   '20': '5rem',
  //   '24': '6rem',
  //   '32': '8rem',
  //   '40': '10rem',
  //   '48': '12rem',
  //   '56': '14rem',
  //   '64': '16rem',
  //   px: '1px',
  //   xs: '20rem',
  //   sm: '24rem',
  //   md: '28rem',
  //   lg: '32rem',
  //   xl: '36rem',
  //   '2xl': '42rem',
  //   '3xl': '48rem',
  //   '4xl': '56rem',
  //   '5xl': '64rem',
  //   '6xl': '72rem',
  //   '1/2': '50%',
  //   '1/3': '33.333333%',
  //   '2/3': '66.666667%',
  //   '1/4': '25%',
  //   '2/4': '50%',
  //   '3/4': '75%',
  //   '1/5': '20%',
  //   '2/5': '40%',
  //   '3/5': '60%',
  //   '4/5': '80%',
  //   '1/6': '16.666667%',
  //   '2/6': '33.333333%',
  //   '3/6': '50%',
  //   '4/6': '66.666667%',
  //   '5/6': '83.333333%',
  //   '1/12': '8.333333%',
  //   '2/12': '16.666667%',
  //   '3/12': '25%',
  //   '4/12': '33.333333%',
  //   '5/12': '41.666667%',
  //   '6/12': '50%',
  //   '7/12': '58.333333%',
  //   '8/12': '66.666667%',
  //   '9/12': '75%',
  //   '10/12': '83.333333%',
  //   '11/12': '91.666667%',
  //   full: '100%',
  //   screenHeight: '100vh',
  //   screenWidth: '100vw'
  // },
  shadows: {
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    none: 'none'
  },
  space: [
    // index * 4px
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
    '2.25rem',
    '2.5rem',
    '2.75rem',
    '3rem',
    '3.5rem',
    '4rem',
    '5rem',
    '6rem',
    '7rem',
    '8rem',
    '9rem',
    '10rem'
  ],
  radii: {
    none: '0',
    sm: '0.125rem',
    default: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    full: '9999px'
  },
  zIndices: {
    '0': 0,
    '10': 10,
    '20': 20,
    '30': 30,
    '40': 40,
    '50': 50,
    auto: 'auto'
  },
  links: {
    nav: {
      cursor: 'pointer'
    },
    unstyled: {
      cursor: 'pointer',
      textDecoration: 'none'
    }
  },
  layout: {
    mobileNavBar: {
      background: 'mobileNavBarBG',
      backdropFilter: 'blur(21px)'
    },
    pageContainer: {
      px: [5, 0]
    },
    mainContainer: {
      width: ['100%', 'calc(100% - 364px)'],
      px: [0, 8],
      mt: 100,
      mb: 100
    },
    pageSideContainer: {
      width: '100%',
      mt: 10
    },
    newTabContainer: {
      width: ['100%', 'auto'],
      px: [2, 8],
      justifyContent: 'center',
      alignItems: 'center'
    },
    underlineBox: {
      p: 8,
      borderWidth: '0 0 1px 0',
      borderStyle: 'solid',
      borderColor: 'underlineBorderColor'
    },
    flex: {},
    coloredContainer: {
      py: 3,
      px: 4,
      minWidth: 100,
      fontFamily: 'body',
      borderRadius: 30,
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
    },
    container: {
      maxWidth: 1440,
      width: ['91%', null, null, null, '100%']
    },
    iconContainer: {
      borderRadius: 100,
      background: 'iconContainerBG',
      p: 2,
      width: '4vh',
      height: '4vh',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '14px'
    },
    cardIconContainer: {
      borderRadius: 10,
      background: 'muted',
      width: '30%',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      p: 3
    },
    flexCenterCenter: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    flexStartCenter: {
      variant: 'layout.flexCenterCenter',
      alignItems: 'flex-start'
    },
    flexStartStart: {
      variant: 'layout.flexStartCenter',
      justifyContent: 'flex-start'
    },
    flexStartEnd: {
      variant: 'layout.flexStartCenter',
      justifyContent: 'flex-end'
    },
    flexEndCenter: {
      variant: 'layout.flexCenterCenter',
      alignItems: 'flex-end'
    },
    flexCenterEnd: {
      variant: 'layout.flexCenterCenter',
      justifyContent: 'flex-end'
    },
    flexCenterStart: {
      variant: 'layout.flexCenterCenter',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    flexCenterSpaceBetween: {
      variant: 'layout.flexCenterCenter',
      justifyContent: 'space-between'
    },
    flexCenterSpaceAround: {
      variant: 'layout.flexCenterCenter',
      justifyContent: 'space-around'
    },
    hStack: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '> * + *': {
        ml: 2
      }
    },
    vStack: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      '> * + *': {
        mt: 2
      }
    },
    borderedText: {
      borderRadius: 10,
      textAlign: 'center'
    },
    borderedTextCenter: {
      variant: 'layout.borderedText',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    roundContainer: {
      variant: 'layout.flexCenterCenter',
      borderRadius: '100%',
      width: 25,
      height: 25
    }
  },
  text: {
    default: {
      my: 'auto',
      fontSize: ['14px', null, null, '16px', '16px'],
      lineHeight: ['14px', null, null, '20px', '20px']
    },
    mediumSmall: {
      my: 'auto',
      fontSize: ['12px', null, null, '14px', '14px'],
      lineHeight: ['14px', null, null, '18px', '18px']
    },
    small: {
      fontFamily: 'body',
      fontSize: ['10px', null, null, '12px', '12px'],
      lineHeight: ['12px', null, null, '16px', '16px']
    },
    large: {
      fontFamily: 'body',
      fontSize: ['16px', null, null, '18px', '18px'],
      lineHeight: ['18px', null, null, '20px', '20px']
    },
    extraLarge: {
      fontFamily: 'body',
      fontSize: ['20px', null, null, '18px', '24px'],
      lineHeight: ['20px', '20px', null, '20px', '24px']
    },
    mediumTitle: {
      letterSpacing: '0.08em',
      fontWeight: 'heading',
      fontFamily: 'body',
      fontSize: ['13px', null, null, '18px', '18px'],
      lineHeight: ['16px', '20px', null, '20px', '24px']
    },
    largeTitle: {
      letterSpacing: '0.05em',
      fontWeight: 'heading',
      fontFamily: 'Poppins',
      fontSize: ['13px', '17px', '20px', '24px', '32px'],
      lineHeight: ['16px', '24px', '28px', '32px', '36px']
    },
    largeTitle2: {
      letterSpacing: '0.05em',
      fontWeight: 'heading',
      fontFamily: 'Poppins',
      fontSize: ['17px', '21px', '24px', '28px', '36px'],
      lineHeight: ['20px', '28px', '32px', '36px', '40px']
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      overflow: 'overlay'
    },
    a: {
      cursor: 'pointer',
      color: 'primary',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline'
      },
      ':active': {
        textDecoration: 'none'
      },
      ':focus': {
        textDecoration: 'none'
      },
      ':visited': {
        textDecoration: 'none'
      }
    },
    h1: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 1,
      fontSize: 6,
      mt: 2
    },
    h2: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 1,
      fontSize: 5,
      mt: 2
    },
    h3: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 1,
      fontSize: 4,
      mt: 3
    },
    h4: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 1,
      fontSize: 3
    },
    h5: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 1,
      fontSize: 2
    },
    h6: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 2,
      fontSize: 1
    },
    code: {},
    pre: {},
    hr: {
      bg: 'muted',
      border: 0,
      height: '1px',
      m: 3
    }
  },
  cards: {
    primary: {
      background: 'primaryCardBG',
      py: 5,
      px: 4,
      borderRadius: 10
    },
    primarySmall: {
      variant: 'cards.primary',
      p: 3
    },
    secondary: {
      variant: 'cards.primary',
      background: 'secondaryCardBG'
    },
    muted: {
      variant: 'cards.primary',
      background: 'muted'
    },
    mixedColor: {
      variant: 'cards.primary',
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
    },
    flexCenterCard: {
      background: 'light',
      py: 5,
      px: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    flexCenterSecondaryCard: {
      variant: 'cards.secondary',
      py: 5,
      px: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '2px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      transition: 'border 0.2s'
    },
    flexCenterSecondaryCardOutlined: {
      variant: 'cards.flexCenterSecondaryCard',
      borderColor: 'primary'
    },
    upload: {
      variant: 'forms.input',
      borderWidth: 2,
      borderColor: 'lightBorderColor',
      borderStyle: 'dotted'
    },
    chatAdmin: {
      background: 'darkBG',
      borderRadius: '20px 20px 20px 0',
      width: 'auto',
      py: 4,
      px: 3
    },
    chatUser: {
      variant: 'cards.chatAdmin',
      borderRadius: '20px 20px 0 20px',
      color: 'buttonText',
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%'
    },
    chatInputFooter: {
      variant: 'cards.primary',
      borderRadius: 0,
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%'
    },
    chatInputCard: {
      backgroundColor: 'sidebarButtonBG',
      width: '100%',
      borderRadius: 40,
      display: 'flex',
      py: 3
    },
    NFTSlideShowCardLarge: {
      width: '100%',
      height: '100%',
      borderRadius: 20,
      flexDirection: 'column',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      mx: 0,
      p: 2
    },
    NFTSlideShowCardSmall: {
      variant: 'cards.NFTSlideShowCardLarge'
    },
    toolTipCard: {
      background: '#lineChartTooltipBG',
      backdropFilter: 'blur(6px)',
      px: 8,
      py: 3,
      borderRadius: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary'
    }
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      py: 4,
      px: 6,
      fontSize: ['14px', null, null, '16px', '16px'],
      lineHeight: ['14px', null, null, '20px', '20px'],
      color: 'buttonText',
      fontFamily: 'body',
      minWidth: [100, 150, 150, 150, 150],
      borderRadius: 30,
      background: 'buttonBG',
      whiteSpace: 'nowrap'
    },
    primaryFlexCenter: {
      variant: 'buttons.primary',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'text',
      background: 'buttonSecondaryBG',
      ':hover': {
        opacity: 0.8
      }
    },
    sidebarButton: {
      variant: 'buttons.primary',
      background: 'sidebarButtonBG',
      width: '100%',
      marginLeft: 0,
      color: 'text'
    },
    text: {
      p: 0,
      fontSize: ['14px', null, null, '16px', '16px'],
      lineHeight: ['14px', null, null, '20px', '20px'],
      cursor: 'pointer',
      color: 'text',
      fontFamily: 'body',
      background: 'none',
      ':disabled': {
        opacity: 0.2
      }
    },
    round: {
      cursor: 'pointer',
      borderRadius: 100,
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%',
      color: 'buttonText',
      p: 2,
      width: '4vh',
      height: '4vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      verticalAlign: 'middle'
    },
    outline: {
      variant: 'buttons.primary',
      background: 'none',
      borderWidth: 2,
      borderStyle: 'solid',
      color: 'text',
      borderColor: 'darkBG'
    },
    disabled: {
      py: 2,
      px: 3,
      cursor: 'not-allowed',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'primary',
      border: 'none',
      opacity: 0.5,
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'default'
    },
    icon: {
      cursor: 'pointer',
      color: 'primary',
      width: 'auto',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      verticalAlign: 'middle',
      // TODO: remove this?
      svg: {
        width: '1.5em',
        height: '1.5em'
      }
    }
  },
  badges: {
    primary: {
      px: 4,
      py: 1,
      fontSize: 16,
      borderRadius: 24
    }
  },
  forms: {
    textarea: {
      background: 'input',
      borderRadius: 10,
      color: 'mutedText',
      fontFamily: 'body',
      outline: 'none',
      border: 'none',
      resize: 'none',
      '::placeholder': {
        color: 'textMuted'
      },
      p: 4,
      minWidth: 150,
      '&:focus': {
        outline: 'none',
        border: 'none'
      }
    },
    select: {
      fontFamily: 'body',
      color: 'textMuted',
      textAlign: 'center',
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%',
      py: 4,
      px: 4,
      minWidth: [100, 150, 150, 150, 150],
      borderRadius: [20, 25, 30],
      cursor: 'pointer',
      border: 'none',
      '&:focus': {
        outline: 'none',
        border: 'none'
      },
      option: {
        background: 'dark'
      }
    },
    secondarySelect: {
      variant: 'forms.select',
      background: 'input',
      borderRadius: 10,
      color: 'textMuted'
    },
    //TODO: need to fix autocomplete style problems
    input: {
      fontFamily: 'body',
      textAlign: 'left',
      background: 'input',
      border: 'none',
      p: 4,
      borderRadius: 10,
      '::placeholder': {
        color: 'textMuted'
      },
      // '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active, &:-internal-autofill-selected, &:-internal-autofill-previewed':
        {
          // backgroundColor: 'input',
          // background: 'input',
          // '-webkit-text-fill-color': 'text !important',
          color: 'white !important',
          '-webkit-box-shadow': ' 0 0 0 0 input inset !important',
          border: 'none'
        },
      '&:focus': {
        outline: 'none',
        border: 'none',
        background: 'input'
      },
      //disable number input arrows
      '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        m: 0
      },
      'input[type=number]': {
        '-moz-appearance': 'textfield'
      }
    },
    switch: {
      background: 'transparent linear-gradient(278deg, #A10D5F 0%, #0C42D3 100%) 0% 0%',
      justifyItems: 'flex-end'
    },
    checkbox: { color: 'white' },
    reactTelInput: {
      '.react-tel-input': {
        '.selected-flag': {
          backgroundColor: 'input',
          color: 'white',
          border: 'none',
          '&:hover': {
            background: 'dark'
          },
          '&:focus': {
            background: 'dark'
          }
        },
        '.flag-dropdown': {
          bg: 'transparent'
        },
        '.flag-dropdown.open': {
          '.selected-flag': {
            background: 'dark'
          }
        }
      },
      '.country-list': {
        background: 'transparent',
        '.search': {
          backgroundColor: 'input'
        },
        '.search-box': {
          backgroundColor: 'input',
          color: 'white',
          border: 'none',
          fontFamily: 'Poppins'
        },
        '.country.highlight': {
          backgroundColor: 'dark'
        },
        '.country': {
          backgroundColor: 'input',
          color: 'white',
          '&:hover': {
            backgroundColor: 'dark'
          },
          fontFamily: 'Poppins'
        }
      },
      '.form-control': {
        fontFamily: 'Poppins',
        width: '100%',
        backgroundColor: 'input',
        color: 'white',
        border: 'none'
      },
      '.flag-dropdown': {
        border: 'none'
      }
    }
  },
  // transforms: {
  //   transformOrigin: {
  //     center: 'center',
  //     top: 'top',
  //     'top-right': 'top right',
  //     right: 'right',
  //     'bottom-right': 'bottom right',
  //     bottom: 'bottom',
  //     'bottom-left': 'bottom left',
  //     left: 'left',
  //     'top-left': 'top left'
  //   },
  //   translate: {
  //     '0': '0',
  //     '1': '0.25rem',
  //     '2': '0.5rem',
  //     '3': '0.75rem',
  //     '4': '1rem',
  //     '5': '1.25rem',
  //     '6': '1.5rem',
  //     '8': '2rem',
  //     '10': '2.5rem',
  //     '12': '3rem',
  //     '16': '4rem',
  //     '20': '5rem',
  //     '24': '6rem',
  //     '32': '8rem',
  //     '40': '10rem',
  //     '48': '12rem',
  //     '56': '14rem',
  //     '64': '16rem',
  //     px: '1px',
  //     '-full': '-100%',
  //     '-1/2': '-50%',
  //     '1/2': '50%',
  //     full: '100%'
  //   },
  //   scale: {
  //     '0': '0',
  //     '50': '.5',
  //     '75': '.75',
  //     '90': '.9',
  //     '95': '.95',
  //     '100': '1',
  //     '105': '1.05',
  //     '110': '1.1',
  //     '125': '1.25',
  //     '150': '1.5'
  //   },
  //   rotate: {
  //     '0': '0',
  //     '45': '45deg',
  //     '90': '90deg',
  //     '180': '180deg',
  //     '-180': '-180deg',
  //     '-90': '-90deg',
  //     '-45': '-45deg'
  //   },
  //   skew: {
  //     '0': '0',
  //     '3': '3deg',
  //     '6': '6deg',
  //     '12': '12deg',
  //     '-12': '-12deg',
  //     '-6': '-6deg',
  //     '-3': '-3deg'
  //   }
  // },
  transitions: {
    property: {
      none: 'none',
      all: 'all',
      default:
        'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
      colors: 'background-color, border-color, color, fill, stroke',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform'
    },
    timingFunction: {
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)'
    },
    duration: {
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms'
    }
  },
  images: {
    avatar: {
      width: 100,
      height: 100,
      borderRadius: 99999
    }
  }
}

export default theme
