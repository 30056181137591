import { Box, Flex, Heading, Text } from 'theme-ui'
import TitleWithBack from 'components/TitleWithBack'
import MobileReturnHeader from 'components/MobileReturnHeader'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import AnnouncementService, { Announcement } from 'network/services/announcement'
import { useResolution } from 'hooks/useResolution'
import ErrorCard from 'components/error'
import LoadingCard from 'components/loading'

const AnnouncementDetail = () => {
  const { isMobile } = useResolution()

  return (
    <Box variant="layout.pageContainer">
      {isMobile && <MobileReturnHeader title="Announcement" delta={-1} />}
      <TitleWithBack delta={-1} title="" />
      <PageView />
    </Box>
  )
}

const PageView = () => {
  const { id } = useParams()

  const {
    data: announcement,
    error,
    mutate
  } = useSWR<{ data: Announcement }>(id != null ? AnnouncementService.getAnnouncement(id) : null)

  if (error) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <ErrorCard message="Unable to retrieve Announcement details" refresh={() => mutate()} />
      </Flex>
    )
  }

  if (!announcement) {
    return (
      <Flex variant="layout.flexCenterCenter">
        <LoadingCard />
      </Flex>
    )
  }

  return (
    <Box>
      <Flex variant="layout.flexCenterSpaceBetween">
        <Heading>{announcement!.data.title}</Heading>
        <Text sx={{ color: 'textMuted' }}>{announcement!.data.published_at}</Text>
      </Flex>
      <Box p={3} />
      <Text>{announcement!.data.content}</Text>
    </Box>
  )
}

export default AnnouncementDetail
