import { useRef, useState } from 'react'
import { Box, Text, Flex, IconButton, Button, Grid } from 'theme-ui'
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel'
import { BiCaretLeft, BiCaretRight } from 'react-icons/bi'

import { useResolution } from 'hooks/useResolution'
import { TopBuyerCard, TopNFTCard } from 'components/NFTs'
import NFTSlideshowCard from 'components/NFTs/nft-slideshow-card'

export interface VXTData {
  image: string
  name: string
  highestBid: number
  email: string
}

export interface TopBuyerData {
  email: string
  value: number
  image: string
}

const NFTShowcase = () => {
  const [topVXTData, setTopVXTData] = useState<VXTData[]>([
    {
      image: 'assets/images/test-nft-1.png',
      name: 'Anonymous #7288',
      highestBid: 3.005,
      email: 'alex…ail.com'
    },
    {
      image: 'assets/images/test-nft-1.png',
      name: 'Anonymous #7288',
      highestBid: 3.005,
      email: 'alex…ail.com'
    },
    {
      image: 'assets/images/test-nft-1.png',
      name: 'Anonymous #7288',
      highestBid: 3.005,
      email: 'alex…ail.com'
    },
    {
      image: 'assets/images/test-nft-1.png',
      name: 'Anonymous #7288',
      highestBid: 3.005,
      email: 'alex…ail.com'
    },
    {
      image: 'assets/images/test-nft-1.png',
      name: 'Anonymous #7288',
      highestBid: 3.005,
      email: 'alex…ail.com'
    },
    {
      image: 'assets/images/test-nft-1.png',
      name: 'Anonymous #7288',
      highestBid: 3.005,
      email: 'alex…ail.com'
    }
  ])

  const [topBuyerData, setTopBuyerData] = useState<TopBuyerData[]>([
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    },
    {
      email: 'alex…ail.com',
      value: 170981,
      image: '/assets/svg/user-logo.svg'
    }
  ])

  return (
    <Box sx={{ mb: 100 }}>
      <SlideShow />

      <Box p={10} />

      <Box>
        <Text variant="extraLarge">Top Pbc NFTs</Text>
      </Box>

      <Box p={2} />

      <Flex variant="layout.flexCenterSpaceBetween">
        <Flex variant="layout.flexCenterStart">
          <Button variant="secondary" sx={{ minWidth: [80, 'auto'], px: [4, 'auto'] }}>
            Avatar
          </Button>
          <Button variant="outline" sx={{ ml: 4, minWidth: [80, 'auto'], px: [4, 'auto'] }}>
            Potion
          </Button>
        </Flex>
        <Button variant="secondary" sx={{ minWidth: [80, 'auto'], px: [4, 'auto'] }}>
          Bid
        </Button>
      </Flex>
      <Box p={4} />
      <Grid gap={5} columns={[1, 2]}>
        {topVXTData.slice(0, 6).map((item, index) => (
          <TopNFTCard
            key={index}
            image={item.image}
            name={item.name}
            highestBid={item.highestBid}
            email={item.email}
            index={index + 1}
          />
        ))}
      </Grid>

      <Box p={10} />
      <Flex variant="layout.flexCenterSpaceBetween">
        <Text variant="extraLarge">Top Buyers</Text>
        <Button variant="secondary" sx={{ minWidth: [80, 'auto'], px: [4, 'auto'] }}>
          Bid
        </Button>
      </Flex>
      <Box p={2} />
      <TopBuyer topBuyerData={topBuyerData} />
    </Box>
  )
}

const SlideShow = () => {
  const ref = useRef<StackedCarousel>()

  return (
    <Flex variant="layout.flexCenterSpaceBetween" sx={{ position: 'relative' }}>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
            // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
            // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
            // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
            let currentVisibleSlide = 5
            if (parentWidth <= 1024) currentVisibleSlide = 3

            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={NFTSlideshowCard}
                slideWidth={parentWidth < 640 ? 250 : 350}
                carouselWidth={parentWidth}
                data={[
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  },
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  },
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  },
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  },
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  },
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  },
                  {
                    image: 'assets/images/test-nft-1.png',
                    name: 'Anonymous #7288',
                    highestBid: 3.005,
                    email: 'alex…ail.com'
                  }
                ]}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
              />
            )
          }}
        />
        <>
          <IconButton
            variant="round"
            sx={{ position: 'absolute', top: '40%', left: 0, zIndex: 10, background: 'muted' }}
            color="white"
            onClick={() => {
              ref.current?.goBack()
            }}
          >
            <BiCaretLeft />
          </IconButton>
          <IconButton
            variant="round"
            sx={{ position: 'absolute', top: '40%', right: 0, zIndex: 10, background: 'muted' }}
            color="white"
            onClick={() => {
              ref.current?.goNext()
            }}
          >
            <BiCaretRight />
          </IconButton>
        </>
      </Box>
    </Flex>
  )
}

const TopBuyer = ({ topBuyerData }: { topBuyerData: TopBuyerData[] }) => {
  const { isMobile } = useResolution()

  return isMobile ? (
    <Grid gap={3}>
      {topBuyerData.map((item, index) => (
        <Flex variant="layout.flexCenterStart">
          <TopBuyerCard
            email={item.email}
            image={item.image}
            value={item.value}
            index={index + 1}
          />
        </Flex>
      ))}
    </Grid>
  ) : (
    <Flex variant="layout.flexCenterSpaceBetween" sx={{ '>*': { width: 200 } }}>
      <Grid gap={3}>
        {topBuyerData.slice(0, 3).map((item, index) => (
          <Flex key={index} variant="layout.flexCenterStart">
            <TopBuyerCard
              email={item.email}
              image={item.image}
              value={item.value}
              index={index + 1}
            />
          </Flex>
        ))}
      </Grid>
      <Grid gap={3}>
        {topBuyerData.slice(3, 6).map((item, index) => (
          <Flex key={index} variant="layout.flexCenterCenter">
            <TopBuyerCard
              email={item.email}
              image={item.image}
              value={item.value}
              index={index + 4}
            />
          </Flex>
        ))}
      </Grid>
      <Grid gap={3}>
        {topBuyerData.slice(6, 9).map((item, index) => (
          <Flex key={index} variant="layout.flexCenterEnd">
            <TopBuyerCard
              email={item.email}
              image={item.image}
              value={item.value}
              index={index + 7}
            />
          </Flex>
        ))}
      </Grid>
    </Flex>
  )
}

export default NFTShowcase
