import { Box, Button, Container, IconButton, Image, Text } from 'theme-ui'
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { authStore } from 'store/auth'
import { useResolution } from 'hooks/useResolution'
import SocialLink from 'components/social-link'

const PublicLayout = () => {
  const { token: isLoggedIn } = useSnapshot(authStore)
  let location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const token = query.get('platform_token')

  if (isLoggedIn && !token) {
    return <Navigate to="/home" state={{ from: location }} replace />
  }

  return (
    <Container sx={{ minHeight: '100vh' }}>
      <PublicHeader />
      <Outlet />
      <PublicFooter />
    </Container>
  )
}

const PublicHeader = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ zIndex: 2, py: 3, pt: 15 }}>
          <Container variant="layout.flexCenterCenter">
            <Link to="/" style={{ display: 'flex' }}>
              <Image src="/logo_header.png" alt="pbc" />
            </Link>
          </Container>
        </Box>
      ) : (
        <Box sx={{ position: 'fixed', top: 7, left: 0, right: 0, zIndex: 2 }}>
          <Container>
            <Box variant="layout.flexCenterSpaceBetween">
              <Link to="/">
                <Image src="/logo_header.png" alt="pbc" />
              </Link>
              {/* <Button>English</Button> */}
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

const PublicFooter = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ bottom: 7, left: 0, right: 0, py: 3 }}>
          <Container>
            <Box variant="layout.vStack" sx={{ alignItems: 'center' }}>
              <Box sx={{ pb: 14 }}>
                <ExternalPage />
              </Box>
              <Box>{/* <Button>English</Button> */}</Box>
              <Box>
                <Text>{`PBC © ${new Date().getFullYear()}. All Rights Reserved`}</Text>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box sx={{ position: 'fixed', bottom: 7, left: 0, right: 0 }}>
          <Container>
            <Box variant="layout.flexCenterSpaceBetween">
              <Text>{`PBC © ${new Date().getFullYear()}. All Rights Reserved`}</Text>
              <ExternalPage />
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

const ExternalPage = () => {
  return <SocialLink />
}

export default PublicLayout
