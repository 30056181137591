import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Flex, Text, Box, Image } from 'theme-ui'

const InfoActionCard: FC<{
  title: string
  subtitle: string
  imageUrl: string
  disabled?: boolean
  navigateUrl?: string
}> = ({ title, subtitle, imageUrl, disabled = false, navigateUrl = '' }) => {
  const navigate = useNavigate()

  return (
    <Card variant="secondary" py={8}>
      <Flex variant="layout.flexCenterSpaceBetween" sx={{ position: 'relative' }}>
        <Flex variant="layout.vStack" paddingLeft="20px">
          <Box sx={{ maxWidth: 200 }}>
            <Text as="p" color="white" variant="largeTitle2">
              {title}
            </Text>
          </Box>
          <Box padding={2} />
          <Box>
            {/* <Text as="p" variant="large">
            {subtitle}
          </Text> */}
            <Button
              disabled={disabled}
              variant={!disabled ? 'primary' : 'secondary'}
              onClick={() => navigate(navigateUrl)}
            >
              {subtitle}
            </Button>
          </Box>
        </Flex>
        <Box
          sx={{
            position: 'absolute',
            top: ['50%', -100],
            right: ['-20px', '-40px'],
            transform: ['translateY(-50%)', 'initial']
          }}
        >
          <Image src={imageUrl} sx={{ maxWidth: ['120px', '300px'] }} />
        </Box>
      </Flex>
    </Card>
  )
}

export default InfoActionCard
