import { Box, Container, IconButton, Image, Text } from 'theme-ui'
import { Link, Outlet } from 'react-router-dom'
import { useResolution } from 'hooks/useResolution'
import SocialLink from 'components/social-link'

const GeneralLayout = () => {
  return (
    <Container sx={{ minHeight: '100vh' }}>
      <PublicHeader />
      <Outlet />
      <PublicFooter />
    </Container>
  )
}

const PublicHeader = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ zIndex: 2, py: 3, pt: 15 }}>
          <Container variant="layout.flexCenterCenter">
            <Link to="/" style={{ display: 'flex' }}>
              <Image src="/logo_header.png" alt="pbc" />
            </Link>
          </Container>
        </Box>
      ) : (
        <Box>
          <Container sx={{ mt: 10 }}>
            <Box variant="layout.flexCenterCenter">
              <Link to="/">
                <Image src="/logo_header.png" alt="pbc" />
              </Link>
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

const PublicFooter = () => {
  const { isMobile } = useResolution()

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ bottom: 7, left: 0, right: 0, py: 3 }}>
          <Container>
            <Box variant="layout.vStack" sx={{ alignItems: 'center' }}>
              <Box sx={{ pb: 14 }}>
                <ExternalPage />
              </Box>
              <Box>{/* <Button>English</Button> */}</Box>
              <Box>
                <Text>{`PBC © ${new Date().getFullYear()}. All Rights Reserved`}</Text>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box>
          <Container sx={{ mb: 10 }}>
            <Box variant="layout.flexCenterSpaceBetween">
              <Text>{`PBC © ${new Date().getFullYear()}. All Rights Reserved`}</Text>
              <ExternalPage />
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  )
}

const ExternalPage = () => {
  return <SocialLink />
}

export default GeneralLayout
