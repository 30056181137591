import client, { IPostResponse } from '../request'

export type SecurityCodeVerification = {
  two_fa_method: string
  two_fa_code: string
  user_security_id: string
}

export type UserSecurity = {
  id: string
  two_fa_method?: string
  two_fa_enabled?: boolean
  two_fa_code?: string
  otpauth_url?: string
}

export type UserSecurityRequest = {
  two_fa_enabled: boolean
  two_fa_code: string
}

// useSWR, return strings
const get = '/me/user-securities'

// axios
const create = () => {
  return client.post<IPostResponse<UserSecurity>>('/me/user-securities')
}

// axios
const update = (data: UserSecurityRequest, id: string | number) => {
  return client.put<IPostResponse<UserSecurity>>(`/me/user-securities/${id}`, data)
}

// axios
const verifySecurityCode = (data: SecurityCodeVerification) => {
  return client.post<IPostResponse<null>>('/me/user-securities/verify', data)
}

const AssetService = {
  get,
  create,
  update,
  verifySecurityCode
}

export default AssetService
