/** @jsxImportSource theme-ui */
import { FC } from 'react'

const CommonIcon: FC<{
  type: 'bar' | 'money_bag' | 'medal' | 'nft'
  background?: string
  fill?: string
}> = ({ type, background, fill }) => {
  if (type.toLowerCase() === 'money_bag')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.988"
        height="17.807"
        viewBox="0 0 13.988 17.807"
      >
        <g transform="translate(-5 -1.994)">
          <path
            d="M10.513,5.813h5.875L17.832,2.92a.637.637,0,0,0-1.011-.744,2.181,2.181,0,0,1-1.462.458A2.187,2.187,0,0,1,13.9,2.182a.636.636,0,0,0-.9,0,2.181,2.181,0,0,1-1.462.451,2.187,2.187,0,0,1-1.456-.451A.636.636,0,0,0,9.07,2.92Z"
            transform="translate(-1.457 0)"
            fill="#fff"
          />
          <path
            d="M15.326,10H8.662C7.3,11.647,5,14.915,5,18.266a4.21,4.21,0,0,0,3.815,4.451h6.358a4.21,4.21,0,0,0,3.815-4.451C18.988,14.915,16.687,11.647,15.326,10Zm-2.7,8.9v.636a.636.636,0,0,1-1.272,0V18.9a1.907,1.907,0,0,1-1.647-.954.636.636,0,1,1,1.094-.636.636.636,0,0,0,.553.318H12.63a.636.636,0,1,0,0-1.272H11.358a1.907,1.907,0,0,1,0-3.815v-.636a.636.636,0,1,1,1.272,0v.636a1.907,1.907,0,0,1,1.647.954.636.636,0,1,1-1.094.636.636.636,0,0,0-.553-.318H11.358a.636.636,0,0,0,0,1.272H12.63a1.907,1.907,0,0,1,0,3.815Z"
            transform="translate(0 -2.915)"
            fill="#fff"
          />
        </g>
      </svg>
    )

  if (type.toLowerCase() === 'bar')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.808"
        height="16.515"
        viewBox="0 0 14.808 16.515"
      >
        <path
          d="M36.49,6.288v7.5H35.383V7.943a.339.339,0,0,0-.339-.339H32.993a.339.339,0,0,0-.339.339v5.842H31.547V9.6a.339.339,0,0,0-.339-.339H29.156a.339.339,0,0,0-.339.339v4.187H27.056l-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0L27,13.8l-.008,0-.008,0-.008,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.006,0-.006,0-.006.005-.006.005-.006.005-.006.006-.006.006-.006.006-.006.006-.005.006-.005.006-.005.006,0,.006,0,.006,0,.007,0,.007,0,.007,0,.007,0,.007,0,.007,0,.007,0,.007,0,.008,0,.008,0,.008,0,.008,0,.008,0,.008,0,.008,0,.008,0,.008,0,.008v.043s0,.006,0,.009v2.06a.339.339,0,0,0,.339.34H40.921a.339.339,0,0,0,.339-.339v-2.06s0-.006,0-.009,0-.006,0-.009v-.034l0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.006,0-.006-.005-.006-.005-.006-.005-.006-.006-.006-.006-.006-.006-.006-.006-.006-.006-.005-.006-.005-.006-.005-.006,0-.006,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.007,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0-.008,0H39.22v-7.5a.339.339,0,0,0-.339-.339H36.83a.339.339,0,0,0-.339.339Zm2.738-4.022-3.2,3.853a.6.6,0,0,1-.776.129L31.435,4.211l-3.945,4.2a.6.6,0,1,1-.874-.822c1.42-1.512,2.846-3.019,4.259-4.538a.6.6,0,0,1,.719-.119L35.42,4.976,38.551,1.2h-.633a.6.6,0,1,1,0-1.2h1.911a.6.6,0,0,1,.6.6V2.512a.6.6,0,1,1-1.2,0Z"
          transform="translate(-26.452 0)"
          fill="#fff"
          fillRule="evenodd"
        />
      </svg>
    )

  if (type.toLowerCase() === 'nft')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.99"
        height="16.594"
        viewBox="0 0 13.99 16.594"
      >
        <g transform="translate(-40.167 0)">
          <path
            d="M116.554,0H107.7a1.526,1.526,0,0,0-1.469,1.111h8.239a2.5,2.5,0,0,1,2.5,2.5V14.453a1.529,1.529,0,0,0,1.111-1.47V1.528A1.527,1.527,0,0,0,116.554,0Z"
            transform="translate(-63.924)"
            fill="#fff"
          />
          <path
            d="M140.231,157.752v-3.347l-2.9-1.674-2.9,1.674v3.347l2.9,1.674Zm-3.3.419-1.215-1.823a.486.486,0,0,1,0-.539l1.215-1.823a.488.488,0,0,1,.809,0l1.215,1.823a.486.486,0,0,1,0,.539l-1.215,1.823A.488.488,0,0,1,136.928,158.171Z"
            transform="translate(-91.212 -147.782)"
            fill="#fff"
          />
          <path
            d="M50.547,64.267H41.695a1.527,1.527,0,0,0-1.528,1.528V77.25a1.527,1.527,0,0,0,1.528,1.528h8.852a1.527,1.527,0,0,0,1.528-1.528V65.795A1.527,1.527,0,0,0,50.547,64.267Zm-8.3,4.26a.486.486,0,0,1,.243-.421l3.385-1.954a.486.486,0,0,1,.486,0l3.385,1.954a.486.486,0,0,1,.243.421v3.908a.486.486,0,0,1-.243.421l-3.385,1.954a.486.486,0,0,1-.486,0l-3.385-1.954a.486.486,0,0,1-.243-.421ZM49.505,76.7H42.736a.486.486,0,0,1,0-.972h6.769A.486.486,0,0,1,49.505,76.7Z"
            transform="translate(0 -62.184)"
            fill="#fff"
          />
          <path
            d="M205.665,227.753l-.631-.946-.631.946.631.946Z"
            transform="translate(-158.913 -219.456)"
            fill="#fff"
          />
        </g>
      </svg>
    )

  if (type.toLowerCase() === 'medal')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.563"
        height="17.806"
        viewBox="0 0 13.563 17.806"
      >
        <g transform="translate(-61.009)">
          <g transform="translate(65.279 4.286)">
            <g transform="translate(0)">
              <path
                d="M187.764,126.04l1.045-.994-1.456-.207a.522.522,0,0,1-.392-.281l-.663-1.311-.663,1.311a.522.522,0,0,1-.392.281l-1.456.207,1.045.994a.523.523,0,0,1,.154.468l-.25,1.42,1.323-.679a.525.525,0,0,1,.477,0l1.323.679-.25-1.42A.523.523,0,0,1,187.764,126.04Z"
                transform="translate(-183.786 -123.246)"
                fill="#fff"
              />
            </g>
          </g>
          <g transform="translate(61.009)">
            <g>
              <path
                d="M67.79,0a6.781,6.781,0,1,0,6.781,6.781A6.789,6.789,0,0,0,67.79,0ZM71.8,6.1,70.179,7.643l.383,2.178a.522.522,0,0,1-.752.554L67.79,9.339l-2.018,1.036a.522.522,0,0,1-.752-.554L65.4,7.643,63.779,6.1a.522.522,0,0,1,.286-.895l2.254-.32,1.006-1.989a.543.543,0,0,1,.931,0l1.006,1.989,2.254.32A.522.522,0,0,1,71.8,6.1Z"
                transform="translate(-61.009 0)"
                fill="#fff"
              />
            </g>
          </g>
          <g transform="translate(64.139 13.697)">
            <g transform="translate(0)">
              <path
                d="M154.657,394.741a7.773,7.773,0,0,1-3.652-.909v3.587a.522.522,0,0,0,.811.434l2.841-1.893,2.84,1.893a.522.522,0,0,0,.811-.434v-3.587A7.772,7.772,0,0,1,154.657,394.741Z"
                transform="translate(-151.005 -393.831)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </svg>
    )

  return <></>
}

export default CommonIcon
